*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


html,
body,
.site-wrapper {
  height: 100%;
}

.justify-content-right{
  justify-content: right;
}
.about-sec {
  background: #eee;
  margin-top: 60px;
}


.slider-text-section {
    position: absolute;
    top: 147px;
    width: 100%;
}

.home-masthead-intro h1 {
    color: #ff9542;
    font-weight: normal;
    font-size: 21px;
}
.home-masthead-intro span {
    color: #fff;
    font-family: "Marcellus", Arial, Helvetica, sans-serif;
    font-size: 42px;
    font-style: italic;
    font-weight: 400;
}
.home-masthead-intro p {
    color: #fff;
    font-weight: normal !important;
}
.home-masthead-intro a {
  
}

.feat {
  padding: 60px 0;
}

.footer2-section h3 {
    color: #fff;
    font-weight: normal;
    position: relative;
}
.footer2-section h3:after {
    content: "";
    display: block;
    width: 84px;
    border-bottom: 4px solid #101f34;
    margin: 13px 0 24px;
}
ul.opening-hours {
    list-style-type: none;
    padding: 0;
}
ul.opening-hours li {
    color: #a5a5a5;
}
ul.contact-details-footer {
    list-style-type: none;
    padding: 0;
}
ul.contact-details-footer li {
    color: #a5a5a5;
}
.social-icons-footer li {
    display: inline !important;
    color: #a5a5a5;
}
.social-icons-footer a {
    border: 1px solid #fff !important;
    margin: 0 12px 0 0;
    border-radius: 37px;
    text-align: center;
    padding: 8px 0;
    position: relative;
    top: -9px;
    color: #a5a5a5;
}
.quicklinks li a i {
    display: inline;
}


body{
  background-color: #eefbee !important;
}
html{
  background-color: #eefbee !important;
}

.customBackground{
  background-color: #eefbee !important;
}

.customHeader{
  background-color: #021126 !important;
  color: white;
}

.page-title > .title{
  color: white;
}

::-webkit-scrollbar {
  width: 0px;
}

body {
  /*font-family: "Futura", Arial, Helvetica, sans-serif;*/
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.75;
  position: relative;
  visibility: visible;
  overflow-x: hidden;
  color: #2c2c2c;
  background-color: #fff;
}

p {
  color: #696969;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Marcellus", Arial, Helvetica, sans-serif;
  font-weight: 600;
  line-height: 1.2;
  margin-top: 0;
  color: #333;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: inherit;
  margin: 0;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  line-height: inherit;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

a,
button,
img,
input {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.contact-sec {
  padding: 4pc;
}

.header-logo img {
  height: auto;
  width: 140px;
}

*:focus {
  outline: none !important;
}

a:focus {
  text-decoration: none;
  color: inherit;
  outline: 0;
}

a:hover {
  text-decoration: none;
  color: #ff4c00;
}

button,
input[type="submit"] {
  cursor: pointer;
}

img {
  max-width: 100%;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
}

ul:last-child {
  margin-bottom: 0;
}

hr {
  border-top-width: 2px;
}

.fix {
  overflow: hidden;
}

section {
  position: relative;
  z-index: 4;
}

.shadow-img {
  position: absolute;
  bottom: -3pc;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  z-index: 1;
  pointer-events: none;
}

.section,
.main-wrapper {
  /* float: left; */
  position: relative;
  z-index: 4;
  width: 100%;
}

main {
  position: relative;
}

.section-title {
  margin-bottom: 80px;
}

.section-title .sub-title {
  font-family: "Modesty";
  font-size: 76px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 5px;
  color: #ff4c00;
}

.section-title .sub-title:last-child {
  margin-bottom: 0;
}

.section-title .title {
  font-size: 40px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  color: #333;
}

@media only screen and (min-width: 1500px) {
  .section-fluid {
    padding-right: 55px;
    padding-left: 55px;
  }
  .section-fluid > .container,
  .section-fluid > .container-fluid {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .section-fluid {
    padding-right: 35px;
    padding-left: 35px;
  }
  .section-fluid > .container,
  .section-fluid > .container-fluid {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .section-fluid {
    padding-right: 25px;
    padding-left: 25px;
  }
  .section-fluid > .container,
  .section-fluid > .container-fluid {
    max-width: 100%;
  }
}

/* .row {
  margin-top: 0;
  margin-right: -15px;
  margin-left: -15px;
}

.row > [class*="col"] {
  padding-right: 15px;
  padding-left: 15px;
} */

.row > [class*="col"]:not([class*="learts-mt-"]) {
  margin-top: 0;
}

.row.g-0 {
  margin-right: 0;
  margin-left: 0;
}

.row.g-0 > [class*="col"] {
  padding-right: 0;
  padding-left: 0;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
}

.sale-banner7{
  margin-bottom: 10px;
}


@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

/* @media only screen and (max-width: 575px) {
  .container {
    max-width: 450px;
  }
}

@media only screen and (max-width: 479px) {
  .container {
    max-width: 350px;
  }
} */

.bg-primary {
  background-color: #f8796c !important;
}

.text-primary {
  color: #f8796c !important;
}

.bg-primary2 {
  background-color: #ff4c00 !important;
}

.text-primary2 {
  color: #ff4c00 !important;
}

.bg-primary3 {
  background-color: #baa491 !important;
}

.text-primary3 {
  color: #baa491 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.text-secondary {
  color: #6c757d !important;
}

.bg-success {
  background-color: #4caf50 !important;
}

.text-success {
  color: #4caf50 !important;
}

.bg-danger {
  background-color: #f44336 !important;
}

.text-danger {
  color: #f44336 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.text-light {
  color: #f8f9fa !important;
}

.bg-dark {
  background-color: #333 !important;
}

.text-dark {
  color: #333 !important;
}

.bg-black {
  background-color: #000 !important;
}

.text-black {
  color: #000 !important;
}

.bg-grey {
  background-color: #f8f9fd !important;
}

.text-grey {
  color: #f8f9fd !important;
}

.bg-body {
  background-color: #2c2c2c !important;
}

.text-body {
  color: #2c2c2c !important;
}

.bg-body-light {
  background-color: #2c2c2c !important;
}

.text-body-light {
  color: #2c2c2c !important;
}

.bg-heading {
  background-color: #333 !important;
}

.text-heading {
  color: #333 !important;
}

.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

[data-overlay] {
  position: relative;
  z-index: 1;
}

[data-overlay]::before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #000;
}

[data-overlay="0"]::before {
  opacity: 0;
}

[data-overlay="5"]::before {
  opacity: 0.05;
}

[data-overlay="10"]::before {
  opacity: 0.1;
}

[data-overlay="15"]::before {
  opacity: 0.15;
}

[data-overlay="20"]::before {
  opacity: 0.2;
}

[data-overlay="25"]::before {
  opacity: 0.25;
}

[data-overlay="30"]::before {
  opacity: 0.3;
}

[data-overlay="35"]::before {
  opacity: 0.35;
}

[data-overlay="40"]::before {
  opacity: 0.4;
}

[data-overlay="45"]::before {
  opacity: 0.45;
}

[data-overlay="50"]::before {
  opacity: 0.5;
}

[data-overlay="55"]::before {
  opacity: 0.55;
}

[data-overlay="60"]::before {
  opacity: 0.6;
}

[data-overlay="65"]::before {
  opacity: 0.65;
}

[data-overlay="70"]::before {
  opacity: 0.7;
}

[data-overlay="75"]::before {
  opacity: 0.75;
}

[data-overlay="80"]::before {
  opacity: 0.8;
}

[data-overlay="85"]::before {
  opacity: 0.85;
}

[data-overlay="90"]::before {
  opacity: 0.9;
}

[data-overlay="95"]::before {
  opacity: 0.95;
}

[data-overlay="100"]::before {
  opacity: 1;
}

[data-bg-image] {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

body.homepage-bg1 {
  padding: 0 15px 15px !important;
  background-color: #ffcdb8;
}

body.homepage-bg1 .topbar-section {
  background-color: #ffcdb8 !important;
}

body.homepage-bg1.offcanvas-open {
  padding-right: 32px !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  body.homepage-bg1.offcanvas-open {
    padding-right: 15px !important;
  }
}

#scrollUp {
  right: 25px;
  bottom: 25px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: #fff;
  background-color: #ff4c00;
}

#scrollUp i {
  line-height: 40px;
}

.border-top-dashed {
  border-top: 1px dashed #d2d2d2;
}

.border-bottom-dashed {
  border-bottom: 1px dashed #d2d2d2;
}

.img-hover-color {
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
}

.img-hover-color:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.section-padding {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66666%;
    -ms-flex: 0 0 41.66666%;
    flex: 0 0 41.66666%;
    max-width: 41.66666%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66666%;
    -ms-flex: 0 0 66.66666%;
    flex: 0 0 66.66666%;
    max-width: 66.66666%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66666%;
    -ms-flex: 0 0 91.66666%;
    flex: 0 0 91.66666%;
    max-width: 91.66666%;
  }
}

@media only screen and (min-width: 1500px) {
  .col-xxl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
  }
}

@media only screen and (min-width: 1500px) {
  .order-xxl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
  }
}

.learts-mb-10 {
  margin-bottom: 10px;
}

.learts-mb-n10 {
  margin-bottom: -10px;
}

.learts-mb-20 {
  margin-bottom: 20px;
}

.learts-mb-n20 {
  margin-bottom: -20px;
}

.learts-mb-30 {
  margin-bottom: 30px;
}

.learts-mb-n30 {
  margin-bottom: -30px;
}

.learts-mb-40 {
  margin-bottom: 40px;
}

.learts-mb-n40 {
  margin-bottom: -40px;
}

.learts-mb-50 {
  margin-bottom: 50px;
}

.learts-mb-n50 {
  margin-bottom: -50px;
}

.learts-mb-60 {
  margin-bottom: 60px;
}

.learts-mb-n60 {
  margin-bottom: -60px;
}

.learts-mb-70 {
  margin-bottom: 70px;
}

.learts-mb-n70 {
  margin-bottom: -70px;
}

.learts-mb-80 {
  margin-bottom: 80px;
}

.learts-mb-n80 {
  margin-bottom: -80px;
}

.learts-mb-90 {
  margin-bottom: 90px;
}

.learts-mb-n90 {
  margin-bottom: -90px;
}

.learts-mb-100 {
  margin-bottom: 100px;
}

.learts-mb-n100 {
  margin-bottom: -100px;
}

.learts-mt-10 {
  margin-top: 10px;
}

.learts-mt-n10 {
  margin-top: -10px;
}

.learts-mt-20 {
  margin-top: 20px;
}

.learts-mt-n20 {
  margin-top: -20px;
}

.learts-mt-30 {
  margin-top: 30px;
}

.learts-mt-n30 {
  margin-top: -30px;
}

.learts-mt-40 {
  margin-top: 40px;
}

.learts-mt-n40 {
  margin-top: -40px;
}

.learts-mt-50 {
  margin-top: 50px;
}

.learts-mt-n50 {
  margin-top: -50px;
}

.learts-mt-60 {
  margin-top: 60px;
}

.learts-mt-n60 {
  margin-top: -60px;
}

.learts-mt-70 {
  margin-top: 70px;
}

.learts-mt-n70 {
  margin-top: -70px;
}

.learts-mt-80 {
  margin-top: 80px;
}

.learts-mt-n80 {
  margin-top: -80px;
}

.learts-mt-90 {
  margin-top: 90px;
}

.learts-mt-n90 {
  margin-top: -90px;
}

.learts-mt-100 {
  margin-top: 100px;
}

.learts-mt-n100 {
  margin-top: -100px;
}

.learts-pb-10 {
  padding-bottom: 10px;
}

.learts-pb-20 {
  padding-bottom: 20px;
}

.learts-pb-30 {
  padding-bottom: 30px;
}

.learts-pb-40 {
  padding-bottom: 40px;
}

.learts-pb-50 {
  padding-bottom: 50px;
}

.learts-pb-60 {
  padding-bottom: 60px;
}

.learts-pb-70 {
  padding-bottom: 70px;
}

.learts-pb-80 {
  padding-bottom: 80px;
}

.learts-pb-90 {
  padding-bottom: 90px;
}

.learts-pb-100 {
  padding-bottom: 100px;
}

.learts-pt-10 {
  padding-top: 10px;
}

.learts-pt-20 {
  padding-top: 20px;
}

.learts-pt-30 {
  padding-top: 30px;
}

.learts-pt-40 {
  padding-top: 40px;
}

.learts-pt-50 {
  padding-top: 50px;
}

.learts-pt-60 {
  padding-top: 60px;
}

.learts-pt-70 {
  padding-top: 70px;
}

.learts-pt-80 {
  padding-top: 80px;
}

.learts-pt-90 {
  padding-top: 90px;
}

.learts-pt-100 {
  padding-top: 100px;
}

.zoomImg {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.irs--learts {
  font-family: "Futura";
  margin: 7px 0 40px;
}

.irs--learts .irs {
  font-family: "Futura";
  position: relative;
  width: 100%;
}

.irs--learts .irs .irs-line {
  width: 100%;
  height: 5px;
  background-color: #eee;
}

.irs--learts .irs .irs-from,
.irs--learts .irs .irs-to {
  display: none !important;
}

.irs--learts .irs .irs-single {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  top: calc(100% + 25px);
  left: 0 !important;
  visibility: visible !important;
}

.irs--learts .irs .irs-single::before {
  content: "Price: ";
}

.irs--learts .irs-bar {
  top: 0;
  height: 5px;
  background-color: #333;
}

.irs--learts .irs-handle {
  top: 50%;
  width: 18px;
  height: 18px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-indent: -9999px;
  border-radius: 50%;
  background-color: #333;
}

.ps {
  position: relative;
  padding-right: 10px;
}

.ps:not(.ps--active-y) {
  overflow: visible !important;
}

.ps .ps__scrollbar-y-rail {
  width: 5px;
  opacity: 0;
  border-radius: 5px;
}

.ps .ps__scrollbar-y-rail .ps__scrollbar-y {
  right: 1px;
  width: 3px !important;
  -webkit-transition: all 0.1s ease 0s;
  -o-transition: all 0.1s ease 0s;
  transition: all 0.1s ease 0s;
  border-radius: 5px;
}

.pswp__img {
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}

.cd-product-viewer-wrapper {
  position: relative;
  padding-bottom: 20px;
}

.cd-product-viewer-wrapper .close {
  font-size: 30px;
  font-weight: 300;
  position: absolute;
  top: -35px;
  right: -35px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  opacity: 1;
  border: 0;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.cd-product-viewer-wrapper .close:hover {
  opacity: 1 !important;
}

.cd-product-viewer-wrapper .product-viewer {
  position: relative;
  overflow: hidden;
}

.cd-product-viewer-wrapper img {
  position: relative;
  z-index: 1;
  display: block;
}

.cd-product-viewer-wrapper .product-sprite {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 1600%;
  height: 100%;
  -webkit-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0;
  /* background: url(../images/produts/single/7/frame-total.webp) no-repeat center */
  /* center; */
  background-size: 100%;
}

.cd-product-viewer-wrapper.loaded .product-sprite {
  opacity: 1;
}

.cd-product-viewer-handle {
  position: relative;
  z-index: 2;
  width: 60%;
  max-width: 300px;
  height: 4px;
  margin: auto;
  background: #4d4d4d;
}

.cd-product-viewer-handle .fill {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
  border-radius: inherit;
  background: #b54240;
}

.loaded .cd-product-viewer-handle .fill {
  opacity: 0;
}

.cd-product-viewer-handle .handle {
  position: absolute;
  z-index: 2;
  top: -20px;
  left: 0;
  display: inline-block;
  width: 44px;
  height: 44px;
  -webkit-transform: translateX(-50%) scale(0);
  -ms-transform: translateX(-50%) scale(0);
  transform: translateX(-50%) scale(0);
  text-indent: -9999px;
  border-radius: 50%;
  background: #b54240 url(../images/icons/cd-arrows.svg) no-repeat center center;
}

.loaded .cd-product-viewer-handle .handle {
  -webkit-transform: translateX(-50%) scale(1);
  -ms-transform: translateX(-50%) scale(1);
  transform: translateX(-50%) scale(1);
  -webkit-animation: cd-bounce 0.3s 0.3s;
  animation: cd-bounce 0.3s 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes cd-bounce {
  0% {
    -webkit-transform: translateX(-50%) scale(0);
    transform: translateX(-50%) scale(0);
  }
  60% {
    -webkit-transform: translateX(-50%) scale(1.1);
    transform: translateX(-50%) scale(1.1);
  }
  100% {
    -webkit-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
  }
}

@keyframes cd-bounce {
  0% {
    -webkit-transform: translateX(-50%) scale(0);
    transform: translateX(-50%) scale(0);
  }
  60% {
    -webkit-transform: translateX(-50%) scale(1.1);
    transform: translateX(-50%) scale(1.1);
  }
  100% {
    -webkit-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
  }
}

.select2 {
  width: 100% !important;
}

.select2-selection {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  display: block;
  width: 100%;
  height: auto !important;
  padding: 10px 0;
  color: #333;
  border: 2px solid transparent !important;
  border-bottom-color: #ededed !important;
  border-radius: 0 !important;
  background-color: transparent;
}

.select2-selection__rendered {
  padding: 0 !important;
}

.select2-selection__arrow {
  top: 50% !important;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.select2-selection__arrow b {
  border-top-color: #333 !important;
}

.select2-container--open .select2-selection__arrow b {
  border-bottom-color: #333 !important;
}

.select2-dropdown {
  border: 1px solid #eee;
  border-radius: 0;
  -webkit-box-shadow: -2px 2px 80px -27px rgba(0, 0, 0, 0.3);
  box-shadow: -2px 2px 80px -27px rgba(0, 0, 0, 0.3);
}

.select2-search {
  padding: 15px;
}

.select2-search input[type="search"] {
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 15px !important;
  border-color: #ededed !important;
}

.select2-results__options {
  padding-right: 0;
}

.select2-results__options .select2-results__option {
  line-height: 24px;
  padding: 10px 15px;
}

.select2-results__options .select2-results__option[aria-selected="true"] {
  background-color: #eee;
}

.select2-results__options
  .select2-results__option.select2-results__option--highlighted {
  background-color: #f8796c;
}

.slick-slider {
  overflow: hidden;
  margin-right: -40px;
  margin-left: -40px;
  padding-right: 40px;
  padding-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .slick-slider {
    margin-right: -20px;
    margin-left: -20px;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.slick-slider:hover .slick-arrow {
  opacity: 1;
}

.slick-slider:hover .slick-arrow.slick-prev {
  left: 0;
}

.slick-slider:hover .slick-arrow.slick-next {
  right: 0;
}

.slick-slider .slick-list {
  margin-right: -15px;
  margin-left: -15px;
}

.slick-slider .slick-list .slick-slide > div {
  padding-right: 15px;
  padding-left: 15px;
}

.slick-arrow {
  position: absolute;
  z-index: 9;
  top: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  color: #2c2c2c;
  border: 0;
  background-color: transparent;
}

.slick-arrow i {
  font-size: 25px;
  line-height: 1;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.slick-arrow.slick-prev {
  right: auto;
  left: -40px;
}

.slick-arrow.slick-next {
  right: -40px;
  left: auto;
}

.slick-arrow:hover {
  color: #333;
}

.slick-dots {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 18px;
  margin-top: 35px;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

.slick-dots li {
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 6px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.slick-dots li button {
  width: 10px;
  height: 10px;
  padding: 0;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  text-indent: -9999px;
  opacity: 0.2;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: #1f1f1f;
}

.slick-dots li.slick-active button {
  width: 18px;
  height: 18px;
  opacity: 1;
  border-color: #333;
  background-color: transparent;
}

.swiper-slider-wrapper {
  position: relative;
  overflow: hidden;
  margin-right: -40px;
  margin-left: -40px;
  padding-right: 40px;
  padding-left: 40px;
}

.swiper-slider-wrapper .swiper-button-prev {
  left: -40px;
}

.swiper-slider-wrapper .swiper-button-next {
  right: -40px;
}

.swiper-slider-wrapper:hover [class*="swiper-button"] {
  opacity: 1;
}

.swiper-slider-wrapper:hover .swiper-button-prev {
  left: 0;
}

.swiper-slider-wrapper:hover .swiper-button-next {
  right: 0;
}

[class*="swiper-button"] {
  width: 40px;
  height: 40px;
  margin-top: 0 !important;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 2;
  color: #fff;
  /* background-image: url('../images/left-arrow.png'); */
}
.swiper_smaindlide .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  font-size: 27px;
}
.swiper_smaindlide .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
  font-size: 27px;
}
[class*="swiper-button"] i {
  font-size: 25px;
  line-height: 1;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}


[class*="swiper-button"]:hover {
  color: #333;
}

.swiper-pagination {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-height: 20px;
  margin-top: 35px;
}

.swiper-pagination .swiper-pagination-bullet {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  width: 10px;
  max-width: 10px;
  height: 10px;
  max-height: 10px;
  margin: 6px !important;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  border: 1px solid transparent;
  background-color: #1f1f1f;
}

.swiper-pagination .swiper-pagination-bullet-active {
  width: 18px;
  max-width: 18px;
  height: 18px;
  max-height: 18px;
  border-color: #333;
  background-color: transparent;
}

.btn {
  font-weight: 400;
  line-height: 15px;
  padding: 15px 40px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 5px;
}

.btn:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-icon {
  width: 56px;
  padding: 15px;
}

.btn-sm {
  font-size: 12px;
  padding: 5px 20px;
}

.btn-md {
  font-size: 14px;
  padding: 10px 30px;
}

.btn-lg {
  font-size: 18px;
  padding: 20px 50px;
}

.btn-xl {
  font-size: 20px;
  padding: 25px 60px;
}

.btn-primary {
  border-color: #f8796c;
  background-color: #f8796c;
  color: #fff;
}

.btn-primary:hover {
  border-color: #ff4c00;
  background-color: #ff4c00;
  color: #fff;
}

.btn-outline-primary {
  color: #f8796c;
  border-color: #f8796c;
  background-color: transparent;
}

.btn-outline-primary:hover {
  border-color: #f8796c;
  background-color: #f8796c;
  color: #fff;
}

.btn-primary2 {
  border-color: #ff4c00;
  background-color: #ff4c00;
  color: #fff;
}

.btn-primary2:hover {
  border-color: #5e9286;
  background-color: #5e9286;
  color: #fff;
}

.btn-outline-primary2 {
  color: #ff4c00;
  border-color: #ff4c00;
  background-color: transparent;
}

.btn-outline-primary2:hover {
  border-color: #ff4c00;
  background-color: #ff4c00;
  color: #fff;
}

.btn-primary3 {
  border-color: #baa491;
  background-color: #baa491;
  color: #fff;
}

.btn-primary3:hover {
  border-color: #ab9179;
  background-color: #ab9179;
  color: #fff;
}

.btn-outline-primary3 {
  color: #baa491;
  border-color: #baa491;
  background-color: transparent;
}

.btn-outline-primary3:hover {
  border-color: #baa491;
  background-color: #baa491;
  color: #fff;
}

.btn-secondary {
  border-color: #6c757d;
  background-color: #6c757d;
  color: #fff;
}

.btn-secondary:hover {
  border-color: #5a6268;
  background-color: #5a6268;
  color: #fff;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:hover {
  border-color: #6c757d;
  background-color: #6c757d;
  color: #fff;
}

.btn-success {
  border-color: #4caf50;
  background-color: #4caf50;
  color: #fff;
}

.btn-success:hover {
  border-color: #409444;
  background-color: #409444;
  color: #fff;
}

.btn-outline-success {
  color: #4caf50;
  border-color: #4caf50;
  background-color: transparent;
}

.btn-outline-success:hover {
  border-color: #4caf50;
  background-color: #4caf50;
  color: #fff;
}

.btn-danger {
  border-color: #f44336;
  background-color: #f44336;
  color: #fff;
}

.btn-danger:hover {
  border-color: #f22112;
  background-color: #f22112;
  color: #fff;
}

.btn-outline-danger {
  color: #f44336;
  border-color: #f44336;
  background-color: transparent;
}

.btn-outline-danger:hover {
  border-color: #f44336;
  background-color: #f44336;
  color: #fff;
}

.btn-warning {
  border-color: #ffc107;
  background-color: #ffc107;
  color: #333;
}

.btn-warning:hover {
  border-color: #e0a800;
  background-color: #e0a800;
  color: #333;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:hover {
  border-color: #ffc107;
  background-color: #ffc107;
  color: #333;
}

.btn-info {
  border-color: #17a2b8;
  background-color: #17a2b8;
  color: #fff;
}

.btn-info:hover {
  border-color: #138496;
  background-color: #138496;
  color: #fff;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:hover {
  border-color: #17a2b8;
  background-color: #17a2b8;
  color: #fff;
}

.btn-light {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
  color: #333;
}

.btn-light:hover {
  border-color: #e2e6ea;
  background-color: #e2e6ea;
  color: #333;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:hover {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
  color: #333;
}

.btn-dark {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.btn-dark:hover {
  border-color: #202020;
  background-color: #202020;
  color: #fff;
}

.btn-outline-dark {
  color: #333;
  border-color: #333;
  background-color: transparent;
}

.btn-outline-dark:hover {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.btn-black {
  border-color: #000;
  background-color: #000;
  color: #fff;
}

.btn-black:hover {
  border-color: black;
  background-color: black;
  color: #fff;
}

.btn-outline-black {
  color: #000;
  border-color: #000;
  background-color: transparent;
}

.btn-outline-black:hover {
  border-color: #000;
  background-color: #000;
  color: #fff;
}

.btn-grey {
  border-color: #f8f9fd;
  background-color: #f8f9fd;
  color: #fff;
}

.btn-grey:hover {
  border-color: #dae0f5;
  background-color: #dae0f5;
  color: #fff;
}

.btn-outline-grey {
  color: #f8f9fd;
  border-color: #f8f9fd;
  background-color: transparent;
}

.btn-outline-grey:hover {
  border-color: #f8f9fd;
  background-color: #f8f9fd;
  color: #fff;
}

.btn-body {
  border-color: #2c2c2c;
  background-color: #2c2c2c;
  color: #fff;
}

.btn-body:hover {
  border-color: #565656;
  background-color: #565656;
  color: #fff;
}

.btn-outline-body {
  color: #2c2c2c;
  border-color: #2c2c2c;
  background-color: transparent;
}

.btn-outline-body:hover {
  border-color: #2c2c2c;
  background-color: #2c2c2c;
  color: #fff;
}

.btn-body-light {
  border-color: #2c2c2c;
  background-color: #2c2c2c;
  color: #fff;
}

.btn-body-light:hover {
  border-color: #6b6b6b;
  background-color: #6b6b6b;
  color: #fff;
}

.btn-outline-body-light {
  color: #2c2c2c;
  border-color: #2c2c2c;
  background-color: transparent;
}

.btn-outline-body-light:hover {
  border-color: #2c2c2c;
  background-color: #2c2c2c;
  color: #fff;
}

.btn-heading {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.btn-heading:hover {
  border-color: #202020;
  background-color: #202020;
  color: #fff;
}

.btn-outline-heading {
  color: #333;
  border-color: #333;
  background-color: transparent;
}

.btn-outline-heading:hover {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.btn-hover-primary:hover,
.btn-hover-primary:not(:disabled):not(.disabled).active {
  border-color: #ff4c00;
  background-color: #ff4c00;
  color: #fff;
}

.btn-outline-hover-primary:hover,
.btn-outline-hover-primary:not(:disabled):not(.disabled).active {
  color: #f8796c;
  border-color: #f8796c;
  background-color: transparent;
}

.btn-hover-primary2:hover,
.btn-hover-primary2:not(:disabled):not(.disabled).active {
  border-color: #ff4c00;
  background-color: #ff4c00;
  color: #fff;
}

.btn-outline-hover-primary2:hover,
.btn-outline-hover-primary2:not(:disabled):not(.disabled).active {
  color: #ff4c00;
  border-color: #ff4c00;
  background-color: transparent;
}

.btn-hover-primary3:hover,
.btn-hover-primary3:not(:disabled):not(.disabled).active {
  border-color: #baa491;
  background-color: #baa491;
  color: #fff;
}

.btn-outline-hover-primary3:hover,
.btn-outline-hover-primary3:not(:disabled):not(.disabled).active {
  color: #baa491;
  border-color: #baa491;
  background-color: transparent;
}

.btn-hover-secondary:hover,
.btn-hover-secondary:not(:disabled):not(.disabled).active {
  border-color: #6c757d;
  background-color: #6c757d;
  color: #fff;
}

.btn-outline-hover-secondary:hover,
.btn-outline-hover-secondary:not(:disabled):not(.disabled).active {
  color: #6c757d;
  border-color: #6c757d;
  background-color: transparent;
}

.btn-hover-success:hover,
.btn-hover-success:not(:disabled):not(.disabled).active {
  border-color: #4caf50;
  background-color: #4caf50;
  color: #fff;
}

.btn-outline-hover-success:hover,
.btn-outline-hover-success:not(:disabled):not(.disabled).active {
  color: #4caf50;
  border-color: #4caf50;
  background-color: transparent;
}

.btn-hover-danger:hover,
.btn-hover-danger:not(:disabled):not(.disabled).active {
  border-color: #f44336;
  background-color: #f44336;
  color: #fff;
}

.btn-outline-hover-danger:hover,
.btn-outline-hover-danger:not(:disabled):not(.disabled).active {
  color: #f44336;
  border-color: #f44336;
  background-color: transparent;
}

.btn-hover-warning:hover,
.btn-hover-warning:not(:disabled):not(.disabled).active {
  border-color: #ffc107;
  background-color: #ffc107;
  color: #333;
}

.btn-outline-hover-warning:hover,
.btn-outline-hover-warning:not(:disabled):not(.disabled).active {
  color: #ffc107;
  border-color: #ffc107;
  background-color: transparent;
}

.btn-hover-info:hover,
.btn-hover-info:not(:disabled):not(.disabled).active {
  border-color: #17a2b8;
  background-color: #17a2b8;
  color: #fff;
}

.btn-outline-hover-info:hover,
.btn-outline-hover-info:not(:disabled):not(.disabled).active {
  color: #17a2b8;
  border-color: #17a2b8;
  background-color: transparent;
}

.btn-hover-light:hover,
.btn-hover-light:not(:disabled):not(.disabled).active {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
  color: #333;
}

.btn-outline-hover-light:hover,
.btn-outline-hover-light:not(:disabled):not(.disabled).active {
  color: #f8f9fa;
  border-color: #f8f9fa;
  background-color: transparent;
}

.btn-hover-dark:hover,
.btn-hover-dark:not(:disabled):not(.disabled).active {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.btn-outline-hover-dark:hover,
.btn-outline-hover-dark:not(:disabled):not(.disabled).active {
  color: #333;
  border-color: #333;
  background-color: transparent;
}

.btn-hover-black:hover,
.btn-hover-black:not(:disabled):not(.disabled).active {
  border-color: #000;
  background-color: #000;
  color: #fff;
}

.btn-outline-hover-black:hover,
.btn-outline-hover-black:not(:disabled):not(.disabled).active {
  color: #000;
  border-color: #000;
  background-color: transparent;
}

.btn-hover-grey:hover,
.btn-hover-grey:not(:disabled):not(.disabled).active {
  border-color: #f8f9fd;
  background-color: #f8f9fd;
  color: #fff;
}

.btn-outline-hover-grey:hover,
.btn-outline-hover-grey:not(:disabled):not(.disabled).active {
  color: #f8f9fd;
  border-color: #f8f9fd;
  background-color: transparent;
}

.btn-hover-body:hover,
.btn-hover-body:not(:disabled):not(.disabled).active {
  border-color: #2c2c2c;
  background-color: #2c2c2c;
  color: #fff;
}

.btn-outline-hover-body:hover,
.btn-outline-hover-body:not(:disabled):not(.disabled).active {
  color: #2c2c2c;
  border-color: #2c2c2c;
  background-color: transparent;
}

.btn-hover-body-light:hover,
.btn-hover-body-light:not(:disabled):not(.disabled).active {
  border-color: #2c2c2c;
  background-color: #2c2c2c;
  color: #fff;
}

.btn-outline-hover-body-light:hover,
.btn-outline-hover-body-light:not(:disabled):not(.disabled).active {
  color: #2c2c2c;
  border-color: #2c2c2c;
  background-color: transparent;
}

.btn-hover-heading:hover,
.btn-hover-heading:not(:disabled):not(.disabled).active {
  border-color: #333;
  background-color: #333;
  color: #fff;
}

.btn-outline-hover-heading:hover,
.btn-outline-hover-heading:not(:disabled):not(.disabled).active {
  color: #333;
  border-color: #333;
  background-color: transparent;
}

[class*="hintT"] {
  position: relative;
  z-index: 9;
}

[class*="hintT"]::before {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  position: absolute;
  z-index: 9;
  visibility: hidden;
  padding: 8px 10px;
  content: attr(data-hint);
  -webkit-transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
  white-space: nowrap;
  text-transform: initial;
  pointer-events: none;
  opacity: 0;
  color: #fff;
  background: #333;
  -webkit-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  text-shadow: 0 -1px 0 #000;
}

[class*="hintT"]::after {
  position: absolute;
  z-index: 1000001;
  z-index: 8;
  visibility: hidden;
  content: "";
  -webkit-transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1) 0s;
  opacity: 0;
  border: 6px solid transparent;
  background: 0;
}

[class*="hintT"]:hover::before,
[class*="hintT"]:hover::after {
  visibility: visible;
  opacity: 1;
}

.hintT-top::before {
  bottom: calc(100% - 4px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hintT-top::after {
  bottom: calc(100% - 16px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-top-color: #333;
}

.hintT-top:hover::before {
  bottom: calc(100% + 6px);
}

.hintT-top:hover::after {
  bottom: calc(100% - 5px);
}

.hintT-right::before {
  top: 50%;
  left: calc(100% - 4px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hintT-right::after {
  top: 50%;
  left: calc(100% - 16px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-right-color: #333;
}

.hintT-right:hover::before {
  left: calc(100% + 6px);
}

.hintT-right:hover::after {
  left: calc(100% - 5px);
}

.hintT-bottom::before {
  top: calc(100% - 4px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.hintT-bottom::after {
  top: calc(100% - 16px);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  border-bottom-color: #333;
}

.hintT-bottom:hover::before {
  top: calc(100% + 6px);
}

.hintT-bottom:hover::after {
  top: calc(100% - 5px);
}

.hintT-left::before {
  top: 50%;
  right: calc(100% - 4px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.hintT-left::after {
  top: 50%;
  right: calc(100% - 16px);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-left-color: #333;
}

.hintT-left:hover::before {
  right: calc(100% + 6px);
}

.hintT-left:hover::after {
  right: calc(100% - 5px);
}

.accordion .card {
  border-width: 1px 0 0;
  border-color: rgba(0, 0, 0, 0.075);
  border-radius: 0 !important;
}

.accordion .card:last-child {
  border-bottom-width: 1px;
}

.accordion .card .card-header {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.accordion .card .card-header .btn {
  font-family: "Marcellus";
  font-size: 18px;
  display: block;
  width: 100%;
  padding: 0;
  padding: 15px 20px;
  text-align: left;
  text-decoration: none;
  text-transform: inherit;
  color: #333;
  border: 0;
  border-radius: 0;
  background-color: transparent;
}

.accordion .card .card-header .btn:not(.collapsed) {
  background-color: rgba(0, 0, 0, 0.075);
}

.accordion .card .card-body {
  padding: 30px 0;
}

.accordion .card .card-body p {
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  color: #2c2c2c;
}

.brand-carousel .slick-track {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-carousel img {
  height: auto;
  width: 130px;
}

.brand-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.brand-item a {
  display: block;
  opacity: 0.4;
}

.brand-item a img {
  max-width: 100%;
}

.brand-item a:hover {
  opacity: 1;
}

.icon-box1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100%;
}

.icon-box1 .inner {
  text-align: center;
}

.icon-box1 .inner .icon {
  margin-bottom: 30px;
  color: #2c2c2c;
}

.icon-box1 .inner .icon i {
  font-size: 56px;
  line-height: 1;
  display: block;
}

.icon-box1 .inner .content .title {
  font-family: "Futura";
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 15px;
  color: #333;
}

.icon-box1 .inner .content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #2c2c2c;
}

.icon-box2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.icon-box2 .inner {
  text-align: left;
}

.icon-box2 .inner .icon {
  float: left;
  padding-right: 20px;
  color: #2c2c2c;
}

.icon-box2 .inner .icon i {
  font-size: 30px;
  line-height: 1;
  display: block;
}

.icon-box2 .inner .content {
  overflow: hidden;
}

.icon-box2 .inner .content .title {
  font-family: "Futura";
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
  color: #333;
}

.icon-box2 .inner .content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #2c2c2c;
}

.icon-box3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100%;
}

.icon-box3:nth-child(odd) {
  background-color: #edf6eb;
}

.icon-box3:nth-child(1) .inner .icon {
  color: #a3d39c;
}

.icon-box3:nth-child(even) {
  background-color: #f8f5de;
}

.icon-box3:nth-child(2) .inner .icon {
  color: #ddb758;
}

.icon-box3:nth-child(3) .inner .icon {
  color: #7eb7e6;
}

.icon-box3 .inner {
  padding: 20px 1pc;
  text-align: center;
  display: flex;
  align-items: center;
}

.icon-box3.col {
  width: 20%;
  margin: 2pc 0 !important;
}

.icon-box3 .inner .icon {
  margin-bottom: 30px;
  color: #2c2c2c;
}

.icon-box3 .inner .icon i {
  font-size: 56px;
  line-height: 1;
  display: block;
}

.icon-box3 .inner .content .title {
  font-family: "Futura";
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #333;
}

.icon-box3 .inner .content p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #2c2c2c;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .icon-box3 .inner {
    padding: 40px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .icon-box3 .inner {
    padding: 10px 15px;
  }
}

.icon-box4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100%;
  text-align: center;
}

.icon-box4 .inner .icon {
  margin-bottom: 30px;
  color: #2c2c2c;
}

.icon-box4 .inner .icon i {
  font-size: 56px;
  line-height: 1;
  display: block;
}

.icon-box4 .inner .content .title {
  font-family: "Futura";
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 15px;
  color: #333;
}

.icon-box4 .inner .content p {
  line-height: 1.5;
  color: #2c2c2c;
}

.icon-box5 {
  display: flex;
  justify-content: center;
}

.icon-box5 .icon {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 90px;
  -ms-flex: 1 0 90px;
  flex: 1 0 90px;
  max-width: 90px;
  height: 90px;
  text-align: center;
  color: #ff4c00;
  border-radius: 50%;
  background-color: #ffcdb8;
}

.icon-box5 .icon i {
  font-size: 22px;
  line-height: 90px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.icon-box5 .content {
  padding-left: 20px;
}

.icon-box5 .content .title {
  font-family: "Futura";
  font-size: 24px;
  font-weight: 400;
  color: #000;
  margin-bottom: 5px;
}

.icon-box5 .content p {
  color: #333;
  font-weight: 400;
}

.icon-box5:hover .icon i {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
}

blockquote:not(.learts-blockquote):not(.learts-blockquote2) {
  margin-bottom: 20px;
  margin-left: 40px;
  padding: 10px 20px;
  border-left: 2px solid #333;
}

blockquote:not(.learts-blockquote):not(.learts-blockquote2) p {
  font-size: 18px !important;
  font-weight: 300;
  font-style: italic;
  line-height: 1.675;
}

blockquote:not(.learts-blockquote):not(.learts-blockquote2) p:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  blockquote:not(.learts-blockquote):not(.learts-blockquote2) {
    margin-left: 0;
  }
}

blockquote.learts-blockquote,
.learts-blockquote {
  overflow: hidden;
  height: 100%;
  padding: 60px;
  background-color: #ffcdb8;
}

blockquote.learts-blockquote .inner .title,
.learts-blockquote .inner .title {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 20px;
}

blockquote.learts-blockquote .inner .desc,
.learts-blockquote .inner .desc {
  margin-bottom: 20px;
}

blockquote.learts-blockquote .inner .desc p,
.learts-blockquote .inner .desc p {
  font-size: 16px;
  font-weight: 400;
}

blockquote.learts-blockquote .inner .link,
.learts-blockquote .inner .link {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  float: right;
  margin-left: auto;
  color: #333;
}

blockquote.learts-blockquote .inner .link::before,
.learts-blockquote .inner .link::before {
  position: absolute;
  top: 50%;
  right: 100%;
  width: 60px;
  height: 2px;
  margin-right: 20px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #333;
}

blockquote.learts-blockquote .inner .link:hover::before,
.learts-blockquote .inner .link:hover::before {
  margin-right: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  blockquote.learts-blockquote,
  .learts-blockquote {
    padding: 50px;
  }
  blockquote.learts-blockquote .inner .title,
  .learts-blockquote .inner .title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  blockquote.learts-blockquote,
  .learts-blockquote {
    padding: 50px;
  }
  blockquote.learts-blockquote .inner .title,
  .learts-blockquote .inner .title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 767px) {
  blockquote.learts-blockquote,
  .learts-blockquote {
    padding: 40px;
  }
  blockquote.learts-blockquote .inner .title,
  .learts-blockquote .inner .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  blockquote.learts-blockquote,
  .learts-blockquote {
    padding: 30px;
  }
  blockquote.learts-blockquote .inner .title,
  .learts-blockquote .inner .title {
    font-size: 24px;
  }
}

blockquote.learts-blockquote2,
.learts-blockquote2 {
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
  text-align: center;
}

blockquote.learts-blockquote2:last-child,
.learts-blockquote2:last-child {
  margin-bottom: 0;
}

blockquote.learts-blockquote2 .icon,
.learts-blockquote2 .icon {
  margin-bottom: 36px;
}

blockquote.learts-blockquote2 .content p,
.learts-blockquote2 .content p {
  font-family: "Marcellus";
  font-size: 24px !important;
  font-weight: 400;
  line-height: 1.675;
}

label {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.125;
  display: block;
  margin-bottom: 10px;
  letter-spacing: 1.6px;
  color: #333;
}

label .required {
  font-size: 16px;
  color: red;
}

label:last-child {
  margin-bottom: 0;
}

input:not([type="checkbox"]):not([type="radio"]),
textarea {
  font-size: 18px;
  font-weight: 400;
  display: block;
  width: 100%;
  padding: 10px 0;
  color: #333;
  border: 2px solid transparent;
  border-bottom-color: #ededed;
  background-color: transparent;
}

input:not([type="checkbox"]):not([type="radio"])::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #696969;
}

input:not([type="checkbox"]):not([type="radio"]):-moz-placeholder,
textarea:-moz-placeholder {
  color: #696969;
}

input:not([type="checkbox"]):not([type="radio"])::-moz-placeholder,
textarea::-moz-placeholder {
  color: #696969;
}

input:not([type="checkbox"]):not([type="radio"]):-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #696969;
}

input:not([type="checkbox"]):not([type="radio"]):focus,
textarea:focus {
  border-bottom-color: #333;
}

.login-register-form input {
  background-color: #e8f0fe !important;
  padding: 10px 15px !important;
}

textarea {
  height: 200px;
  resize: none;
}

.form-check {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-check input[type="checkbox"] {
  margin-top: 2px;
  color: #f8796c;
}

.form-check input[type="checkbox"]:checked {
  border-color: #ff4c00 !important;
  background-color: #ff4c00 !important;
}

.form-check input[type="checkbox"]:focus {
  border-color: #ff4c00;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-check .form-check-label {
  margin-left: 10px;
}

fieldset {
  padding: 30px 40px;
  border: 1px solid #eee;
}

fieldset legend {
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  width: auto;
  margin-top: -42px;
  margin-bottom: 0;
  padding: 0 15px;
  text-transform: uppercase;
  color: #333;
  background-color: #fff;
}

.topbar-section {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  z-index: 101;
}

@media only screen and (min-width: 1500px) {
  .topbar-section.section-fluid {
    padding-right: 55px;
    padding-left: 55px;
  }
  .topbar-section.section-fluid .container,
  .topbar-section.section-fluid .container-fluid {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .topbar-section.section-fluid {
    padding-right: 35px;
    padding-left: 35px;
  }
  .topbar-section.section-fluid .container,
  .topbar-section.section-fluid .container-fluid {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .topbar-section.section-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .topbar-section.section-fluid .container {
    max-width: 960px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .topbar-section.section-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .topbar-section.section-fluid .container {
    max-width: 720px;
  }
}

.topbar-menu ul {
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.topbar-menu ul li {
  position: relative;
  margin-right: 15px;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
}

.topbar-menu ul li::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 15px;
  content: "";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #d8d8d8;
}

.topbar-menu ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.topbar-menu ul li:last-child::after {
  display: none;
}

.topbar-menu ul li a i {
  font-size: 14px;
  margin-right: 10px;
}

.header-lan-curr ~ ul li:last-child {
  margin-right: 15px;
  padding-right: 15px;
}

.header-lan-curr ~ ul li:last-child::after {
  display: block !important;
}

.header-section {
  position: relative;
  z-index: 98;
}

.header-section .container,
.header-section .container-fluid {
  position: relative;
}

.header-section .row > [class*="col"] {
  position: static;
}

.header-section.header-menu-center .row > [class*="col"]:first-child,
.header-section.header-menu-center .row > [class*="col"]:last-child {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 210px;
  -ms-flex: 1 0 210px;
  flex: 1 0 210px;
  max-width: 210px;
}

.header-section.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent !important;
}

.sticky-header {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(0%);
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}

.sticky-header .container,
.sticky-header .container-fluid {
  position: relative;
}

.sticky-header .row > [class*="col"] {
  position: static;
}

@media only screen and (min-width: 1200px) {
  .sticky-header.header-menu-center .row > [class*="col"]:first-child,
  .sticky-header.header-menu-center .row > [class*="col"]:last-child {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 210px;
    -ms-flex: 1 0 210px;
    flex: 1 0 210px;
    max-width: 210px;
  }
}

.sticky-header.is-sticky {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

body.offside-header-left {
  margin-left: 400px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  body.offside-header-left {
    margin-left: 300px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  body.offside-header-left {
    margin-left: 0;
  }
}

.header-call {
  position: absolute;
  top: 0;
  right: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 200px;
  height: 100%;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
}

.header-call p {
  font-size: 16px;
  font-weight: 600;
}

.header-call p i {
  margin-right: 10px;
}

.header-logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
}

.header-logo a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.header-lan-curr {
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.header-lan-curr > li {
  position: relative;
  margin-right: 16px;
  padding-right: 15px;
}

.header-lan-curr > li::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 15px;
  content: "";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #d8d8d8;
}

.header-lan-curr > li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.header-lan-curr > li:last-child::after {
  display: none;
}

.header-lan-curr > li > a {
  line-height: 1;
  padding: 5px 0;
  color: #2c2c2c;
}

.header-lan-curr > li > a::after {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  margin-left: 5px;
  content: "\f107";
  opacity: 0.4;
}

.header-lan-curr > li > .curr-lan-sub-menu {
  position: absolute;
  z-index: 1;
  top: 150%;
  left: -15px;
  display: block;
  visibility: hidden;
  min-width: 120px;
  margin: 0;
  padding: 10px 15px;
  list-style: none;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  letter-spacing: 0;
  opacity: 0;
  background-color: #fff;
  -webkit-box-shadow: -1px 10px 80px -15px rgba(0, 0, 0, 0.3);
  box-shadow: -1px 10px 80px -15px rgba(0, 0, 0, 0.3);
}

.header-lan-curr > li > .curr-lan-sub-menu li {
  line-height: 1.75;
  margin: 0;
  padding: 0;
}

.header-lan-curr > li > .curr-lan-sub-menu li::after {
  display: none;
}

.header-lan-curr > li > .curr-lan-sub-menu li + li {
  margin-top: 5px;
}

.header-lan-curr > li > .curr-lan-sub-menu li a {
  display: block;
  padding: 0;
  color: #2c2c2c;
}

.header-lan-curr > li > .curr-lan-sub-menu li a:hover {
  padding-left: 5px;
}

.header-lan-curr > li:hover > a {
  color: #333;
}

.header-lan-curr > li:hover > .curr-lan-sub-menu {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

.header-tools {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.header-tools > div {
  margin-left: 25px;
}

.header-tools > div a {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #333;
}

.header-tools > div a span {
  font-size: 13px;
  font-weight: 700;
  line-height: 1rem;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  min-width: 16px;
  height: 16px;
  padding: 0 3px;
  text-align: center;
  color: #fff;
  border-radius: 14px;
  background-color: #f8796c;
}

.header-tools > div a i {
  font-size: 20px;
  line-height: 32px;
  display: block;
}

.header-tools > div.header-wishlist > a i,
.header-tools > div.header-cart > a i {
  margin-right: 10px;
}

.header-tools > div.mobile-menu-toggle > a {
  width: 24px;
  height: 32px;
}

.header-tools > div:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 575px) {
  .header-tools > div {
    margin-left: 15px;
  }
}

.mobile-menu-toggle svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 60px;
  margin-top: -2px;
  margin-left: -2px;
  cursor: pointer;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.mobile-menu-toggle svg path {
  -webkit-transition: stroke-dashoffset 0.5s
      cubic-bezier(0.25, -0.25, 0.75, 1.25),
    stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
    stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
    stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  fill: none;
  stroke: #333;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 30px;
}

.mobile-menu-toggle svg path.top,
.mobile-menu-toggle svg path.bottom {
  stroke-dasharray: 240px 950px;
}

.mobile-menu-toggle svg path.middle {
  stroke-dasharray: 240px 240px;
}

.mobile-menu-toggle .close {
  opacity: 1;
}

.mobile-menu-toggle .close svg path.top,
.mobile-menu-toggle .close svg path.bottom {
  stroke-dasharray: -650px;
  stroke-dashoffset: -650px;
}

.mobile-menu-toggle .close svg path.middle {
  stroke-dasharray: 1px 220px;
  stroke-dashoffset: -115px;
}

.site-menu-section {
  border-top: 1px dashed #dedede;
}

.site-main-menu {
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.site-main-menu > ul {
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.site-main-menu > ul > li {
  display: flex;
  align-items: center;
  margin-right: 10px;
  letter-spacing: 1.5px;
}

.site-main-menu > ul > li > a {
  line-height: 1.5;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 25px;
  text-transform: uppercase;
  color: #2c2c2c;
}

.site-main-menu > ul > li > a > .menu-text {
  position: relative;
}

.site-main-menu > ul > li > a > .menu-text::after {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 1px;
  content: "";
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  -o-transition: transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.8, 0, 0.2, 1),
    -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.8, 0, 0.2, 1),
    -webkit-transform 0.5s cubic-bezier(0.8, 0, 0.2, 1);
  -webkit-transform: scale3d(0, 1, 1);
  transform: scale3d(0, 1, 1);
  -webkit-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  background-color: white;
}
.menu-text{
  color: white ;
}

/* .site-main-menu > ul > li.has-children > a::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 10px;
  content: "\f107";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.6;
} */

.site-main-menu > ul > li.align-left > .sub-menu {
  right: 0;
  left: auto;
}


.site-main-menu > ul > li:hover > a {
  color: #333;
}

.site-main-menu > ul > li:hover > a > .menu-text::after {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  -webkit-transform-origin: 0 50%;
  -ms-transform-origin: 0 50%;
  transform-origin: 0 50%;
}

.site-main-menu > ul > li:hover > .sub-menu {
  z-index: 99;
  top: 70%;
  visibility: visible;
  opacity: 1;
}

.site-main-menu.menu-height-60 > ul > li > a {
  padding-top: 18px;
  padding-bottom: 18px;
}

.sub-menu {
  position: absolute;
  z-index: 9;
  z-index: 9;
  top: 100%;
  visibility: hidden;
  min-width: 280px;
  margin: 0;
  padding: 20px 0;
  list-style: none;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  letter-spacing: 0;
  opacity: 0;
  background-color: #fff;
  -webkit-box-shadow: -1px 10px 80px -15px rgba(0, 0, 0, 0.3);
  box-shadow: -1px 10px 80px -15px rgba(0, 0, 0, 0.3);
}

.sub-menu li {
  line-height: 3.125;
  position: relative;
}

.sub-menu li a {
  position: relative;
  display: block;
  padding: 0 30px;
  color: #2c2c2c;
}

.sub-menu li a:not(.mega-menu-title) .menu-text {
  position: relative;
}

.sub-menu li a:not(.mega-menu-title) .menu-text:hover{
  font-weight: bold;
}

.sub-menu li a:not(.mega-menu-title) .menu-text::after {
  position: absolute;
  top: calc(50% + 10px);
  left: 0;
  width: 100%;
  /* height: 1px; */
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 1;
  /* color: #000; */
  background-color: #333;
}

.sub-menu li a:hover .menu-text::after {
  opacity: 1;
}

.sub-menu li.has-children > a::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  position: absolute;
  top: 50%;
  right: 30px;
  content: "\f105";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.6;
}

.sub-menu li.has-children.align-left > a::before {
  content: "\f104";
}

.sub-menu li.has-children.align-left > .sub-menu {
  right: 100%;
  left: auto;
}

.sub-menu li > .sub-menu {
  top: 100%;
  left: 100%;
}

.sub-menu li:hover > a {
  color: #333;
}

.sub-menu li:hover > .sub-menu {
  top: 0;
  visibility: visible;
  opacity: 1;
}

.sub-menu.mega-menu {
  right: auto;
  left: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 1170px;
  margin: 0;
  padding: 30px 30px 30px 10px;
  list-style: none;
}

.sub-menu.mega-menu > li {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 25%;
  -ms-flex: 1 0 25%;
  flex: 1 0 25%;
  padding: 0 10px 0 30px;
  border-right: 1px solid #e7e7e7;
}

.sub-menu.mega-menu > li:last-child {
  border: 0;
}

.sub-menu.mega-menu > li > a {
  padding: 0;
}

.sub-menu.mega-menu > li > a.mega-menu-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 16px;
  letter-spacing: 0.01em;
  color: #333;
}

.sub-menu.mega-menu > li:hover > a {
  color: #333;
}

.sub-menu.mega-menu > li ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sub-menu.mega-menu > li ul li {
  line-height: 3.125;
  position: relative;
}

.sub-menu.mega-menu > li ul li a {
  padding: 0;
  color: #2c2c2c;
}

.sub-menu.mega-menu > li ul li > img {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 40%;
  visibility: hidden;
  width: 250px;
  height: auto;
  -webkit-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
  opacity: 0;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.sub-menu.mega-menu > li ul li:hover > a {
  color: #333;
}

.sub-menu.mega-menu > li ul li:hover > img {
  visibility: visible;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}

body.offcanvas-open {
  overflow: hidden;
  padding-right: 17px;
}

body.offcanvas-open .sticky-header {
  right: 17px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  body.offcanvas-open {
    padding-right: 0;
  }
  body.offcanvas-open .sticky-header {
    right: 0;
  }
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas {
  font-size: 16px;
  font-weight: 400;
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  overflow: hidden;
  width: 400px;
  height: 100vh;
  padding: 20px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.offcanvas.offcanvas-open {
  visibility: visible;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.offcanvas .inner {
  position: relative;
  z-index: 9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.offcanvas .inner.ps {
  margin-right: -10px;
}

.offcanvas .inner .head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.offcanvas .inner .head .title {
  font-size: 18px;
  line-height: 1;
  color: #333;
}

.offcanvas .inner .head .offcanvas-close {
  position: relative;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  border: 0;
  background-color: transparent;
}

.offcanvas .inner .head .offcanvas-close::before,
.offcanvas .inner .head .offcanvas-close::after {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 20px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #333;
}

.offcanvas .inner .head .offcanvas-close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.offcanvas .inner .head .offcanvas-close:hover::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.offcanvas .inner .head .offcanvas-close:hover::after {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.offcanvas .inner .body {
  overflow: hidden;
  height: auto;
}

.offcanvas .inner .foot {
  width: 100%;
  margin-top: 25px;
}

.offcanvas .inner .foot .sub-total {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid #eee;
}

.offcanvas .inner .foot .sub-total strong {
  font-weight: 600;
  color: #333;
}

.offcanvas .inner .foot .sub-total .amount {
  font-weight: 600;
  color: #f8796c;
}

.offcanvas .inner .foot .buttons {
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.offcanvas .inner .foot .buttons .btn {
  display: block;
  padding: 10px 20px;
}

.offcanvas .inner .foot .buttons .btn + .btn {
  margin-top: 15px;
}

.offcanvas .inner .foot .minicart-message {
  margin-top: 15px;
}

.offcanvas.offcanvas-cart .inner .body {
  max-height: calc(100% - (60px + 245px));
}

.offcanvas.offcanvas-wishlist .inner .body {
  max-height: calc(100% - (60px + 92px));
}

.offcanvas.offcanvas-mobile-menu {
  right: auto;
  left: 0;
  padding: 50px 40px;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-mobile-menu.offcanvas-open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.offcanvas.offcanvas-search {
  width: 100%;
  padding: 40px;
  -webkit-transition: all 0.5s ease 0.5s;
  -o-transition: all 0.5s ease 0.5s;
  transition: all 0.5s ease 0.5s;
}

.offcanvas.offcanvas-search::before {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: #fff;
}

.offcanvas.offcanvas-search .inner {
  z-index: 5;
  padding-right: 10%;
}

.offcanvas.offcanvas-search .inner .offcanvas-close {
  position: absolute;
  top: 5px;
  right: 0;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  border: 0;
  background-color: transparent;
}

.offcanvas.offcanvas-search .inner .offcanvas-close::before,
.offcanvas.offcanvas-search .inner .offcanvas-close::after {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 50px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #333;
}

.offcanvas.offcanvas-search .inner .offcanvas-close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.offcanvas.offcanvas-search .inner .offcanvas-close:hover::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.offcanvas.offcanvas-search .inner .offcanvas-close:hover::after {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.offcanvas.offcanvas-search.offcanvas-open {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.offcanvas.offcanvas-search.offcanvas-open::before {
  width: 0;
  -webkit-transition: all 0.5s ease 0.5s;
  -o-transition: all 0.5s ease 0.5s;
  transition: all 0.5s ease 0.5s;
}

.offcanvas.offcanvas-header {
  position: fixed;
  right: auto;
  left: 0;
  visibility: visible;
  padding: 50px 40px;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .offcanvas.offcanvas-header {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .offcanvas.offcanvas-header.offcanvas-open {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.offcanvas.offcanvas-overlay-menu {
  left: 0;
  visibility: hidden;
  width: 100%;
  padding: 40px;
  -webkit-transition: all 0.5s ease 0.5s;
  -o-transition: all 0.5s ease 0.5s;
  transition: all 0.5s ease 0.5s;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0;
  background-color: #ffcdb8;
}

.offcanvas.offcanvas-overlay-menu .inner {
  z-index: 5;
  padding-right: 0;
}

.offcanvas.offcanvas-overlay-menu .inner .offcanvas-close {
  position: absolute;
  z-index: 9;
  top: 5px;
  right: 0;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  border: 0;
  background-color: transparent;
}

.offcanvas.offcanvas-overlay-menu .inner .offcanvas-close::before,
.offcanvas.offcanvas-overlay-menu .inner .offcanvas-close::after {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 50px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #333;
}

.offcanvas.offcanvas-overlay-menu .inner .offcanvas-close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.offcanvas.offcanvas-overlay-menu .inner .offcanvas-close:hover::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.offcanvas.offcanvas-overlay-menu .inner .offcanvas-close:hover::after {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.offcanvas.offcanvas-overlay-menu.offcanvas-open {
  visibility: visible;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .offcanvas.offcanvas-header {
    width: 300px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas.offcanvas-search .inner .offcanvas-close {
    width: 40px;
    height: 40px;
  }
  .offcanvas.offcanvas-search .inner .offcanvas-close::before,
  .offcanvas.offcanvas-search .inner .offcanvas-close::after {
    width: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas.offcanvas-search .inner {
    padding-right: 40px;
  }
  .offcanvas.offcanvas-search .inner .offcanvas-close {
    width: 30px;
    height: 30px;
  }
  .offcanvas.offcanvas-search .inner .offcanvas-close::before,
  .offcanvas.offcanvas-search .inner .offcanvas-close::after {
    width: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .offcanvas.offcanvas-search {
    padding: 30px;
  }
  .offcanvas.offcanvas-overlay-menu {
    padding: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .offcanvas {
    width: 300px;
  }
  .offcanvas.offcanvas-search {
    padding: 20px;
  }
  .offcanvas.offcanvas-overlay-menu {
    padding: 20px;
  }
}

.minicart-product-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.minicart-product-list li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.minicart-product-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.minicart-product-list li .image {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 75px;
  -ms-flex: 1 0 75px;
  flex: 1 0 75px;
}

.minicart-product-list li .content {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 calc(100% - 75px);
  -ms-flex: 1 0 calc(100% - 75px);
  flex: 1 0 calc(100% - 75px);
  padding-left: 15px;
}

.minicart-product-list li .content .title {
  color: #2c2c2c;
}

.minicart-product-list li .content .title:hover {
  color: #333;
}

.minicart-product-list li .content .quantity-price {
  font-size: 12px;
  display: block;
  margin-top: 10px;
}

.minicart-product-list li .content .quantity-price .amount {
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.minicart-product-list li .content .remove {
  font-size: 24px;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 5px;
  color: #2c2c2c;
}

.minicart-product-list li .content .remove:hover {
  color: red;
}

.offcanvas-search-form input[type="text"] {
  font-size: 48px;
  line-height: 1;
  width: 95%;
  padding: 5px 0;
  vertical-align: middle;
  border: 0;
  border-bottom: 2px solid #333;
  background-color: #fff;
  background-size: 30px;
}

.offcanvas-search-form input[type="text"]::-webkit-input-placeholder {
  opacity: 0.5;
}

.offcanvas-search-form input[type="text"]:-moz-placeholder {
  opacity: 0.5;
}

.offcanvas-search-form input[type="text"]::-moz-placeholder {
  opacity: 0.5;
}

.offcanvas-search-form input[type="text"]:-ms-input-placeholder {
  opacity: 0.5;
}

.offcanvas-search-form .select2 {
  font-size: 48px;
  line-height: 1;
  width: 100% !important;
  border: 0;
  border-bottom: 2px solid #333;
}

.offcanvas-search-form .select2 .selection .select2-selection {
  font-size: inherit;
  height: 75px !important;
  padding: 5px 0;
  border: none !important;
  border-radius: 0;
  background-color: #fff;
}

.offcanvas-search-form
  .select2
  .selection
  .select2-selection
  .select2-selection__rendered {
  font-size: inherit;
  font-weight: 400;
  line-height: inherit;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding-left: 0;
  opacity: 0.8;
}

.offcanvas-search-form
  .select2
  .selection
  .select2-selection
  .select2-selection__rendered::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  position: absolute;
  top: 50%;
  right: 0;
  content: "\f107";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.offcanvas-search-form
  .select2
  .selection
  .select2-selection
  .select2-selection__arrow {
  display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .offcanvas-search-form input[type="text"],
  .offcanvas-search-form .select2 {
    font-size: 36px;
  }
  .offcanvas-search-form .select2 .selection .select2-selection {
    height: 59px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offcanvas-search-form input[type="text"],
  .offcanvas-search-form .select2 {
    font-size: 36px;
    width: 100%;
  }
  .offcanvas-search-form .select2 .selection .select2-selection {
    height: 59px !important;
  }
}

@media only screen and (max-width: 767px) {
  .offcanvas-search-form input[type="text"],
  .offcanvas-search-form .select2 {
    font-size: 30px;
    width: 100%;
  }
  .offcanvas-search-form .select2 .selection .select2-selection {
    height: 51px !important;
  }
}

@media only screen and (max-width: 575px) {
  .offcanvas-search-form input[type="text"],
  .offcanvas-search-form .select2 {
    font-size: 24px;
  }
  .offcanvas-search-form .select2 .selection .select2-selection {
    height: 42px !important;
  }
}

@media only screen and (max-width: 479px) {
  .offcanvas-search-form input[type="text"],
  .offcanvas-search-form .select2 {
    font-size: 20px;
  }
  .offcanvas-search-form .select2 .selection .select2-selection {
    height: 37px !important;
  }
}

.offcanvas-logo {
  margin-bottom: 50px;
}

.offcanvas-menu-search-form {
  margin-bottom: 30px;
}

.offcanvas-menu-search-form form {
  position: relative;
}

.offcanvas-menu-search-form form input[type="text"] {
  width: 100%;
  padding: 8px 20px;
  padding-right: 50px;
  color: #2c2c2c;
  border: 1px solid #eee;
}

.offcanvas-menu-search-form form button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 5px 15px;
  border: 0;
  background-color: transparent;
}

.offcanvas-menu > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.offcanvas-menu > ul > li {
  position: relative;
}

.offcanvas-menu > ul > li > a {
  display: block;
  padding: 8px 24px 8px 0;
  text-transform: uppercase;
  color: #2c2c2c;
}

.offcanvas-menu > ul > li > a:hover {
  color: #333;
}

.offcanvas-menu > ul > li .menu-expand {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 24px;
  height: 44px;
  cursor: pointer;
  background-color: transparent;
}

.offcanvas-menu > ul > li .menu-expand::before,
.offcanvas-menu > ul > li .menu-expand::after {
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 7px);
  width: 14px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
  background-color: #2c2c2c;
}

.offcanvas-menu > ul > li .menu-expand::after {
  -webkit-transform: rotate(90deg) scale(0.75);
  -ms-transform: rotate(90deg) scale(0.75);
  transform: rotate(90deg) scale(0.75);
}

.offcanvas-menu > ul > li.active > .menu-expand::after {
  -webkit-transform: rotate(0) scale(0.75);
  -ms-transform: rotate(0) scale(0.75);
  transform: rotate(0) scale(0.75);
}

.offcanvas-menu > ul > li .sub-menu {
  position: static;
  top: auto;
  display: none;
  visibility: visible;
  width: 100%;
  min-width: auto;
  padding: 0;
  padding-left: 15px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  opacity: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.offcanvas-menu > ul > li .sub-menu li {
  line-height: inherit;
  position: relative;
}

.offcanvas-menu > ul > li .sub-menu li > a {
  display: block;
  padding: 8px 24px 8px 0;
  color: #2c2c2c;
}

.offcanvas-menu > ul > li .sub-menu li > a:hover {
  color: #333;
}

.offcanvas-menu > ul > li .sub-menu li.active > .menu-expand::after {
  -webkit-transform: rotate(0) scale(0.75);
  -ms-transform: rotate(0) scale(0.75);
  transform: rotate(0) scale(0.75);
}

.offcanvas-buttons {
  margin-top: 30px;
}

.offcanvas-buttons:not(:last-child) {
  margin-bottom: 30px;
}

.offcanvas-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.offcanvas-social a {
  line-height: 1;
  width: 48px;
  height: 48px;
  margin: 0 5px;
  padding: 0;
  text-align: center;
  color: #333;
  border-radius: 50%;
  background-color: #ffcdb8;
}

.offcanvas-social a:hover {
  color: #fff;
  background-color: #333;
}

.offcanvas-social a:last-child {
  margin-right: 0;
}

.offcanvas-social a i {
  line-height: 48px;
}

.overlay-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  margin: 0 -40px;
}

.overlay-menu > ul {
  font-size: 32px;
  overflow-x: auto;
  width: 100%;
  max-height: 100%;
  margin-bottom: 0;
  padding: 0 40px;
  list-style: none;
  text-align: center;
}

.overlay-menu > ul > li {
  position: relative;
}

.overlay-menu > ul > li + li {
  margin-top: 15px;
}

.overlay-menu > ul > li > a {
  font-family: "Futura";
  font-weight: 400;
  line-height: 1.75;
  text-transform: uppercase;
  color: #333;
}

.overlay-menu > ul .sub-menu {
  font-size: 75%;
  position: static;
  display: none;
  visibility: visible;
  padding-bottom: 0;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  opacity: 1;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.overlay-menu > ul .sub-menu > li + li {
  margin-top: 15px;
}

.overlay-menu > ul .sub-menu > li > a {
  font-family: "Futura";
  font-weight: 400;
  line-height: 1.75;
  text-transform: uppercase;
}

.overlay-menu > ul .sub-menu .sub-menu {
  font-size: 70%;
}

@media only screen and (max-width: 575px) {
  .overlay-menu {
    margin: 0 -30px;
  }
  .overlay-menu > ul {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 479px) {
  .overlay-menu {
    margin: 0 -20px;
  }
  .overlay-menu > ul {
    padding: 0 20px;
  }
}

.header2-search form {
  position: relative;
}

.header2-search form input[type="text"] {
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  height: 40px;
  margin: 0;
  padding-right: 10px;
  padding-left: 30px;
  color: #333;
  border: 0;
  background-color: transparent;
}

.header2-search form input[type="text"]::-webkit-input-placeholder {
  letter-spacing: 0.5px;
  color: #696969;
}

.header2-search form input[type="text"]:-moz-placeholder {
  letter-spacing: 0.5px;
  color: #696969;
}

.header2-search form input[type="text"]::-moz-placeholder {
  letter-spacing: 0.5px;
  color: #696969;
}

.header2-search form input[type="text"]:-ms-input-placeholder {
  letter-spacing: 0.5px;
  color: #696969;
}

.header2-search form .btn {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 30px;
  height: 40px;
  padding: 0;
  text-align: center;
  color: #2c2c2c;
}

.header2-search form .btn i {
  line-height: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .header2-search form input[type="text"] {
    width: 135px;
  }
}

.header6-search {
  width: 100%;
  max-width: 650px;
  margin: auto;
}

.header6-search form {
  position: relative;
}

.header6-search form .select2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  width: 170px !important;
  padding: 7px 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
  border: 0;
}

.header6-search form .select2 .selection .select2-selection {
  height: auto;
  padding: 5px 0;
  border: none !important;
  border-radius: 0;
  background-color: #fff;
}

.header6-search
  form
  .select2
  .selection
  .select2-selection
  .select2-selection__rendered {
  font-weight: 500;
  line-height: inherit;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding-left: 0;
  opacity: 0.8;
}

.header6-search
  form
  .select2
  .selection
  .select2-selection
  .select2-selection__rendered::before {
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  position: absolute;
  top: 50%;
  right: 5px;
  content: "\f0d7";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header6-search
  form
  .select2
  .selection
  .select2-selection
  .select2-selection__arrow {
  display: none;
}

.header6-search form input[type="text"] {
  font-size: 16px;
  line-height: 26px;
  width: 100%;
  padding: 10px 20px;
  color: #2c2c2c;
  border-width: 2px;
  border-style: solid;
  border-color: transparent transparent #ededed;
}

.header6-search form button {
  width: 30px;
  margin-left: -30px;
  padding: 0;
  text-align: center;
  color: #333;
  border: 0;
  background-color: transparent;
}

.header-categories {
  position: absolute;
  top: 0;
  left: 15px;
}

.header-categories .category-toggle {
  font-family: "Futura";
  line-height: 30px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 35px;
  padding-right: 70px;
  letter-spacing: 0.1px;
  color: #fff;
  border: 0;
  background-color: #333;
}

.header-categories .category-toggle i {
  line-height: 1;
  margin-right: 10px;
}

.header-categories .category-toggle::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  line-height: 12px;
  position: absolute;
  top: 50%;
  right: 30px;
  width: 12px;
  margin-top: -6px;
  content: "\f0d7";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.header-categories .category-toggle.active::after {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.header-categories + .site-main-menu {
  padding: 0 200px 0 270px;
}

.header-category-list {
  display: none;
  width: 100%;
  margin: 0;
  padding-left: 0;
  list-style: none;
  background-color: #ff4c00;
}

.header-category-list li {
  padding: 0 35px;
  border-bottom: 1px solid #ff4c00;
}

.header-category-list li a {
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: -1px;
  padding: 15px 0;
  color: #fff;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
}

.header-category-list li a img {
  margin-right: 20px;
}

.header-category-list li:last-child {
  border-bottom: 0;
}

.header-category-list li:last-child a {
  border: 0;
}

.header-category-list li:hover {
  background-color: #63998d;
}

.home1-slider {
  width: 100%;
  height: 750px;
}

.home1-slider:hover [class*="swiper-button"] {
  opacity: 1;
}

.home1-slider:hover [class*="swiper-button"]:hover i {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

@media only screen and (max-width: 767px) {
  .home1-slider {
    height: 550px;
  }
}

@media only screen and (max-width: 575px) {
  .home1-slider {
    height: 500px;
  }
}

@media only screen and (max-width: 479px) {
  .home1-slider {
    height: 450px;
  }
}

.home1-slide-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.home1-slide1-content {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 466px;
  height: 466px;
  padding: 25px;
  border-radius: 50%;
  background-color: transparent;
}

.home1-slide1-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home1-slide1-content .bg {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  border-radius: 50%;
  background-color: #fff;
}

.home1-slide1-content .slide-border {
  position: absolute;
  z-index: -1;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  border: 1px dashed #ff4c00;
  border-radius: 50%;
}

.home1-slide1-content .icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 15px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
}

.home1-slide1-content .title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 11px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #333;
}

.home1-slide1-content .sub-title {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 28px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #ff4c00;
}

.home1-slide1-content .link {
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home1-slide1-content .link a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 2px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid #333;
}

.home1-slide1-content .link a:hover {
  color: #ff4c00;
  border-color: #ff4c00;
}

@media only screen and (max-width: 767px) {
  .home1-slide1-content {
    width: 400px;
    height: 400px;
  }
  .home1-slide1-content .icon img {
    height: 100px;
  }
  .home1-slide1-content .title {
    font-size: 54px;
  }
  .home1-slide1-content .sub-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .home1-slide1-content .link a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .home1-slide1-content {
    width: 350px;
    height: 350px;
  }
  .home1-slide1-content .icon img {
    height: 80px;
  }
  .home1-slide1-content .title {
    font-size: 48px;
  }
  .home1-slide1-content .sub-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .home1-slide1-content {
    width: 300px;
    height: 300px;
  }
  .home1-slide1-content .icon img {
    height: 60px;
  }
  .home1-slide1-content .title {
    font-size: 40px;
  }
  .home1-slide1-content .sub-title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .home1-slide1-content .link a {
    font-size: 12px;
  }
}

.home1-slide2-content {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 466px;
  height: 466px;
  padding: 25px;
  border-radius: 50%;
  background-color: transparent;
}

.home1-slide2-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home1-slide2-content .bg {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  border-radius: 50%;
  background-color: transparent;
}

.home1-slide2-content .slide-border {
  position: absolute;
  z-index: -1;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  border: 3px solid #e1d8d0;
  border-radius: 50%;
}

.home1-slide2-content .icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 25px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
}

.home1-slide2-content .icon img:last-child:not(:first-child) {
  margin-left: 15px;
}

.home1-slide2-content .title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 11px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #baa491;
}

.home1-slide2-content .sub-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 25px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  text-align: center;
  color: #333;
}

.home1-slide2-content .link {
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home1-slide2-content .link a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 2px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid #333;
}

.home1-slide2-content .link a:hover {
  color: #ff4c00;
  border-color: #ff4c00;
}

@media only screen and (max-width: 767px) {
  .home1-slide2-content {
    width: 400px;
    height: 400px;
  }
  .home1-slide2-content .title {
    font-size: 54px;
    margin-bottom: 20px;
  }
  .home1-slide2-content .sub-title {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .home1-slide2-content .link a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .home1-slide2-content {
    width: 350px;
    height: 350px;
  }
  .home1-slide2-content .title {
    font-size: 48px;
  }
  .home1-slide2-content .sub-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .home1-slide2-content {
    width: 300px;
    height: 300px;
  }
  .home1-slide2-content .icon {
    margin-bottom: 15px;
  }
  .home1-slide2-content .title {
    font-size: 40px;
  }
  .home1-slide2-content .sub-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .home1-slide2-content .link a {
    font-size: 12px;
  }
}

.home1-slide3-content {
  max-width: calc(100% - 20px);
  margin-top: 80px;
  text-align: center;
}

.home1-slide3-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home1-slide3-content .title {
  font-family: "Modesty";
  font-size: 76px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 20px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #baa491;
}

.home1-slide3-content .sub-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 1.1;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 35px;
  padding: 0 80px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  text-align: center;
  color: #000;
}

.home1-slide3-content .sub-title img {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  opacity: 0;
}

.home1-slide3-content .sub-title img.left-icon {
  left: 0;
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.home1-slide3-content .sub-title img.right-icon {
  right: 0;
  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
}

.home1-slide3-content .link {
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home1-slide3-content .link a {
  font-family: "Marcellus";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  z-index: 9;
  padding: 0 45px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  background-image: url("../images/slider/home1/slide-3-3.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .home1-slide3-content {
    margin-top: 60px;
  }
  .home1-slide3-content .title {
    font-size: 70px;
    margin-bottom: 20px;
  }
  .home1-slide3-content .sub-title {
    font-size: 36px;
    margin-bottom: 25px;
  }
  .home1-slide3-content .link a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .home1-slide3-content {
    margin-top: 60px;
  }
  .home1-slide3-content .title {
    font-size: 54px;
  }
  .home1-slide3-content .sub-title {
    font-size: 30px;
    margin-bottom: 20px;
    padding: 0 50px;
  }
  .home1-slide3-content .sub-title img {
    height: 16px;
    margin-top: -8px;
  }
}

@media only screen and (max-width: 479px) {
  .home1-slide3-content .title {
    font-size: 40px;
  }
  .home1-slide3-content .sub-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .home1-slide3-content .link a {
    font-size: 12px;
  }
}

.swiper-slide-active .home1-slide1-content > * {
  opacity: 1;
}

.swiper-slide-active .home1-slide1-content .bg {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-slide-active .home1-slide1-content .slide-border {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-slide-active .home1-slide1-content .icon {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide1-content .title {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide1-content .sub-title {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide1-content .link {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide2-content > * {
  opacity: 1;
}

.swiper-slide-active .home1-slide2-content .bg {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-slide-active .home1-slide2-content .slide-border {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-slide-active .home1-slide2-content .icon {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide2-content .title {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide2-content .sub-title {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide2-content .link {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide3-content > * {
  opacity: 1;
}

.swiper-slide-active .home1-slide3-content .title {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide3-content .sub-title {
  -webkit-transition-delay: 0.95s;
  -o-transition-delay: 0.95s;
  transition-delay: 0.95s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home1-slide3-content .sub-title img {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
  opacity: 1;
}

.swiper-slide-active .home1-slide3-content .sub-title img.left-icon {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home1-slide3-content .sub-title img.right-icon {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home1-slide3-content .link {
  -webkit-transition-delay: 1.15s;
  -o-transition-delay: 1.15s;
  transition-delay: 1.15s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.home2-slider {
  width: 100%;
  height: 770px;
}

.home2-slider [class*="swiper-button"]:hover i {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.home2-slider:hover [class*="swiper-button"] {
  opacity: 1;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .home2-slider {
    height: 550px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home2-slider {
    height: 450px;
  }
}

@media only screen and (max-width: 575px) {
  .home2-slider {
    height: 650px;
  }
}

.home2-slide-item .slide-pointer {
  overflow: hidden;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  text-align: center;
  opacity: 0;
  color: #fff;
  border: 0;
  border-radius: 50%;
  background-color: #da0e2b;
}

.home2-slide-item .slide-pointer span {
  font-size: 40px;
  line-height: 1;
  display: block;
  margin-top: -5px;
}

.home2-slide-item .slide-product {
  position: absolute;
  display: none;
  width: 300px;
  padding: 40px;
  background-color: #fff;
}

.home2-slide-item .slide-product .image {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 240px;
  margin-bottom: 20px;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home2-slide-item .slide-product .image img {
  max-width: 100%;
  max-height: 100%;
}

.home2-slide-item .slide-product .title {
  font-family: "Futura";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  color: #333;
}

.home2-slide-item .slide-product .price {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  color: #333;
}

.home2-slide-item .slide-product.active {
  display: block;
}

.home2-slide-item .slide-product.active .image {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .home2-slide-item .slide-pointer {
    width: 30px;
    height: 30px;
  }
  .home2-slide-item .slide-pointer span {
    font-size: 36px;
    margin-top: -5px;
  }
  .home2-slide-item .slide-product {
    width: 180px;
    padding: 30px;
  }
  .home2-slide-item .slide-product .image {
    height: 140px;
    margin-bottom: 20px;
  }
  .home2-slide-item .slide-product .title {
    font-size: 16px;
  }
  .home2-slide-item .slide-product .price {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home2-slide-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .home2-slide-item .slide-product {
    width: 180px;
    padding: 30px;
  }
  .home2-slide-item .slide-product .image {
    height: 140px;
    margin-bottom: 20px;
  }
  .home2-slide-item .slide-product .title {
    font-size: 16px;
  }
  .home2-slide-item .slide-product .price {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .home2-slide-item .slide-pointer {
    display: none;
  }
}

.home2-slide1-image {
  position: absolute;
  z-index: 9;
  top: 10%;
  right: 15%;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .home2-slide1-image {
    max-width: 500px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home2-slide1-image {
    max-width: 400px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home2-slide1-image {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    display: inline-block;
    max-width: 600px;
    margin: 0 auto;
  }
}

.home2-slide1-product1 {
  position: absolute;
  top: 10%;
  right: 22%;
}

.home2-slide1-product1 .slide-product {
  top: 0;
  left: 100%;
  margin-left: 20px;
  -webkit-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home2-slide1-product1 .slide-product {
    right: 100%;
    left: auto;
    margin-right: 20px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .home2-slide1-product1 .slide-product {
    right: 100%;
    left: auto;
    margin-right: 20px;
    margin-left: 0;
  }
}

.home2-slide2-image {
  position: absolute;
  z-index: 9;
  top: 14%;
  right: 9%;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .home2-slide2-image {
    max-width: 700px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home2-slide2-image {
    max-width: 550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home2-slide2-image {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    display: inline-block;
    max-width: 600px;
    margin: 0 auto 20px;
  }
}

.home2-slide2-product1 {
  position: absolute;
  bottom: 27%;
  left: 7%;
}

.home2-slide2-product1 .slide-product {
  right: 100%;
  bottom: 100%;
  margin-right: 30px;
  -webkit-transform: translateY(15%);
  -ms-transform: translateY(15%);
  transform: translateY(15%);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home2-slide2-product1 .slide-product {
    right: auto;
    left: 100%;
    margin-right: 0;
    margin-left: 20px;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
  }
}

.home2-slide2-product2 {
  position: absolute;
  top: 30%;
  left: 51%;
}

.home2-slide2-product2 .slide-product {
  top: 50%;
  left: 100%;
  margin-left: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home2-slide2-product2 .slide-product {
    margin-left: 20px;
  }
}

.home2-slide3-image {
  position: absolute;
  z-index: 9;
  right: 30%;
  bottom: 0;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .home2-slide3-image {
    right: 25%;
    max-width: 450px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home2-slide3-image {
    right: 20%;
    max-width: 350px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home2-slide3-image {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    display: inline-block;
    max-width: 300px;
    margin: 0 auto 20px;
  }
}

.home2-slide3-product1 {
  position: absolute;
  bottom: 55%;
  left: 5%;
}

.home2-slide3-product1 .slide-product {
  top: 50%;
  right: 100%;
  margin-right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 767px) {
  .home2-slide3-product1 .slide-product {
    right: auto;
    left: 100%;
    margin-right: auto;
    margin-left: 20px;
  }
}

.home2-slide3-product2 {
  position: absolute;
  right: 3%;
  bottom: 28%;
}

.home2-slide3-product2 .slide-product {
  bottom: 100%;
  left: 100%;
  margin-left: 20px;
  -webkit-transform: translateY(15%);
  -ms-transform: translateY(15%);
  transform: translateY(15%);
}

@media only screen and (max-width: 767px) {
  .home2-slide3-product2 .slide-product {
    right: 100%;
    left: auto;
    margin-right: 20px;
    margin-left: auto;
  }
}

.home2-slide-content {
  position: absolute;
  z-index: 99;
  bottom: 18%;
  left: 8%;
}

.home2-slide-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home2-slide-content .sub-title {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 30px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  letter-spacing: 5px;
  color: #333;
}

.home2-slide-content .title {
  font-size: 56px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #333;
}

.home2-slide-content .link {
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home2-slide-content .link a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 2px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid #333;
}

.home2-slide-content .link a:hover {
  color: #ff4c00;
  border-color: #ff4c00;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home2-slide-content .sub-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .home2-slide-content .title {
    font-size: 36px;
  }
  .home2-slide-content .link a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home2-slide-content {
    position: static;
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .home2-slide-content .sub-title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .home2-slide-content .title {
    font-size: 44px;
  }
  .home2-slide-content .link a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .home2-slide-content .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .home2-slide-content .sub-title {
    font-size: 14px;
  }
  .home2-slide-content .title {
    font-size: 30px;
  }
  .home2-slide-content .link a {
    font-size: 12px;
  }
}

.home2-slide-pages {
  font-size: 56px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  right: 4%;
  bottom: 15%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home2-slide-pages > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home2-slide-pages .current {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  color: #333;
}

.home2-slide-pages .border {
  display: block;
  width: 80px;
  height: 2px;
  margin: 5px 15px 0;
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  border: none !important;
  background-color: #333;
}

.home2-slide-pages .total {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  color: rgba(126, 126, 126, 0.5);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home2-slide-pages {
    font-size: 44px;
  }
  .home2-slide-pages .border {
    width: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home2-slide-pages {
    display: none;
  }
}

.swiper-slide-active .home2-slide1-image,
.swiper-slide-active .home2-slide2-image,
.swiper-slide-active .home2-slide3-image {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.swiper-slide-active .slide-pointer {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  opacity: 1;
}

.swiper-slide-active .home2-slide-content > * {
  opacity: 1;
}

.swiper-slide-active .home2-slide-content .sub-title {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home2-slide-content .title {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home2-slide-content .link {
  -webkit-transition-delay: 1.6s;
  -o-transition-delay: 1.6s;
  transition-delay: 1.6s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home2-slide-pages > * {
  opacity: 1;
}

.swiper-slide-active .home2-slide-pages .current {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home2-slide-pages .border {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home2-slide-pages .total {
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.home3-slider {
  height: 500px;
}

.home3-slider [class*="swiper-button"] {
  top: auto;
  top: auto;
  bottom: 0;
  left: auto;
  width: 80px;
  height: 80px;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  opacity: 1;
  color: #2c2c2c;
  background-color: #fff;
}

.home3-slider [class*="swiper-button"] i {
  font-size: 18px;
}

.home3-slider [class*="swiper-button"]:hover {
  color: #333;
  background-color: #ffcdb8;
}

.home3-slider .swiper-button-prev {
  right: 80px;
}

.home3-slider .swiper-button-next {
  right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home3-slider {
    height: 450px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home3-slider {
    height: 350px;
  }
  .home3-slider [class*="swiper-button"] {
    width: 60px;
    height: 60px;
  }
  .home3-slider .swiper-button-prev {
    right: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .home3-slider {
    height: 300px;
  }
  .home3-slider [class*="swiper-button"] {
    width: 50px;
    height: 50px;
  }
  .home3-slider [class*="swiper-button"]::after {
    font-size: 14px;
  }
  .home3-slider .swiper-button-prev {
    right: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .home3-slider {
    height: 250px;
  }
  .home3-slider [class*="swiper-button"] {
    width: 40px;
    height: 40px;
  }
  .home3-slider .swiper-button-prev {
    right: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .home3-slider {
    height: 200px;
  }
  .home3-slider [class*="swiper-button"] {
    width: 30px;
    height: 30px;
  }
  .home3-slider .swiper-button-prev {
    right: 30px;
  }
}

.home3-slide-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home3-slide-content {
  display: inline-block;
  text-align: center;
}

.home3-slide-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home3-slide-content > *:last-child {
  margin-bottom: 0;
}

.home3-slide-content .sub-title {
  font-family: "Modesty";
  font-size: 52px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 24px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #ff4c00;
}

.home3-slide-content .title {
  font-size: 52px;
  font-weight: 400;
  line-height: 1.55;
  margin-bottom: 18px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home3-slide-content .link {
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home3-slide-content .sub-title {
    font-size: 44px;
    margin-bottom: 20px;
  }
  .home3-slide-content .title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home3-slide-content .sub-title {
    font-size: 36px;
    margin-bottom: 15px;
  }
  .home3-slide-content .title {
    font-size: 30px;
  }
  .home3-slide-content .link .btn {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .home3-slide-content .sub-title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .home3-slide-content .title {
    font-size: 24px;
  }
  .home3-slide-content .link .btn {
    font-size: 14px;
    padding: 8px 25px;
  }
}

@media only screen and (max-width: 479px) {
  .home3-slide-content .sub-title {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .home3-slide-content .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .home3-slide-content .link .btn {
    font-size: 12px;
    padding: 3px 20px;
  }
}

.swiper-slide-active .home3-slide-content > * {
  opacity: 1;
}

.swiper-slide-active .home3-slide-content .sub-title {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home3-slide-content .title {
  -webkit-transition-delay: 0.95s;
  -o-transition-delay: 0.95s;
  transition-delay: 0.95s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home3-slide-content .link {
  -webkit-transition-delay: 1.15s;
  -o-transition-delay: 1.15s;
  transition-delay: 1.15s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.home4-slider {
  overflow: visible;
}

.home4-slider [class*="swiper-button"] {
  color: #e7e7e7;
}

.home4-slider [class*="swiper-button"] i {
  font-size: 40px;
}

.home4-slider [class*="swiper-button"]:hover {
  color: #333;
}

.home4-slider .swiper-button-prev {
  left: -120px;
}

.home4-slider .swiper-button-next {
  right: -120px;
}

.home4-slider:hover [class*="swiper-button"] {
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home4-slider {
    overflow: hidden;
  }
  .home4-slider [class*="swiper-button"] {
    color: #2c2c2c;
  }
  .home4-slider [class*="swiper-button"] i {
    font-size: 30px;
  }
  .home4-slider .swiper-button-prev {
    left: -40px;
  }
  .home4-slider .swiper-button-next {
    right: -40px;
  }
  .home4-slider:hover .swiper-button-prev {
    left: 0;
  }
  .home4-slider:hover .swiper-button-next {
    right: 0;
  }
  .home4-slider .swiper-pagination {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .home4-slider .swiper-pagination {
    margin-top: 20px;
  }
}

.home4-slide-content {
  position: absolute;
  top: 50%;
  left: 70px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.home4-slide-content .category {
  font-family: "Futura";
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  display: block;
  margin-bottom: 35px;
  letter-spacing: 3px;
}

.home4-slide-content .title {
  font-size: 48px;
  font-weight: 400;
  line-height: 1.35;
  margin-bottom: 36px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home4-slide-content {
    left: 50px;
  }
  .home4-slide-content .category {
    margin-bottom: 25px;
  }
  .home4-slide-content .title {
    font-size: 40px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home4-slide-content {
    left: 40px;
  }
  .home4-slide-content .category {
    font-size: 14px;
    margin-bottom: 20px;
  }
  .home4-slide-content .title {
    font-size: 32px;
    margin-bottom: 25px;
  }
  .home4-slide-content .link .btn {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .home4-slide-content {
    left: 30px;
  }
  .home4-slide-content .category {
    font-size: 12px;
    margin-bottom: 15px;
  }
  .home4-slide-content .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .home4-slide-content .link .btn {
    font-size: 14px;
    padding: 8px 20px;
  }
}

@media only screen and (max-width: 479px) {
  .home4-slide-content {
    left: 20px;
  }
  .home4-slide-content .category {
    font-size: 10px;
    margin-bottom: 10px;
  }
  .home4-slide-content .title {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .home4-slide-content .link .btn {
    font-size: 12px;
    padding: 3px 15px;
  }
}

.home5-slider {
  position: relative;
  height: 720px;
}

.home5-slider .swiper-container {
  height: 100%;
}

.home5-slider .swiper-pagination {
  position: absolute;
  bottom: 40px;
  left: 40px;
}

@media only screen and (max-width: 767px) {
  .home5-slider {
    height: 700px;
  }
  .home5-slider .swiper-pagination {
    bottom: 25px;
    left: 30px;
  }
}

.home5-slide-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 150px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f4f3ec),
    color-stop(60%, #f4f3ec),
    color-stop(60%, #fff),
    to(#fff)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f4f3ec 0,
    #f4f3ec 60%,
    #fff 60%,
    #fff 100%
  );
  background-image: -o-linear-gradient(
    left,
    #f4f3ec 0,
    #f4f3ec 60%,
    #fff 60%,
    #fff 100%
  );
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #f4f3ec),
    color-stop(60%, #f4f3ec),
    color-stop(60%, #fff),
    to(#fff)
  );
  background-image: linear-gradient(
    to right,
    #f4f3ec 0,
    #f4f3ec 60%,
    #fff 60%,
    #fff 100%
  );
}

@media only screen and (min-width: 1200px) and (max-width: 1499px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .home5-slide-item {
    padding: 0 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home5-slide-item {
    padding: 0 50px;
    background-color: #f4f3ec;
    background-image: none;
  }
}

.home5-slide1-image {
  -webkit-animation: homr5image 5s linear infinite alternate both;
  animation: homr5image 5s linear infinite alternate both;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .home5-slide1-image {
    max-width: 300px;
    margin: 0 auto;
  }
}

.home5-slide1-content {
  text-align: center;
}

.home5-slide1-content .sub-title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-bottom: 20px;
  color: #454545;
}

.home5-slide1-content .sub-title::before {
  padding-right: 20px;
  content: url("../images/title/title-decor-left.webp");
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
}

.home5-slide1-content .sub-title::after {
  padding-left: 20px;
  content: url("../images/title/title-decor-right.webp");
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
}

.home5-slide1-content .title {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .home5-slide1-content .sub-title {
    font-size: 50px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .home5-slide1-content .sub-title::before,
  .home5-slide1-content .sub-title::after {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .home5-slide1-content .sub-title {
    font-size: 40px;
  }
}

.home5-slide2-image {
  -webkit-animation: homr5image 5s linear infinite alternate both;
  animation: homr5image 5s linear infinite alternate both;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .home5-slide2-image {
    max-width: 300px;
    margin: 0 auto;
  }
}

.home5-slide2-content {
  text-align: center;
}

.home5-slide2-content .sub-title {
  font-family: "Modesty";
  font-size: 120px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin: 0;
  color: #454545;
}

.home5-slide2-content .sub-title::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
}

.home5-slide2-content .sub-title::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
  -webkit-filter: grayscale(100);
  filter: grayscale(100);
}

.home5-slide2-content .title {
  font-size: 64px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 30px;
}

.home5-slide2-content .btn {
  color: #333;
  background-color: #fff;
}

.home5-slide2-content .btn:hover {
  color: #fff;
  background-color: #333;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .home5-slide2-content .sub-title {
    font-size: 90px;
  }
  .home5-slide2-content .title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home5-slide2-content .sub-title {
    font-size: 50px;
    margin-bottom: 10px;
  }
  .home5-slide2-content .title {
    font-size: 34px;
  }
  .home5-slide2-content .btn {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .home5-slide2-content .sub-title {
    font-size: 80px;
    margin-bottom: 10px;
  }
  .home5-slide2-content .title {
    font-size: 40px;
  }
  .home5-slide2-content .btn {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .home5-slide2-content .sub-title::before,
  .home5-slide2-content .sub-title::after {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .home5-slide2-content .sub-title {
    font-size: 40px;
  }
  .home5-slide2-content .title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .home5-slide2-content .btn {
    font-size: 14px;
    padding: 8px 20px;
  }
}

.home5-slide3-image {
  -webkit-animation: homr5image 5s linear infinite alternate both;
  animation: homr5image 5s linear infinite alternate both;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .home5-slide3-image {
    max-width: 90%;
    margin: 0 auto;
  }
}

.home5-slide3-content {
  text-align: center;
}

.home5-slide3-content .sub-title {
  font-family: "Modesty";
  font-size: 70px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-bottom: 20px;
  color: #454545;
}

.home5-slide3-content .title {
  font-family: "Futura";
  font-size: 60px;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 0;
  letter-spacing: 14px;
  text-transform: uppercase;
  color: #baa491;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home5-slide3-content .title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home5-slide3-content .sub-title {
    font-size: 55px;
  }
  .home5-slide3-content .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .home5-slide3-content .sub-title {
    font-size: 45px;
  }
  .home5-slide3-content .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .home5-slide3-content .sub-title {
    font-size: 40px;
  }
  .home5-slide3-content .title {
    font-size: 24px;
    letter-spacing: 5px;
  }
}

.home5-slide-collection,
.home5-slide-sale {
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 60px;
  width: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  letter-spacing: 5px;
  color: #333;
  -webkit-writing-mode: vertical-lr;
  -ms-writing-mode: tb-lr;
  writing-mode: vertical-lr;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .home5-slide-collection,
  .home5-slide-sale {
    left: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home5-slide-collection,
  .home5-slide-sale {
    font-size: 14px;
    left: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .home5-slide-collection,
  .home5-slide-sale {
    font-size: 12px;
    left: 10px;
  }
}

.home5-slide-sale {
  right: 60px;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .home5-slide-sale {
    right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home5-slide-sale {
    right: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .home5-slide-sale {
    right: 10px;
  }
}

.home5-slide-shop-link {
  position: absolute;
  right: 50px;
  bottom: 40px;
  width: auto;
}

.home5-slide-shop-link::before {
  position: absolute;
  top: 50%;
  right: calc(100% + 15px);
  width: 60px;
  height: 2px;
  content: "";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #333;
}

.home5-slide-shop-link a {
  font-weight: 400;
  color: #333;
}

.home5-slide-shop-link a:hover {
  color: #ff4c00;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .home5-slide-shop-link {
    right: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home5-slide-shop-link {
    right: 30px;
    bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .home5-slide-shop-link {
    right: 20px;
    bottom: 20px;
  }
  .home5-slide-shop-link::before {
    display: none;
  }
  .home5-slide-shop-link a {
    font-size: 14px;
  }
}

@-webkit-keyframes homr5image {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@keyframes homr5image {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.home6-slide-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 90vh;
  padding: 60px 0;
}

.home6-slide1-content {
  text-align: center;
}

.home6-slide1-content .sub-title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 400;
  line-height: 1.375;
  color: #fff;
}

.home6-slide1-content .title {
  font-size: 64px;
  font-weight: 400;
  line-height: 1.25;
  color: #fff;
}

.home6-slide1-content .title:not(:last-child) {
  margin-bottom: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .home6-slide1-content .sub-title {
    font-size: 70px;
  }
  .home6-slide1-content .title {
    font-size: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .home6-slide1-content .sub-title {
    font-size: 50px;
  }
  .home6-slide1-content .title {
    font-size: 40px;
  }
  .home6-slide1-content .title:not(:last-child) {
    margin-bottom: 30px;
  }
  .home6-slide1-content .link .btn {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .home6-slide1-content .sub-title {
    font-size: 40px;
  }
  .home6-slide1-content .title {
    font-size: 36px;
  }
  .home6-slide1-content .title:not(:last-child) {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .home6-slide1-content .sub-title {
    font-size: 30px;
  }
  .home6-slide1-content .title {
    font-size: 30px;
  }
  .home6-slide1-content .title:not(:last-child) {
    margin-bottom: 20px;
  }
  .home6-slide1-content .link .btn {
    font-size: 14px;
    padding: 8px 25px;
  }
}

.home7-slider {
  height: 850px;
}

.home7-slider [class*="swiper-button"]:hover i {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.home7-slider:hover [class*="swiper-button"] {
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home7-slider {
    height: 650px;
  }
}

@media only screen and (max-width: 767px) {
  .home7-slider {
    height: 550px;
  }
}

@media only screen and (max-width: 575px) {
  .home7-slider {
    height: 500px;
  }
}

@media only screen and (max-width: 479px) {
  .home7-slider {
    height: 450px;
  }
}

.home7-slide-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home7-slide1-content {
  z-index: 1;
  overflow: hidden;
  text-align: center;
}

.home7-slide1-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home7-slide1-content .name {
  font-family: "Modesty";
  font-size: 120px;
  line-height: 1.2;
  display: block;
  margin-bottom: -30px;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  color: #454545;
}

.home7-slide1-content .title {
  font-family: "Futura";
  font-size: 55px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 11px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  letter-spacing: 20px;
  text-transform: uppercase;
  color: #baa491;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home7-slide1-content .name {
    font-size: 90px;
  }
  .home7-slide1-content .title {
    font-size: 44px;
    letter-spacing: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .home7-slide1-content .name {
    font-size: 70px;
    margin-bottom: -15px;
  }
  .home7-slide1-content .title {
    font-size: 36px;
    letter-spacing: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .home7-slide1-content .name {
    font-size: 50px;
    margin-bottom: 0;
  }
  .home7-slide1-content .title {
    font-size: 30px;
    letter-spacing: 5px;
  }
}

.home7-slide1-image {
  position: relative;
  display: inline-block;
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  opacity: 0;
}

.home7-slide1-image:not(:first-child) {
  margin-top: 50px;
}

.home7-slide1-image .price {
  font-size: 13px;
  font-weight: 400;
  line-height: 1.35;
  position: absolute;
  top: 0;
  left: 30px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #333;
  border-radius: 50%;
  background-color: #fff;
}

.home7-slide1-image .price .amount {
  font-size: 24px;
  font-weight: 700;
  display: block;
  letter-spacing: 0;
}

.home7-slide1-image img {
  -webkit-animation: hero7SlideUpDown 2s linear infinite alternate both;
  animation: hero7SlideUpDown 2s linear infinite alternate both;
}

@media only screen and (max-width: 767px) {
  .home7-slide1-image .price {
    font-size: 10px;
    left: 20px;
    width: 60px;
    height: 60px;
  }
  .home7-slide1-image .price .amount {
    font-size: 18px;
  }
}

@media only screen and (max-width: 479px) {
  .home7-slide1-image .price {
    top: -10px;
  }
}

@-webkit-keyframes hero7SlideUpDown {
  0% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes hero7SlideUpDown {
  0% {
    -webkit-transform: translateY(5px);
    transform: translateY(5px);
  }
  100% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

.home7-slide2-content {
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  text-align: center;
}

.home7-slide2-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home7-slide2-content .sub-title {
  font-family: "Modesty";
  font-size: 70px;
  line-height: 1;
  display: block;
  margin-bottom: 20px;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  color: #454545;
}

.home7-slide2-content .title {
  font-size: 65px;
  font-weight: 400;
  line-height: 1.25;
  position: relative;
  margin-bottom: 40px;
  padding: 0 60px;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  letter-spacing: 5px;
  text-transform: uppercase;
}

.home7-slide2-content .title::before,
.home7-slide2-content .title::after {
  line-height: 0.9;
  position: absolute;
  top: 0;
}

.home7-slide2-content .title::before {
  left: 0;
  content: url(../images/slider/home7/slide2-title-1.webp);
}

.home7-slide2-content .title::after {
  right: 0;
  content: url(../images/slider/home7/slide2-title-2.webp);
}

.home7-slide2-content .link {
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home7-slide2-content .sub-title {
    font-size: 60px;
  }
  .home7-slide2-content .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .home7-slide2-content .sub-title {
    font-size: 50px;
  }
  .home7-slide2-content .title {
    font-size: 40px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .home7-slide2-content .sub-title {
    font-size: 40px;
  }
  .home7-slide2-content .title {
    font-size: 30px;
    margin-bottom: 30px;
    padding: 0;
  }
  .home7-slide2-content .title::before,
  .home7-slide2-content .title::after {
    display: none;
  }
}

@media only screen and (max-width: 479px) {
  .home7-slide2-content .sub-title {
    font-size: 26px;
  }
  .home7-slide2-content .title {
    font-size: 24px;
  }
}

.swiper-slide-active .home7-slide1-content > * {
  opacity: 1;
}

.swiper-slide-active .home7-slide1-content .name {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-slide-active .home7-slide1-content .title {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home7-slide1-image {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.swiper-slide-active .home7-slide2-content > * {
  opacity: 1;
}

.swiper-slide-active .home7-slide2-content .sub-title {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-slide-active .home7-slide2-content .title {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home7-slide2-content .link {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.home8-slider .swiper-pagination {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: auto;
  height: auto;
  margin: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.home8-slide-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.home8-slide-image {
  z-index: 1;
  overflow: hidden;
  max-width: 64%;
}

.home8-slide-image img {
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home8-slide-image {
    max-width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .home8-slide-image {
    max-width: 100%;
  }
}

.home8-slide-content {
  position: absolute;
  z-index: 1;
  z-index: 8;
  top: 50%;
  left: 64%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
}

.home8-slide-content > * {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
}

.home8-slide-content .sub-title {
  font-family: "Modesty";
  font-size: 75px;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #333;
}

.home8-slide-content .title {
  font-size: 60px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 30px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home8-slide-content .link {
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home8-slide-content .link a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 2px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid #333;
}

.home8-slide-content .link a:hover {
  color: #ff4c00;
  border-color: #ff4c00;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .home8-slide-content .sub-title {
    font-size: 50px;
  }
  .home8-slide-content .title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home8-slide-content .sub-title {
    font-size: 50px;
  }
  .home8-slide-content .title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home8-slide-content {
    left: 70%;
  }
  .home8-slide-content .sub-title {
    font-size: 40px;
  }
  .home8-slide-content .title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .home8-slide-content .link a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .home8-slide-content {
    position: relative;
    right: 0;
    left: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    margin-top: -80px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .home8-slide-content .sub-title {
    font-size: 40px;
  }
  .home8-slide-content .title {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .home8-slide-content .link a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .home8-slide-content {
    margin-top: -40px;
  }
  .home8-slide-content .sub-title {
    font-size: 36px;
  }
  .home8-slide-content .title {
    font-size: 24px;
  }
}

.home8-slide-pages {
  font-size: 56px;
  font-weight: 400;
  line-height: 1;
  position: absolute;
  z-index: 9;
  right: 3%;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.home8-slide-pages > * {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
}

.home8-slide-pages .current {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  color: #333;
}

.home8-slide-pages .border {
  display: block;
  width: 80px;
  height: 2px;
  margin: 5px 15px 0;
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  border: none !important;
  background-color: #333;
}

.home8-slide-pages .total {
  -webkit-transform: translateX(40px);
  -ms-transform: translateX(40px);
  transform: translateX(40px);
  color: rgba(126, 126, 126, 0.5);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home8-slide-pages {
    font-size: 44px;
  }
  .home8-slide-pages .border {
    width: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .home8-slide-pages {
    font-size: 40px;
    right: 0;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .home8-slide-pages .border {
    width: 40px;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .home8-slide-pages {
    font-size: 30px;
    right: 5px;
    bottom: 5px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .home8-slide-pages .border {
    width: 30px;
  }
}

.swiper-slide-active .home8-slide-image img {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home8-slide-content > * {
  opacity: 1;
}

.swiper-slide-active .home8-slide-content .sub-title {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home8-slide-content .title {
  -webkit-transition-delay: 1.6s;
  -o-transition-delay: 1.6s;
  transition-delay: 1.6s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home8-slide-content .link {
  -webkit-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home8-slide-pages > * {
  opacity: 1;
}

.swiper-slide-active .home8-slide-pages .current {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home8-slide-pages .border {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home8-slide-pages .total {
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.home12-slider {
  width: 100%;
}

.home12-slider:hover [class*="swiper-button"] {
  opacity: 1;
}

.home12-slider:hover [class*="swiper-button"]:hover i {
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
}

.home12-slide-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 500px;
  background-size: cover;
  background-attachment: fixed;
}

@media only screen and (max-width: 767px) {
  .home12-slide-item {
    height: 550px;
  }
}

@media only screen and (max-width: 575px) {
  .home12-slide-item {
    height: 500px;
  }
}

@media only screen and (max-width: 479px) {
  .home12-slide-item {
    height: 450px;
  }
}

.home12-slide1-content {
  position: relative;
  z-index: 1;
  align-self: center;
  padding: 5pc 40px;
  text-align: center;
}

.home12-slide1-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home12-slide1-content .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  background-color: #75442adb;
}

.home12-slide1-content .bg::before {
  position: absolute;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  content: "";
  border: 1px dashed #fff;
}

.home12-slide1-content .sub-title {
  font-family: "Modesty";
  font-size: 55px;
  font-weight: 400;
  line-height: 1.25;
  display: block;
  margin-bottom: 28px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #ff4c00;
}

.home12-slide1-content .title {
  font-size: 40px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 25px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  letter-spacing: 3px;
  color: #333;
}

.home12-slide1-content .price {
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

@media only screen and (max-width: 767px) {
  .home12-slide1-content {
    max-width: 450px;
  }
  .home12-slide1-content .sub-title {
    font-size: 50px;
  }
  .home12-slide1-content .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .home12-slide1-content .sub-title {
    font-size: 40px;
  }
  .home12-slide1-content .title {
    font-size: 30px;
  }
}

.home12-slide2-content {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 466px;
  height: 466px;
  padding: 25px;
  border-radius: 50%;
  background-color: transparent;
}

.home12-slide2-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home12-slide2-content .bg {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
  border-radius: 50%;
  background-color: #fff;
}

.home12-slide2-content .icon {
  margin-bottom: 25px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
}

.home12-slide2-content .title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 11px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home12-slide2-content .price {
  margin-bottom: 20px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home12-slide2-content .link {
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
}

.home12-slide2-content .link a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 2px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid #333;
}

.home12-slide2-content .link a:hover {
  color: #ff4c00;
  border-color: #ff4c00;
}

@media only screen and (max-width: 767px) {
  .home12-slide2-content {
    width: 400px;
    height: 400px;
  }
  .home12-slide2-content .title {
    font-size: 60px;
  }
  .home12-slide2-content .link a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .home12-slide2-content {
    width: 350px;
    height: 350px;
  }
  .home12-slide2-content .icon {
    height: 60px;
    margin-bottom: 20px;
  }
  .home12-slide2-content .title {
    font-size: 48px;
  }
  .home12-slide2-content .price {
    height: 60px;
  }
}

@media only screen and (max-width: 479px) {
  .home12-slide2-content {
    width: 300px;
    height: 300px;
  }
  .home12-slide2-content .title {
    font-size: 40px;
  }
  .home12-slide2-content .price {
    height: 50px;
  }
  .home12-slide2-content .link a {
    font-size: 12px;
  }
}

.home12-slide3-content {
  max-width: calc(100% - 20px);
  margin-top: 80px;
  text-align: center;
}

.home12-slide3-content > * {
  -webkit-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  transition: all 1s ease 0s;
  opacity: 0;
}

.home12-slide3-content .title {
  font-family: "Modesty";
  font-size: 76px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 20px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  color: #ff4c00;
}

.home12-slide3-content .sub-title {
  font-size: 56px;
  font-weight: 400;
  line-height: 1.1;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 35px;
  padding: 0 80px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  text-align: center;
  color: #000;
}

.home12-slide3-content .sub-title img {
  position: absolute;
  top: 50%;
  margin-top: -12px;
  opacity: 0;
}

.home12-slide3-content .sub-title img.left-icon {
  left: 0;
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

.home12-slide3-content .sub-title img.right-icon {
  right: 0;
  -webkit-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
}

.home12-slide3-content .price {
  font-family: "Marcellus";
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  z-index: 9;
  display: inline-block;
  padding: 0 45px;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  background-image: url("../images/slider/home1/slide-3-3.webp");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media only screen and (max-width: 767px) {
  .home12-slide3-content {
    margin-top: 60px;
  }
  .home12-slide3-content .title {
    font-size: 70px;
    margin-bottom: 20px;
  }
  .home12-slide3-content .sub-title {
    font-size: 36px;
    margin-bottom: 25px;
  }
  .home12-slide3-content .price {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .home12-slide3-content {
    margin-top: 60px;
  }
  .home12-slide3-content .title {
    font-size: 54px;
  }
  .home12-slide3-content .sub-title {
    font-size: 30px;
    margin-bottom: 20px;
    padding: 0 50px;
  }
  .home12-slide3-content .sub-title img {
    height: 16px;
    margin-top: -8px;
  }
}

@media only screen and (max-width: 479px) {
  .home12-slide3-content .title {
    font-size: 40px;
  }
  .home12-slide3-content .sub-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .home12-slide3-content .price {
    font-size: 12px;
  }
}

.swiper-slide-active .home12-slide1-content > * {
  opacity: 1;
}

.swiper-slide-active .home12-slide1-content .bg {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-slide-active .home12-slide1-content .sub-title {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide1-content .title {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide1-content .price {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide2-content > * {
  opacity: 1;
}

.swiper-slide-active .home12-slide2-content .bg {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.swiper-slide-active .home12-slide2-content .icon {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide2-content .title {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide2-content .price {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide2-content .link {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide3-content > * {
  opacity: 1;
}

.swiper-slide-active .home12-slide3-content .title {
  -webkit-transition-delay: 0.75s;
  -o-transition-delay: 0.75s;
  transition-delay: 0.75s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide3-content .sub-title {
  -webkit-transition-delay: 0.95s;
  -o-transition-delay: 0.95s;
  transition-delay: 0.95s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.swiper-slide-active .home12-slide3-content .sub-title img {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
  opacity: 1;
}

.swiper-slide-active .home12-slide3-content .sub-title img.left-icon {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home12-slide3-content .sub-title img.right-icon {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.swiper-slide-active .home12-slide3-content .price {
  -webkit-transition-delay: 1.15s;
  -o-transition-delay: 1.15s;
  transition-delay: 1.15s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.section-title .title.title-icon-both::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
}

.section-title .title.title-icon-both::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

.section-title .title.title-icon-left::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
}

.section-title .title.title-icon-right::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title .sub-title {
    font-size: 60px;
  }
  .section-title .title {
    font-size: 44px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title {
    margin-bottom: 60px;
  }
  .section-title .sub-title {
    font-size: 50px;
  }
  .section-title .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title {
    margin-bottom: 50px;
  }
  .section-title .sub-title {
    font-size: 40px;
  }
  .section-title .title {
    font-size: 24px;
  }
  .section-title .title::before,
  .section-title .title::after {
    display: none !important;
  }
}

.section-title2 {
  margin-bottom: 40px;
}

.section-title2 .sub-title {
  font-family: "Modesty";
  font-size: 56px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 8px;
  color: #ff4c00;
}

.section-title2 .title {
  font-size: 35px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  color: #333;
}
.revartsectino .section-title2 .title, .sectionteam .section-title2 .title, .feat .section-title2 .title, .slider-testimonial .section-title2 .title {
    position: relative;
    margin-bottom: 25px;
}
.revartsectino .section-title2 .title:before, .sectionteam .section-title2 .title:before, .feat .section-title2 .title:before, .slider-testimonial .section-title2 .title:before {
    content: '';
    width: 60px;
    height: 3px;
    background: #ff8f38;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
}



.feat .section-head h4 {
  position: relative;
  padding:0;
  color:#f91942;
  line-height: 1;
  letter-spacing:0.3px;
  font-size: 34px;
  font-weight: 700;  
  text-align:center;
  text-transform:none;
  margin-bottom:30px;
}
.feat .section-head h4:before {
  content: '';
  width: 60px;
  height: 3px;
  background: #f91942;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right:0;  
  margin:0 auto;
}
.feat .section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color:#2f2f2f
}
.feat p.service_text{
  color:#cccccc !important;
  font-size:16px;
  line-height:28px;
  text-align:center;    
}
.feat .section-head p, p.awesome_line {
  color:#818181;
  font-size:16px;
  line-height:28px;
  text-align:center;  
}

.feat .extra-text {
    font-size:34px;
    font-weight: 700;
    color:#2f2f2f;
    margin-bottom: 25px;
    position:relative;
    text-transform: none;
}
.feat .extra-text::before {
    content: '';
    width: 60px;
    height: 3px;
    background: #f91942;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
}
.feat .extra-text span {
    font-weight: 700;
    color:#f91942;
}
.feat .item {
    background: #fff;
    text-align: center;
    padding: 30px 25px;
    -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    border:5px solid rgba(0, 0, 0, 0.07);
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.feat .item:hover{
    background:#f8796c;
    box-shadow:0 8px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.feat .item:hover .item, .feat .item:hover span.icon{
    background:#fff;
    border-radius:10px;
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.feat .item:hover h6, .feat .item:hover p{
    color:#fff;
    -webkit-transition: all .5s ease 0;
    transition: all .5s ease 0;
    transition: all 0.5s ease 0s;
}
.feat .item .icon {
    font-size: 40px;
    margin-bottom: 25px;
    color: #f91942;
    width: 90px;
    height: 90px;
    line-height: 96px;
    display: block;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
}
.feat .item .feature_box_col_one{
    background:rgba(255, 77, 28, 0.15);
    color:#ff923c
}
.feat .item .feature_box_col_two{
    background:rgba(255, 77, 28, 0.15);
    color:#ff923c
}
.feat .item .feature_box_col_three{
    background:rgba(255, 77, 28, 0.15);
    color:#ff923c
}
.feat .item .feature_box_col_four{
    background:rgba(255, 77, 28, 0.15);
    color:#ff923c
}
.feat .item .feature_box_col_five{
    background:rgba(255, 77, 28, 0.15);
    color:#ff923c
}
.feat .item .feature_box_col_six{
    background:rgba(255, 77, 28, 0.15);
    color:#ff923c
}
.feat .item p{
    font-size:15px;
    line-height:26px;
}
.feat .item h6 {
    margin-bottom:20px;
    color:#2f2f2f;
}
.feat .mission p {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
}
.feat .mission i {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #f91942;
    border-radius: 50%;
    color: #fff;
    font-size: 25px;
}
.feat .mission .small-text {
    margin-left: 10px;
    font-size: 13px;
    color: #666;
}
.feat .skills {
    padding-top:0px;
}
.feat .skills .prog-item {
    margin-bottom: 25px;
}
.feat .skills .prog-item:last-child {
    margin-bottom: 0;
}
.feat .skills .prog-item p {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
}
.feat .skills .prog-item .skills-progress {
    width: 100%;
    height: 10px;
    background: #e0e0e0;
    border-radius:20px;
    position: relative;
}
.feat .skills .prog-item .skills-progress span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #f91942;
    width: 10%;
    border-radius: 10px;
    -webkit-transition: all 1s;
    transition: all 1s;
}
.feat .skills .prog-item .skills-progress span:after {
    content: attr(data-value);
    position: absolute;
    top: -5px;
    right: 0;
    font-size: 10px;
    font-weight:600;    
    color: #fff;
    background:rgba(0, 0, 0, 0.9);
    padding: 3px 7px;
    border-radius: 30px;
}

.calltoaction-section {
    padding-left: 0;
    padding-right: 0;
}




.section-title2 .title-icon-both::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
}

.section-title2 .title-icon-both::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

.section-title2 .title.title-icon-left::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
}

.section-title2 .title.title-icon-right::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

.section-title2 p {
  line-height: 1.75;
  max-width: 570px;
  margin-top: 5px;
}

.section-title2.text-center p {
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 767px) {
  .section-title2 {
    margin-bottom: 60px;
  }
  .section-title2 .sub-title {
    font-size: 50px;
  }
  .section-title2 .title {
    font-size: 32px;
  }
  .section-title2 p {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title2 {
    margin-bottom: 50px;
  }
  .section-title2 .sub-title {
    font-size: 40px;
  }
  .section-title2 .title {
    font-size: 24px;
  }
  .section-title2 .title::before,
  .section-title2 .title::after {
    display: none !important;
  }
  .section-title2 p {
    margin-top: 10px;
  }
}

.section-title3 {
  margin-bottom: 80px;
  padding: 20px 0;
  background-size: auto 100%;
}

.section-title3 .title {
  font-size: 35px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
  color: #333;
}

.section-title3 .desc {
  font-weight: 400;
  line-height: 1.5;
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .section-title3 {
    margin-bottom: 60px;
  }
  .section-title3 .sub-title {
    font-size: 50px;
  }
  .section-title3 .title {
    font-size: 32px;
  }
  .section-title3 p {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title3 {
    margin-bottom: 50px;
  }
  .section-title3 .sub-title {
    font-size: 40px;
  }
  .section-title3 .title {
    font-size: 24px;
  }
  .section-title3 p {
    margin-top: 10px;
  }
}

.section-title4 {
  margin-bottom: 80px;
}

.section-title4 .title {
  font-family: "Modesty";
  font-size: 72px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0;
}

.section-title4 .title.title-icon-both::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
}

.section-title4 .title.title-icon-both::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

.section-title4 .title.title-icon-left::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
}

.section-title4 .title.title-icon-right::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title4 .title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title4 {
    margin-bottom: 60px;
  }
  .section-title4 .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title4 {
    margin-bottom: 50px;
  }
  .section-title4 .title {
    font-size: 40px;
  }
  .section-title4 .title::before,
  .section-title4 .title::after {
    display: none !important;
  }
}

.block-title {
  margin-bottom: 35px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ededed;
}

.block-title .title {
  font-size: 30px;
  font-weight: 400;
  line-height: 1;
  margin: 0;
}

.page-title-section {
  padding: 70px 0 70px 4pc;
  box-shadow: inset 0 0 0 2000px #ffffff1d;
}

.page-title-section2 {
  padding: 1pc 0pc;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .page-title-section {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .page-title-section {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 479px) {
  .page-title-section {
    padding: 50px 0;
  }
}

.page-title .title {
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 30px;
}

.page-title .breadcrumb {
  padding: 0;
  background-color: transparent;
}

.page-title .breadcrumb .breadcrumb-item {
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.page-title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: white;
}

.page-title .breadcrumb .breadcrumb-item a:hover {
  color: white;
}

.page-title .breadcrumb .breadcrumb-item.active {
  color: white;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-title .title {
    font-size: 44px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .page-title .title {
    font-size: 40px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .page-title .title {
    font-size: 30px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .page-title .title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .page-title .title {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.sale-banner1 {
  position: relative;
  width: 100%;
  max-width: 510px;
  margin: auto;
  padding-bottom: 100%;
}

.about-gallery img {
  width: 100%;
  object-fit: cover;
}

.about-gallery .sale-banner5 img,
.about-gallery .sale-banner6 img {
  height: 250px;
}

.sale-banner1 .inner {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sale-banner1 .inner .title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 3px;
  color: #333;
}

.sale-banner1 .inner .sale-percent {
  font-size: 34px;
  font-weight: 300;
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 200px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

.sale-banner1 .inner .sale-percent .number {
  font-size: 100px;
}

.sale-banner1 .inner .link {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  padding: 2px 0;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid transparent;
}

.sale-banner1 .inner .link:hover {
  border-color: rgba(51, 51, 51, 0.2);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sale-banner1 .inner {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 479px) {
  .sale-banner1 .inner {
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }
  .sale-banner1 .inner .link {
    font-size: 20px;
  }
}

.sale-banner2 .inner .image {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 35px;
}

.sale-banner2 .inner .image::before {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  content: "";
  border: 1px solid #fff;
}

.sale-banner2 .inner .image img {
  width: 100%;
}

.sale-banner2 .inner .image:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sale-banner2 .inner .content .sale-percent {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 3px;
}

.sale-banner2 .inner .content .text {
  font-family: "ABeeZee";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #333;
}

.sale-banner2 .inner .content .btn {
  background-color: #f9f0ee;
}

@media only screen and (max-width: 575px) {
  .sale-banner2 .inner .image {
    margin-bottom: 20px;
  }
}

.sale-banner3 {
  position: relative;
  width: 100%;
  padding: 80px 30px;
  text-align: center;
  border: 3px solid rgba(114, 164, 153, 0.4);
}

.sale-banner3 .special-title {
  font-family: "Modesty";
  font-size: 56px;
  line-height: 1.25;
  display: block;
  margin-bottom: 25px;
  color: #ff4c00;
}

.sale-banner3 .title {
  font-size: 40px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  margin-bottom: 48px;
}

.sale-banner3 .title::before {
  font-family: "Futura";
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  width: 100%;
  content: attr(data-text);
  -webkit-transform: translateY(-60%);
  -ms-transform: translateY(-60%);
  transform: translateY(-60%);
  text-align: center;
  color: #f2f2f2;
}

.sale-banner3 .link {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  padding-bottom: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.sale-banner3 .link:hover {
  border-color: #333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sale-banner3 .title {
    font-size: 32px;
  }
  .sale-banner3 .title::before {
    font-size: 80px;
  }
}

.sale-banner3-1 {
  position: relative;
  width: 100%;
  text-align: center;
}

.sale-banner3-1 .image img {
  width: 100%;
}

.sale-banner3-1 .content {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sale-banner3-1 .content .special-title {
  font-family: "Modesty";
  font-size: 56px;
  line-height: 1.25;
  display: block;
  margin-bottom: 25px;
  color: #ff4c00;
}

.sale-banner3-1 .content .title {
  font-size: 34px;
  font-weight: 400;
  position: relative;
  z-index: 1;
  margin-bottom: 48px;
}

.sale-banner3-1 .content .link {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  padding-bottom: 4px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.sale-banner3-1 .content .link:hover {
  border-color: #333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .sale-banner3-1 .content .title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 479px) {
  .sale-banner3-1 .content .special-title {
    font-size: 44px;
  }
  .sale-banner3-1 .content .title {
    font-size: 28px;
  }
}

.sale-banner4 {
  height: 100%;
}

.sale-banner4 .inner {
  position: relative;
  height: 100%;
}

.sale-banner4 .inner > img {
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.sale-banner4 .inner .content {
  position: absolute;
  z-index: 9;
  top: 15%;
  left: 0;
  width: 100%;
  text-align: center;
}

.sale-banner4 .inner .content .sub-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 25px;
}

.sale-banner4 .inner .content .title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 400;
  line-height: 0.9;
  margin-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (max-width: 575px) {
  .sale-banner4 .inner .content .sub-title {
    font-size: 20px;
    margin-bottom: 20px;
  }
  .sale-banner4 .inner .content .title {
    font-size: 60px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .sale-banner4 .inner .content {
    top: 12%;
  }
  .sale-banner4 .inner .content .sub-title {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .sale-banner4 .inner .content .title {
    font-size: 40px;
    margin-bottom: 20px;
  }
  .sale-banner4 .inner .content .button-banner img {
    height: 16px;
  }
}

.sale-banner5 .inner {
  position: relative;
  display: block;
}

.sale-banner5 .inner > img {
  z-index: 1;
  display: block;
  width: 100%;
}

.sale-banner5 .inner .content {
  position: absolute;
  z-index: 9;
  bottom: 50px;
  left: 50px;
}

.sale-banner5 .inner .content .title {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 25px;
}

.sale-banner5 .inner .content .price {
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  color: #333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (max-width: 575px) {
  .sale-banner5 .inner .content {
    bottom: 40px;
    left: 40px;
  }
  .sale-banner5 .inner .content .title {
    font-size: 32px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .sale-banner5 .inner .content {
    left: 20px;
  }
  .sale-banner5 .inner .content .title {
    font-size: 22px;
    margin-bottom: 5px;
  }
  .sale-banner5 .inner .content .price {
    font-size: 14px;
  }
}

.sale-banner6 .inner {
  position: relative;
  display: block;
}

.sale-banner6 .inner > img {
  z-index: 1;
  display: block;
  width: 100%;
}

.sale-banner6 .inner .content {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.sale-banner6 .inner .content img {
  display: block;
  margin: auto;
}

.sale-banner6 .inner .content img.price {
  margin-bottom: 25px;
}

.sale-banner6 .inner .content .title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 400;
  line-height: 0.9;
  margin-bottom: 20px;
}

.sale-banner6 .inner .content .link {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  display: inline-block;
  padding-bottom: 5px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.sale-banner6 .inner .content .link:hover {
  border-color: #333;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .sale-banner6 .inner .content .title {
    font-size: 60px;
  }
  .sale-banner6 .inner .content img.icon {
    height: 50px;
  }
  .sale-banner6 .inner .content img.price {
    height: 50px;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (max-width: 767px) {
  .sale-banner6 .inner .content .title {
    font-size: 60px;
  }
  .sale-banner6 .inner .content img.icon {
    height: 40px;
  }
  .sale-banner6 .inner .content img.price {
    height: 30px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .sale-banner6 .inner .content .title {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .sale-banner6 .inner .content img.icon {
    height: 30px;
  }
  .sale-banner6 .inner .content img.price {
    height: 20px;
    margin-bottom: 10px;
  }
}

.sale-banner7 {
  height: 100%;
}

.sale-banner7 .inner {
  position: relative;
  height: 100%;
}

.sale-banner7 .inner .image {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.sale-banner7 .inner .image img {
  z-index: 1;
  display: block;
  width: 100%;
  height: 488px !important;
  -o-object-fit: cover;
  object-fit: cover;
}

.sale-banner7 .inner .image::before {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  border: 1px solid #fff;
  background-color: rgb(0 0 0 / 25%);
}

.sale-banner7 .inner .content {
  position: absolute;
  z-index: 9;
  bottom: 50%;
  left: 0;
  width: 100%;
  text-align: center;
}

.sale-banner7 .inner .content .title {
  /* font-family: "Modesty"; */
  /* font-size: 80px; */
  font-weight: 400;
  line-height: 1;
  margin-bottom: 10px;
  background-color: #00000099;
  padding: 18px 0pc;
  color: #fff;
}

.sale-banner7 .inner .content .sub-title {
  font-family: "Futura";
  font-size: 34px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
  letter-spacing: 10px;
  text-transform: uppercase;
  color: #baa491;
}

.sale-banner7:hover .inner .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sale-banner7:hover .inner .image::before {
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (max-width: 575px) {
  .sale-banner7 .inner .content .title {
    font-size: 16px;
  }
  .sale-banner7 .inner .content .sub-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .sale-banner7 .inner .content .title {
    font-size: 16px;
  }
  .sale-banner7 .inner .content .sub-title {
    font-size: 20px;
  }
}

.sale-banner8 {
  position: relative;
  display: block;
  overflow: hidden;
}

.sale-banner8 img {
  display: block;
  width: 100%;
}

.sale-banner8 .content {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 40px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sale-banner8 .content .title {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.35;
  margin-bottom: 10px;
}

.sale-banner8 .content .link {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  display: inline-block;
  padding-bottom: 5px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.sale-banner8 .content .link:hover {
  border-color: #333;
}

.sale-banner8:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sale-banner8 .content .title {
    font-size: 30px;
  }
  .sale-banner8 .content .link {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .sale-banner8 .content .title {
    font-size: 24px;
  }
  .sale-banner8 .content .link {
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .sale-banner8 .content {
    left: 20px;
  }
  .sale-banner8 .content .title {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .sale-banner8 .content .link {
    font-size: 12px;
  }
}

.sale-banner9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  padding: 60px 30px;
  background-image: url("../images/banner/sale/sale-banner6-1.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

.sale-banner9 .inner .content {
  text-align: center;
}

.sale-banner9 .inner .content .title {
  /* font-family: "Modesty"; */
  font-size: 70px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 7px;
  color: #75442a;
}

.sale-banner9 .inner .content .cuppon {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  display: block;
  margin-bottom: 34px;
  letter-spacing: 2px;
  color: #333;
}

.sale-banner9 .inner .content .cuppon .code {
  font-weight: 600;
  padding: 1px 16px;
  color: #fff;
  background-image: url(../images/banner/sale/sale-banner9-1.1.webp);
  background-size: cover;
}

.sale-banner9 .inner .content .offer {
  font-size: 90px;
  font-weight: 600;
  line-height: 1;
  display: block;
  margin-bottom: 35px;
  color: #333;
}

@media only screen and (max-width: 767px) {
  .sale-banner9 .inner .content .title {
    font-size: 80px;
  }
  .sale-banner9 .inner .content .cuppon {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .sale-banner9 .inner .content .offer {
    font-size: 80px;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 479px) {
  .sale-banner9 .inner .content .title {
    font-size: 40px;
  }
  .sale-banner9 .inner .content .cuppon {
    font-size: 12px;
  }
  .sale-banner9 .inner .content .offer {
    font-size: 60px;
  }
}

.sale-banner9-image {
  height: 100%;
}

.sale-banner9-image img {
  width: 100%;
  height: 366px;
  -o-object-fit: cover;
  object-fit: cover;
}

.sale-banner10 .inner {
  position: relative;
  overflow: hidden;
}

.sale-banner10 .inner > img {
  width: 100%;
}

.sale-banner10 .inner .content {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 0 20px;
  text-align: center;
}

.sale-banner10 .inner .content .sub-title {
  font-family: "Modesty";
  font-size: 56px;
  line-height: 1;
  display: block;
  margin-bottom: 20px;
  color: #ff4c00;
}

.sale-banner10 .inner .content .title {
  font-size: 34px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 4px;
}

.sale-banner10:hover .inner > img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.sale-banner11 .inner {
  position: relative;
  overflow: hidden;
}

.sale-banner11 .inner::before {
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  content: "";
  border: 1px solid #fff;
}

.sale-banner11 .inner > img {
  width: 100%;
}

.sale-banner11 .inner .content {
  position: absolute;
  z-index: 9;
  bottom: 75px;
  left: 0;
  width: 100%;
  padding: 0 30px;
  text-align: center;
}

.sale-banner11 .inner .content .title {
  font-size: 34px;
  font-weight: 400;
  line-height: 1.1;
  margin-bottom: 20px;
}

.sale-banner11:hover .inner > img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.category-banner1 {
  position: relative;
  display: block;
  float: left;
  width: 100%;
}

.category-banner1 .inner .image {
  position: relative;
  display: block;
  overflow: hidden;
}

.category-banner1 .inner .image:not(:last-child) {
  margin-bottom: 20px;
}

.category-banner1 .inner .image::before {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  content: "";
  border: 1px solid #fff;
}

.category-banner1 .inner .image img {
  width: 100%;
}

.category-banner1 .inner .content .title {
  font-size: 24px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
}

.category-banner1 .inner .content .title a:hover {
  color: #333;
}

.category-banner1 .inner .content .title .number {
  font-family: "Futura";
  font-size: 16px;
  line-height: 1;
  position: relative;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #333;
}

.category-banner1 .inner .content .title .number::before {
  position: absolute;
  top: calc(50% + 2px);
  right: calc(100% + 10px);
  width: 50px;
  height: 1px;
  content: "";
  background-color: #2c2c2c;
}

.category-banner1:hover .inner .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.category-banner2 {
  position: relative;
  display: block;
  float: left;
  width: 100%;
}

.category-banner2 .inner {
  display: block;
}

.category-banner2 .inner .image {
  position: relative;
  display: block;
  overflow: hidden;
}

.category-banner2 .inner .image:not(:last-child) {
  margin-bottom: 30px;
}

.category-banner2 .inner .image::before {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  border: 1px solid #fff;
}

.category-banner2 .inner .image img {
  width: 100%;
  height: 660px;
  object-fit: cover;
}

.category-banner2.d-flex .inner .image img {
  height: 400px;
}

.category-banner2 .inner .content .title {
  font-size: 34px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
}

.category-banner2 .inner .content .title .number {
  font-family: "Futura";
  font-size: 16px;
  line-height: 1;
  position: relative;
  padding-left: 5px;
  color: #333;
}

.category-banner2 .banner-desc {
  font-family: "Futura";
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  white-space: nowrap;
  letter-spacing: 3px;
  color: #2c2c2c;
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
}

.category-banner2 .banner-desc.right {
  right: -40px;
}

.category-banner2 .banner-desc.left {
  left: -40px;
}

.category-banner2:hover .inner .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.category-banner2:hover .inner .image::before {
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .category-banner2 .inner .content .title {
    font-size: 24px;
  }
  .category-banner2 .banner-desc {
    display: none;
  }
}

.category-banner3 {
  position: relative;
  display: block;
  float: left;
  width: 100%;
}

.category-banner3 .inner {
  position: relative;
  display: block;
}

.category-banner3 .inner .image {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 0;
}

.category-banner3 .inner .image::before {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  border: 1px solid #fff;
}

.category-banner3 .inner .image img {
  width: 100%;
  height: 314px;
  object-fit: cover;
}

.category-banner3 .inner .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 40px 50px;
}

.category-banner3 .inner .content .title {
  /* font-size: 34px; */
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  color: #fff;
  background-color: #0000009c;
  padding: 10px 2pc;
  width: max-content;
}

.category-banner3 .inner .content .title .number {
  font-family: "Futura";
  font-size: 16px;
  line-height: 1;
  position: relative;
  padding-left: 5px;
  /* color: #333 */
}

.category-banner3:hover .inner .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.category-banner3:hover .inner .image::before {
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .category-banner3 .inner .content .title {
    font-size: 24px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 575px) {
  .category-banner3 .inner .content {
    padding-bottom: 35px;
  }
}

@media only screen and (max-width: 479px) {
  .category-banner3 .inner .content {
    padding: 0 25px 20px;
  }
}

.category-banner4 {
  position: relative;
  display: block;
  float: left;
  width: 100%;
}

.category-banner4 .inner {
  position: relative;
  display: block;
}

.category-banner4 .inner .image {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 0;
}

.category-banner4 .inner .image::before {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  border: 1px solid #fff;
}

.category-banner4 .inner .image img {
  width: 100%;
}

.category-banner4 .inner .content {
  position: relative;
  z-index: 9;
  max-width: 80%;
  margin: -25px auto 0;
  padding: 15px 10px;
}

.category-banner4 .inner .content .title {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.125;
  margin: 0;
  text-align: center;
}

.category-banner4:hover .inner .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.category-banner4:hover .inner .image::before {
  opacity: 1;
}

.category-banner5 {
  position: relative;
  display: block;
  float: left;
  width: 100%;
}

.category-banner5 .inner {
  position: relative;
  display: block;
}

.category-banner5 .inner .image {
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.category-banner5 .inner .image img {
  display: block;
  width: 100%;
  max-width: 264px;
  margin: auto;
  height: 245px;
  object-fit: cover;
  /* border-radius: 50% */
}

.category-banner5 .inner .content {
  margin: 0;
  padding: 0;
  text-align: center;
}

.category-banner5 .inner .content .title {
  font-size: 24px;
  font-weight: 400;
  line-height: 1.125;
  margin: 0 0 15px;
}

.category-banner5 .inner .content .number {
  font-size: 16px;
  font-weight: 400;
  display: block;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  color: #2c2c2c;
}

.category-banner5:hover .inner .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.category-banner5:hover .inner .content .number {
  color: #333;
}

.category-banner6 {
  position: relative;
  display: block;
  float: left;
  width: 100%;
}

.category-banner6 .inner {
  position: relative;
}

.category-banner6 .inner .image {
  position: relative;
  z-index: 1;
  display: block;
  overflow: hidden;
  margin-bottom: 0;
}

.category-banner6 .inner .image::before {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.category-banner6 .inner .image img {
  width: 100%;
}

.category-banner6 .inner .content {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
}

.category-banner6 .inner .content .title {
  font-size: 20px;
  font-weight: 400;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 5px;
}

.category-banner6 .inner .content .title::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #fff;
}

.category-banner6 .inner .content .title a {
  color: #fff;
}

.category-banner6 .inner .content .title a:hover {
  color: #fff;
}

.category-banner6 .inner .content .title .number {
  font-family: "Futura";
  font-size: 16px;
  line-height: 1;
  position: relative;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  margin-left: 50px;
  color: #fff;
}

.category-banner6 .inner .content .title .number::before {
  position: absolute;
  top: calc(50% + 2px);
  right: calc(100% + 10px);
  width: 30px;
  height: 1px;
  content: "";
  background-color: #fff;
}

.category-banner6:hover .inner .image::before {
  opacity: 1;
}

.category-banner6:hover .inner .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.category-banner6:hover .inner .content {
  opacity: 1;
}

.category-banner6:hover .inner .content .title::before {
  width: 100%;
}

.parallax-banner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
}

.parallax-banner .parallax-image {
  position: absolute;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
}

.parallax-banner .parallax-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -o-object-fit: cover;
  object-fit: cover;
}

.parallax-banner .content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 50%;
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
}

.parallax-banner-1 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.parallax-banner-1 .content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 100%;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  max-width: 100%;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
}

.parallax-banner-1 .content .sub-title {
  font-family: "Modesty";
  font-size: 120px;
  line-height: 0.75;
  display: block;
  color: #333;
}

.parallax-banner-1 .content .title {
  font-family: "Futura";
  font-size: 56px;
  font-weight: 600;
  line-height: 1.25;
  margin: 0;
  letter-spacing: 20px;
  color: #baa491;
}

.parallax-banner-2 {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.parallax-banner-2 .content .price {
  margin-bottom: 35px;
}

.parallax-banner-2 .content .title {
  font-size: 64px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 20px;
}

.parallax-banner-2 .content .sub-title {
  font-family: "Modesty";
  font-size: 72px;
  line-height: 1;
  display: block;
  color: #ff4c00;
}

.parallax-banner-3 .content .title {
  font-size: 64px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 30px;
}

.parallax-banner-4 .content {
  max-width: 500px;
  margin-left: 15%;
}

.parallax-banner-4 .content .sub-title {
  font-family: "Modesty";
  font-size: 80px;
  line-height: 1;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.parallax-banner-4 .content .title {
  font-size: 48px;
  font-weight: 400;
  line-height: 1.35;
  margin-bottom: 30px;
}

.parallax-banner-4 .content a {
  font-family: "Futura";
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .parallax-banner-4 .content {
    margin-left: 5%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .parallax-banner-1 .content .sub-title {
    font-size: 100px;
  }
  .parallax-banner-1 .content .title {
    font-size: 44px;
  }
  .parallax-banner-2 .content .title {
    font-size: 44px;
  }
  .parallax-banner-2 .content .sub-title {
    font-size: 56px;
  }
  .parallax-banner-3 .content .title {
    font-size: 44px;
  }
  .parallax-banner-4 .content {
    max-width: 420px;
    margin-left: 5%;
  }
  .parallax-banner-4 .content .sub-title {
    font-size: 60px;
  }
  .parallax-banner-4 .content .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .parallax-banner .content {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    max-width: 100%;
  }
  .parallax-banner-1 .content .sub-title {
    font-size: 80px;
  }
  .parallax-banner-1 .content .title {
    font-size: 40px;
    letter-spacing: 10px;
  }
  .parallax-banner-4 .content {
    max-width: 350px;
  }
}

@media only screen and (max-width: 479px) {
  .parallax-banner-2 .content .title {
    font-size: 36px;
  }
  .parallax-banner-2 .content .sub-title {
    font-size: 44px;
  }
  .parallax-banner-3 .content .title {
    font-size: 36px;
  }
  .parallax-banner-4 .content .sub-title {
    font-size: 50px;
  }
  .parallax-banner-4 .content .title {
    font-size: 36px;
  }
}

.instagram-banner1 .image img {
  width: 100%;
}

.instagram-banner1 .content {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.instagram-banner1 .content .icon {
  margin-bottom: 20px;
  color: #e2efec;
}

.instagram-banner1 .content .icon i {
  font-size: 40px;
  line-height: 1;
  display: block;
}

.instagram-banner1 .content .sub-title {
  font-size: 18px;
  font-weight: 400;
  display: block;
  margin-bottom: 8px;
  color: #333;
}

.instagram-banner1 .content .title {
  font-size: 34px;
  font-weight: 400;
  margin-bottom: 0;
}

.instagram-banner1 .content .title a:hover {
  color: #ff4c00;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 479px) {
  .instagram-banner1 .content .sub-title {
    font-size: 14px;
  }
  .instagram-banner1 .content .title {
    font-size: 24px;
  }
}

.video-banner {
  display: flex;
  height: 500px;
  background-size: cover;
  background-position: right center;
  align-items: center;
  position: relative;
}

.video-banner.home-banner {
    background-size: cover;
    background-position: center;
    height: 406px;
}


.slider-testimonial {
    padding-bottom: 60px;
}
.slider-testimonial .buttons {
  z-index: 1;
  right: 50%;
  top: 261px;
  width: 80px;
  height: 40px;
  position: absolute;
  border-radius: 50px;
  transform: translateX(50%);
  background-color: #FFFFFF;
}
.slider-testimonial .buttons .previous,
html body main .slider .buttons .next {
  width: 50%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.slider-testimonial .buttons .previous {
  left: 0;
  background-image: url("https://alcs-slider.netlify.app/images/icon-prev.svg");
}
.slider-testimonial .buttons .previous:hover {
  transform: scale(1.25);
}
.slider-testimonial .buttons .next {
  right: 0;
  background-image: url("https://alcs-slider.netlify.app/images/icon-next.svg");
}
.slider-testimonial .buttons .next:hover {
  transform: scale(1.25);
}
.slider-testimonial .slide {
  display: flex;
  margin: 0 auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
}
.slider-testimonial .slide .testimonial {
  padding: 32px 51px;
  background-size: 60px;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url(https://alcs-slider.netlify.app/images/pattern-quotes.svg);
}
.slider-testimonial .slide .testimonial blockquote {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: -1px;
}
.testi-item i {
    color: orange;
    font-size: 14px;
    margin-top: 13px;
}
.slider-testimonial .slide .testimonial .author {
    font-size: 15px;
    font-weight: 700;
    margin-left: 60px;
}
.slider-testimonial .slide .testimonial .author span {
  display: block;
  color: #babacf;
  font-weight: 500;
}
.slider-testimonial .slide .slider-img {
  width: 100%;
  padding: 30px;
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(https://alcs-slider.netlify.app/images/pattern-bg.svg);
}
.slider-testimonial .slide .slider-img img {
  width: 240px;
  display: block;
  border-radius: 10px;
  box-shadow: 0px 16px 40px 0px rgba(135, 105, 210, 0.4);
}
.slider-testimonial .slide .active {
  opacity: 1;
  transform: translateX(0) scale(1);
  transition-delay: 0.4s;
}


@media screen and (min-width: 600px) {
  .slider-testimonial .buttons {
    top: 280px;
  }
}
@media screen and (min-width: 900px) {

  .slider-testimonial .buttons {
    right: 43%;
    top: 500px;
  }
  .slider-testimonial .slide {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: row;
  }
  .slider-testimonial .slide .testimonial {
    padding: 32px 0 0 0;
    background-size: 80px;
    background-position: 19% -7%;
    transform: translateX(100px);
  }
 .slider-testimonial .slide .testimonial blockquote {
    font-size: 20px;
    line-height: 26px;
}
  .slider-testimonial .slide .testimonial .author span {
    display: contents;
  }
  .slider-testimonial .slide .slider-img {
    padding: 55px;
  }
  .slider-testimonial .slide .slider-img img {
    width: 400px;
  }
}



.video-banner .content {
  padding: 0 0 0 4pc;
}

.video-banner .content span {
  font-weight: 500;
}

.video-banner .content .video-popup img {
  height: auto;
  width: 70px;
}

.video-banner .content .title {
  margin: 1pc 0;
}

.video-banner .content .title:last-child {
  margin-bottom: 0;
}

.video-banner .content .title span {
  font-weight: 600;
}

.video-banner .content .video-popup {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 50%;
}

.video-banner .content .video-popup:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .video-banner {
    height: 600px;
  }
  .video-banner .content {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner {
    height: 500px;
  }
  .video-banner .content {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner .content .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .video-banner {
    height: 400px;
  }
  .video-banner .content {
    margin-top: 50px;
  }
  .video-banner .content .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .video-banner .content {
    margin-top: 40px;
  }
  .video-banner .content .title {
    font-size: 24px;
  }
}

.video-banner2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 560px;
}

.video-banner2 .content {
  text-align: center;
}

.video-banner2 .content .video-popup {
  display: block;
  border-radius: 50%;
}

.video-banner2 .content .video-popup:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .video-banner2 {
    height: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .video-banner2 {
    height: 400px;
  }
}

.widget-banner {
  position: relative;
  display: block;
  overflow: hidden;
}

.widget-banner::before {
  position: absolute;
  z-index: 2;
  top: 0;
  left: -100%;
  display: block;
  width: 50%;
  height: 100%;
  content: "";
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.3))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.3))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.3) 100%
  );
}

.widget-banner img {
  width: 100%;
}

.widget-banner:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 125%;
  }
}

@keyframes shine {
  0% {
    left: -100%;
  }
  100% {
    left: 125%;
  }
}

.products {
  margin-bottom: -50px;
}

.products .product {
  margin-bottom: 50px;
}

.products-no-space {
  margin-bottom: 0;
}

.products-no-space .product {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .products {
    margin-bottom: -50px;
  }
  .products .product {
    margin-bottom: 50px;
  }
}

.product-tab-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 66px;
}

.product-tab-list li {
  line-height: 1;
  margin: 0 20px;
}

.product-tab-list li a {
  font-family: "Marcellus";
  font-size: 34px;
  font-weight: 400;
  line-height: 1;
  position: relative;
  display: block;
  color: #ababab;
}

.product-tab-list li a:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: url("../images/others/tab-hover.webp");
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  text-align: center;
  opacity: 0;
}

.product-tab-list li a.active,
.product-tab-list li a:hover {
  color: #333;
}

.product-tab-list li a.active:after,
.product-tab-list li a:hover:after {
  top: 100%;
  opacity: 1;
}

.product-tab-list.tab-hover2 li a:after {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 10px;
  content: url("../images/others/tab-hover-2.webp");
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list li a {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .product-tab-list {
    margin-bottom: 20px;
  }
  .product-tab-list li {
    margin: 0 10px 10px;
  }
  .product-tab-list li a {
    font-size: 20px;
  }
  .product-tab-list li a::after {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .product-tab-list li a {
    font-size: 18px;
  }
}

.product-list-slider {
  position: relative;
  overflow: visible;
  margin: 0;
  padding: 0;
}

.product-list-slider .slick-arrow {
  line-height: 1;
  position: absolute;
  top: -60px;
  visibility: visible;
  width: auto;
  height: 30px;
  padding: 0;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 1;
  color: #2c2c2c;
  border: 0;
  background-color: transparent;
}

.product-list-slider .slick-arrow i {
  font-size: 16px;
  line-height: 1;
}

.product-list-slider .slick-arrow:hover {
  color: #333;
}

.product-list-slider .slick-arrow.slick-prev {
  right: 30px !important;
  left: auto !important;
}

.product-list-slider .slick-arrow.slick-next {
  right: 0 !important;
}

.product-list-slider .slick-list {
  margin-right: 0;
  margin-left: 0;
}

.product-list-slider .slick-list .slick-slide > div {
  padding-right: 0;
  padding-left: 0;
}

.product-list-slider .slick-slide > div {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.product-list-slider .slick-slide > div:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.product {
  position: relative;
}

.product:hover .product-thumb .image img.image-hover {
  z-index: 1;
  visibility: visible;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.product:hover .product-thumb .add-to-wishlist {
  z-index: 2;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.product:hover .product-thumb .product-options {
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* .product:hover .product-info .title,
.product:hover .product-info .price {
    visibility: hidden;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0
} */

.product:hover .product-info .product-buttons {
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.product:hover .product2-info .title,
.product:hover .product2-info .price {
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  opacity: 0;
}

.product:hover .product2-buttons {
  visibility: visible;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}

.product-thumb {
  position: relative;
  z-index: 2;
  margin: 0;
}

.product-thumb .image {
  position: relative;
  display: block;
  overflow: hidden;
}

.product-thumb .image img {
  z-index: 3;
  width: 100%;
  height: 333px;
  object-fit: cover;
}

.product-sec .section-title2 .title {
  position: relative;
  margin-bottom: 3pc;
}

/* .product-sec .section-title2 .title:after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    content: url('../images/others/tab-hover.webp');
    transition: all .5s ease 0s;
} */

.product-thumb .image img.image-hover {
  position: absolute;
  z-index: 4;
  top: 0;
  left: 0;
  visibility: hidden;
  -webkit-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  transition-duration: 0.7s;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 0;
}

.product-thumb .add-to-wishlist {
  position: absolute;
  z-index: 7;
  top: 15px;
  right: 15px;
  visibility: hidden;
  width: 48px;
  height: 48px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  text-align: center;
  opacity: 0;
  color: #f8796c;
}

.product-thumb .add-to-wishlist i {
  font-size: 24px;
  line-height: 48px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .product-thumb .add-to-wishlist {
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.product-badges {
  position: absolute;
  top: 20px;
  left: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: -12px;
}

.product-badges span {
  font-weight: 400;
  line-height: 48px;
  z-index: 5;
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
  text-align: center;
  text-transform: lowercase;
  color: #fff;
  border-radius: 50%;
}

.product-badges span.onsale {
  background-color: #98d8ca;
}

.product-badges span.hot {
  background-color: #c61932;
}

.product-badges span.outofstock {
  background-color: #333;
}

.product-options {
  position: absolute;
  z-index: 7;
  bottom: 20px;
  left: 20px;
  visibility: hidden;
  width: calc(100% - 40px);
  padding: 15px 10px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
  background-color: #fff;
}

.product-options ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  color: #333;
}

.product-options ul:not(:last-child) {
  margin-bottom: 10px;
}

.product-options ul li {
  font-weight: 400;
  line-height: 1;
  margin: 0 4px;
  padding: 0;
  cursor: pointer;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.product-options ul.colors li {
  width: 18px;
  height: 18px;
  text-indent: -9999px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.product-options ul.colors li img {
  display: block;
  width: 100%;
  border-radius: 50%;
}

.product-options ul.colors li.active {
  border: 2px solid #f8796c;
}

.product-options ul.sizes li {
  padding-bottom: 3px;
  opacity: 0.5;
}

.product-options ul.sizes li:hover,
.product-options ul.sizes li.active {
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .product-options {
    display: none;
  }
}

.product-info {
  position: relative;
  z-index: 3;
  text-align: center;
}

.product-info:not(:first-child) {
  margin-top: 28px;
}

.product-info .title {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.product-info .title a {
  color: #2c2c2c;
}

.product-info .title a:hover {
  color: #333;
}

.product-info .price {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  color: #333;
}

.product-info .price span.old {
  text-decoration: line-through;
  color: #aaa;
}

.product-info .price span.new {
  margin-left: 10px;
  color: #f8796c;
}

.product-info .product-buttons {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  opacity: 0;
}

.product-info .product-buttons .product-button {
  width: 48px;
  height: 48px;
  margin: 0 5px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background-color: #333;
}

.product-info .product-buttons .product-button i {
  font-size: 16px;
  line-height: 48px;
}

.product-info .product-buttons .product-button:hover {
  background-color: #f8796c;
}

.product-info .product-rating {
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}

.product-info .product-rating .rating {
  font-size: 12px;
  position: relative;
  display: block;
}

.product-info .product-rating .rating::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  content: "    ";
  color: #2c2c2c;
}

.product-info .product-rating .rating .rating-active {
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  height: 100%;
}

.product-info .product-rating .rating .rating-active::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  content: "    ";
  white-space: nowrap;
  color: #f5cc26;
}

.product-info .product-stock-status {
  margin-top: 15px;
}

.product-info .product-stock-status .bar {
  display: block;
  float: left;
  width: 100%;
  height: 7px;
  border-radius: 50px;
  background-color: #ededed;
}

.product-info .product-stock-status .bar .progress {
  display: block;
  height: 100%;
  border-radius: 50px;
  background-color: #f8796c;
}

.product-info .product-stock-status > span {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-top: 12px;
  color: #2c2c2c;
}

.product-info .product-stock-status > span span {
  font-weight: 600;
  color: #333;
}

.product-info .product-stock-status > span.sold {
  float: left;
}

.product-info .product-stock-status > span.available {
  float: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .product-info .title,
  .product-info .price,
  .product-info .product-buttons {
    visibility: visible !important;
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .product-info .product-buttons {
    position: static;
    margin-top: 15px;
  }
}

.product2-info {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  padding: 0 25px 25px;
  text-align: left;
}

.product2-info .title {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.product2-info .title a {
  color: #2c2c2c;
}

.product2-info .title a:hover {
  color: #333;
}

.product2-info .price {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  color: #333;
}

.product2-info .price span.old {
  text-decoration: line-through;
  color: #aaa;
}

.product2-info .price span.new {
  margin-left: 10px;
  color: #f8796c;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .product2-info {
    position: static;
    margin-top: 28px;
    padding: 0;
    text-align: center;
  }
  .product2-info .title,
  .product2-info .price {
    visibility: visible !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
}

.product2-buttons {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
}

.product2-buttons .product-button {
  width: 48px;
  height: 48px;
  margin: 0 5px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  background-color: #333;
}

.product2-buttons .product-button i {
  font-size: 16px;
  line-height: 48px;
}

.product2-buttons .product-button:hover {
  background-color: #f8796c;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .product2-buttons {
    position: static;
    visibility: visible !important;
    margin-top: 15px;
    -webkit-transform: translateY(0) !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;
    opacity: 1 !important;
  }
}

.list-product {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.list-product .thumbnail {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 140px;
  -ms-flex: 0 0 140px;
  flex: 0 0 140px;
  padding-right: 20px;
}

.list-product .thumbnail a {
  display: block;
}

.list-product .thumbnail a img {
  width: 100%;
}

.list-product .content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 calc(100% - 140px);
  -ms-flex: 1 0 calc(100% - 140px);
  flex: 1 0 calc(100% - 140px);
}

.list-product .content .title {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.list-product .content .title a {
  color: #2c2c2c;
}

.list-product .content .title a:hover {
  color: #333;
}

.list-product .content .price {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  color: #333;
}

.list-product .content .price:last-child {
  margin-bottom: 0;
}

.list-product .content .price span.old {
  text-decoration: line-through;
  color: #aaa;
}

.list-product .content .price span.new {
  margin-left: 10px;
  color: #f8796c;
}

.list-product .content .ratting {
  line-height: 1;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.list-product .content .ratting::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  font-weight: 300;
  line-height: 1;
  content: "    ";
  color: #aaa;
}

.list-product .content .ratting .rate {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
  color: #f5cc26;
}

.list-product .content .ratting .rate::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  font-weight: 700;
  line-height: 1;
  content: "    ";
}

[data-name="mojs-shape"] {
  z-index: 99;
}

.product-deal-image img {
  -webkit-animation: dealImage 2s linear infinite alternate both;
  animation: dealImage 2s linear infinite alternate both;
}

@-webkit-keyframes dealImage {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

@keyframes dealImage {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}

.product-deal-content {
  max-width: 525px;
}

.product-deal-content .title {
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 20px;
}

.product-deal-content .title::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

.product-deal-content .desc {
  max-width: 460px;
  margin-bottom: 30px;
}

.product-deal-content .countdown1 {
  margin-bottom: 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-deal-content .title {
    font-size: 34px;
  }
  .product-deal-content .countdown1 {
    margin-bottom: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .product-deal-content .title::after {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .product-deal-content .title {
    font-size: 34px;
  }
  .product-deal-content .countdown1 {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .product-deal-content .title {
    font-size: 28px;
  }
}

.product-images {
  position: relative;
}

.product-images.vertical {
  padding-left: 210px;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .product-images.vertical {
    padding-left: 140px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-images.vertical {
    padding-left: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-images.vertical {
    padding-left: 155px;
  }
}

@media only screen and (max-width: 767px) {
  .product-images.vertical {
    padding-left: 110px;
  }
}

@media only screen and (max-width: 575px) {
  .product-images.vertical {
    padding-left: 95px;
  }
}

@media only screen and (max-width: 479px) {
  .product-images.vertical {
    padding-left: 75px;
  }
}

.product-gallery-popup {
  position: absolute;
  z-index: 9;
  top: 20px;
  right: 20px;
  width: 30px;
  padding: 0;
  text-align: center;
  color: #333;
  border: 0;
  background-color: transparent;
}

.product-gallery-popup i {
  font-size: 24px;
  line-height: 1;
  display: block;
}

.product-video-popup {
  position: absolute;
  z-index: 9;
  top: 70px;
  right: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #333;
  border: 1px solid #333;
  border-radius: 50%;
}

.product-video-popup i {
  font-size: 10px;
  line-height: 1;
  margin-left: 3px;
}

.product-video-popup:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  color: #333;
}

.toggle-360 {
  position: absolute;
  z-index: 9;
  top: 70px;
  right: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.toggle-360:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.product-gallery-slider {
  margin: 0;
  padding: 0;
}

.product-gallery-slider .slick-slide > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-gallery-slider .slick-dots {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
}

.product-zoom {
  display: block !important;
}

.product-zoom img:not(.zoomImg) {
  width: 100%;
  height: 570px;
  object-fit: cover;
}

.product-zoom img.zoomImg {
  pointer-events: none;
}

.product-thumb-slider {
  margin: 30px 0 0;
  padding: 0;
}

.product-thumb-slider .slick-list {
  margin: 0 -15px;
}

.product-thumb-slider .slick-list .slick-slide {
  padding: 0 15px;
}

.product-thumb-slider .slick-list .slick-slide > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-thumb-slider .slick-list .slick-slide.slick-current .item {
  border-color: #333;
}

.product-thumb-slider .item {
  margin-bottom: 1px;
  border: 1px solid transparent;
}

.product-thumb-slider .item img {
  width: 100%;
}

.product-thumb-slider-vertical {
  position: absolute;
  top: 0;
  left: 0;
  width: 195px;
  margin: 0;
  padding: 0 0 75px;
}

.product-thumb-slider-vertical .slick-arrow {
  top: auto;
  bottom: 0;
  width: calc(50% - 3px);
  height: 30px;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 0.7;
  color: #fff;
  border-radius: 500px;
  background-color: #2c2c2c;
}

.product-thumb-slider-vertical .slick-arrow.slick-prev {
  left: 0;
}

.product-thumb-slider-vertical .slick-arrow.slick-next {
  right: 1px;
}

.product-thumb-slider-vertical .slick-arrow i {
  font-size: 14px;
}

.product-thumb-slider-vertical:hover .slick-arrow.slick-next {
  right: 1px;
}

.product-thumb-slider-vertical .slick-list {
  margin: 0 0 -10px;
}

.product-thumb-slider-vertical .slick-list .slick-slide {
  padding: 0 0 10px;
}

.product-thumb-slider-vertical .slick-list .slick-slide > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-thumb-slider-vertical .slick-list .slick-slide.slick-current .item {
  border-color: #333;
}

.product-thumb-slider-vertical .item {
  margin-bottom: 1px;
  border: 1px solid transparent;
}

.product-thumb-slider-vertical .item img {
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .product-thumb-slider-vertical {
    width: 125px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-thumb-slider-vertical {
    width: 95px;
    padding-bottom: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-thumb-slider-vertical {
    width: 140px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .product-thumb-slider-vertical {
    width: 95px;
    padding-bottom: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .product-thumb-slider-vertical {
    width: 80px;
    padding-bottom: 75px;
  }
  .product-thumb-slider-vertical .slick-arrow {
    right: 0;
    left: 0;
    width: 100% !important;
  }
  .product-thumb-slider-vertical .slick-arrow.slick-prev {
    bottom: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .product-thumb-slider-vertical {
    width: 65px;
    padding-bottom: 50px;
  }
  .product-thumb-slider-vertical .slick-arrow {
    height: 20px;
  }
  .product-thumb-slider-vertical .slick-arrow.slick-prev {
    bottom: 25px;
  }
}

.product-summery {
  position: relative;
}

.product-summery-center {
  text-align: center;
}

.product-summery-center .product-ratings {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-summery-center .product-variations {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-summery-center .product-variations table tbody tr td.label {
  text-align: right;
}

.product-summery-center .product-variations table tbody tr td.value {
  text-align: left;
}

.product-summery-center .product-buttons {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-summery-center .product-brands .brands {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-summery-center .product-meta {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-summery-center .product-meta table tbody tr td.label {
  text-align: right;
}

.product-summery-center .product-meta table tbody tr td.value {
  text-align: left;
}

.product-nav {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-nav a {
  padding: 8px 0 10px;
  color: #2c2c2c;
}

.product-nav a + a {
  margin-left: 10px;
}

.product-nav a i {
  font-size: 24px;
  line-height: 1;
  display: block;
}

.product-nav a:hover {
  color: #333;
}

@media only screen and (max-width: 767px) {
  .product-nav {
    display: none;
  }
}

.product-ratings {
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  padding: 13px 0;
}

.product-ratings .star-rating {
  font-size: 12px;
  position: relative;
  display: block;
  margin-right: 10px;
}

.product-ratings .star-rating::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  content: "    ";
  color: #2c2c2c;
}

.product-ratings .star-rating .rating-active {
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  height: 100%;
}

.product-ratings .star-rating .rating-active::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  content: "    ";
  white-space: nowrap;
  color: #f5cc26;
}

.product-ratings .review-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  color: #2c2c2c;
}

.product-ratings .review-link:hover {
  color: #333;
}

.product-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.1675;
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .product-title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .product-title {
    font-size: 30px;
  }
}

.product-price {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.175;
  display: block;
  margin-bottom: 10px;
  color: #333;
}

.product-description {
  margin-bottom: 10px;
}

.product-description p {
  line-height: 1.565;
}

.group-product-list {
  margin-bottom: 10px;
}

.group-product-list .info-text {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 15px;
}

.group-product-list .info-text:last-child {
  margin-bottom: 0;
}

.group-product-list .info-text span {
  font-weight: 700;
}

.group-product-list table {
  width: 100%;
  margin-bottom: 15px;
  border-top: 1px dotted #e5e5e5;
}

.group-product-list table:last-child {
  margin-bottom: 0;
}

.group-product-list table tbody tr td {
  font-weight: 500;
  padding: 10px 10px 10px 0;
  border-width: 0 0 1px 0;
  border-style: dotted;
  border-color: #e5e5e5;
  background-color: transparent;
}

.group-product-list table tbody tr td.quantity {
  font-size: 13px;
  color: #333;
}

.group-product-list table tbody tr td.thumb {
  width: 50px;
}

.group-product-list table tbody tr td.title {
  font-size: 16px;
  font-weight: 500;
}

.group-product-list table tbody tr td.price {
  padding-right: 0;
}

.group-product-list table tbody tr td.price .pro-price {
  display: block;
  text-align: right;
}

.group-product-list table tbody tr td.price .pro-price span {
  font-size: 16px;
  font-weight: 700;
  color: #333;
}

.group-product-list table tbody tr td.price .pro-price span.old {
  font-size: 13px;
  margin-right: 5px;
  text-decoration: line-through;
  opacity: 0.5;
}

@media only screen and (max-width: 479px) {
  .group-product-list table tbody tr td.price .pro-price span {
    display: block;
  }
}

.product-variations {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
}

.product-variations .label span {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}

.product-variations table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-variations table tbody tr td.label {
  width: 160px;
  padding-right: 40px;
}

@media only screen and (max-width: 575px) {
  .product-variations table tbody tr td.label {
    width: 120px;
  }
}

.product-sizes {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-sizes a {
  font-weight: 400;
  line-height: 1;
  margin-right: 15px;
  color: #2c2c2c;
}

.product-sizes a:last-child {
  margin-right: 0;
}

.product-sizes a:hover {
  color: #333;
}

@media only screen and (max-width: 575px) {
  .product-sizes a {
    font-size: 18px;
  }
}

.product-colors {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.product-colors a {
  width: 18px;
  height: 18px;
  margin-right: 15px;
  border: 1px solid #ddd;
  border-radius: 50%;
}

.product-colors a:last-child {
  margin-right: 0;
}

.product-quantity {
  font-weight: 600;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 117px;
  padding: 10px 0;
  border-bottom: 2px solid #2c2c2c;
}

.product-quantity .qty-btn {
  font-size: 14px;
  line-height: 1;
  cursor: pointer;
  color: #333;
}

.product-quantity .input-qty {
  font-size: 14px !important;
  line-height: 1;
  max-width: 88px;
  padding: 0 15px !important;
  text-align: center;
  border: none !important;
}

.product-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-bottom: 40px;
  margin-left: -15px;
}

.product-buttons .btn {
  margin-right: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
}

@media only screen and (max-width: 479px) {
  .product-buttons {
    margin-right: -5px;
    margin-left: -5px;
  }
  .product-buttons .btn {
    margin-right: 5px;
    margin-left: 5px;
  }
  .product-buttons .btn-icon {
    width: 50px;
    padding: 12px;
  }
  .product-buttons .btn:not(.btn-icon) {
    padding: 12px 30px;
  }
}

.product-brands {
  margin-bottom: 30px;
}

.product-brands .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #333;
}

.product-brands .brands {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -20px;
}

.product-brands .brands a {
  margin: 0 20px;
}

.product-brands .brands a img {
  height: auto;
  width: 125px;
  object-fit: contain;
  opacity: 0.3;
}

.product-brands .brands a:hover img {
  opacity: 1;
}

.product-meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  border-top: 1px solid #e0e0e0;
}

.product-meta .label span {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}

.product-meta table tbody tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.product-meta table tbody tr td.label {
  width: 160px;
  padding-right: 40px;
}

@media only screen and (max-width: 575px) {
  .product-meta table tbody tr td.label {
    width: 120px;
  }
}

.product-category {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.product-category li {
  font-size: 16px;
}

.product-category li:not(:last-child)::after {
  margin-right: 6px;
  content: ",";
}

.product-category li a:hover {
  color: #333;
}

.product-tags {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}

.product-tags li {
  font-size: 16px;
}

.product-tags li:not(:last-child)::after {
  margin-right: 6px;
  content: ",";
}

.product-tags li a:hover {
  color: #333;
}

.product-share {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-share a {
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
}

.product-share a:last-child {
  margin-right: 0;
}

.product-share a i {
  font-size: 16px;
  line-height: 1;
}

.product-share a:hover i {
  color: #ff4c00;
}

.product-info-tab-list {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 0px 15px;
}

.product-info-tab-list li {
  padding: 0 20px 20px;
}

.product-info-tab-list li a {
  font-size: 22px;
  font-weight: 400;
  line-height: 1;
  display: block;
  opacity: 0.2;
  color: #333;
}

.product-info-tab-list li a.active,
.product-info-tab-list li a:hover {
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px) {
  .product-info-tab-list {
    margin-right: -15px;
    margin-left: -15px;
  }
  .product-info-tab-list li {
    padding-right: 15px;
    padding-left: 15px;
  }
  .product-info-tab-list li a {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-info-tab-list {
    margin-right: -10px;
    margin-bottom: 30px;
    margin-left: -10px;
  }
  .product-info-tab-list li {
    padding-right: 10px;
    padding-left: 10px;
  }
  .product-info-tab-list li a {
    font-size: 24px;
  }
}

.product-review-wrapper {
  max-width: 820px;
  margin: auto;
}

.product-review-wrapper > .title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  display: block;
  margin-bottom: 40px;
  text-align: center;
  color: #333;
}

.product-review-wrapper > .title:last-child {
  margin-bottom: 0;
}

.product-review-list {
  margin: 0 0 70px;
  padding-left: 0;
  list-style: none;
}

.product-review-list li {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e8e8e8;
}

@media only screen and (max-width: 767px) {
  .product-review-list {
    margin-bottom: 50px;
  }
}

.product-review {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-review .thumb {
  overflow: hidden;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  width: 70px;
  border-radius: 50%;
}

.product-review .thumb img {
  width: 100%;
}

.product-review .content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 calc(100% - 70px);
  -ms-flex: 1 0 calc(100% - 70px);
  flex: 1 0 calc(100% - 70px);
  padding-left: 30px;
}

.product-review .content .ratings {
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.product-review .content .ratings .star-rating {
  font-size: 12px;
  position: relative;
  display: block;
  margin-right: 10px;
}

.product-review .content .ratings .star-rating::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  content: "    ";
  color: #2c2c2c;
}

.product-review .content .ratings .star-rating .rating-active {
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  overflow: hidden;
  height: 100%;
}

.product-review .content .ratings .star-rating .rating-active::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  content: "    ";
  white-space: nowrap;
  color: #f5cc26;
}

.product-review .content .meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.product-review .content .meta .title {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
}

.product-review .content .meta .date {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin-left: 12px;
  color: #333;
}

.product-review .content .meta .date::before {
  margin-right: 10px;
  content: "/";
}

@media only screen and (max-width: 479px) {
  .product-review {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .product-review .content {
    padding-top: 20px;
    padding-left: 0;
  }
}

.review-form .note {
  font-size: 16px;
  margin-bottom: 25px;
}

.form-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form-rating .title {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: block;
  margin-right: 40px;
  margin-bottom: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #333;
}

.form-rating .rating {
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  line-height: 1;
  position: relative;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  color: #f5cc26;
}

.form-rating .rating::before {
  content: "    ";
}

.form-rating .rating .star {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.form-rating .rating .star::before {
  font-weight: 700;
  content: "    ";
}

.form-rating .rating:hover .star {
  opacity: 1;
}

.quickViewModal .modal-content:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: "";
  background-image: url("../images/packages/shadow.jpg");
  z-index: 0;
  background-position: right bottom;
  background-size: 300px;
  background-repeat: no-repeat;
  filter: opacity(0.2);
}

.quickViewModal .modal-content .row {
  position: relative;
  z-index: 4;
}

.quickViewModal .modal-dialog .modal-content {
  padding: 30px;
}

.quickViewModal .modal-dialog .modal-content .close {
  font-size: 30px;
  font-weight: 300;
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 50%;
  background-color: transparent;
}

@media only screen and (min-width: 992px) {
  .quickViewModal .modal-dialog .modal-content .product-summery {
    position: absolute;
    right: 15px;
    left: 15px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .quickViewModal .modal-dialog .modal-content {
    padding: 20px;
  }
}

.product-gallery-slider-quickview {
  margin: 0;
  padding: 0;
}

.product-gallery-slider-quickview .slick-list {
  margin: 0;
}

.product-gallery-slider-quickview .slick-list .slick-slide > div {
  padding: 0;
}

.product-gallery-slider-quickview .slick-dots {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
}

.blog .image {
  margin-bottom: 30px;
}

.blog .image a {
  display: block;
}

.blog .image a img {
  height: 155px;
  object-fit: cover;
  width: 100%;
}

.blog .content .meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-left: 0;
  list-style: none;
}

.blog .content .meta li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  margin-right: 25px;
}

.blog .content .meta li:last-child {
  margin-right: 0;
}

.blog .content .meta li i {
  margin-right: 8px;
  color: #2c2c2c;
}

.blog .content .meta li a {
  border-bottom: 1px solid transparent;
}

.blog .content .meta li a:hover {
  color: #333;
  border-color: rgba(51, 51, 51, 0.2);
}

.blog .content .title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.blog .content .title a:hover {
  color: #333;
}

.blog .content .desc {
  margin-bottom: 20px;
}

.blog .content .desc p {
  font-size: 16px;
}

.blog .content .desc p:last-child {
  margin-bottom: 0;
}

.blog .content .link {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.blog .content .link:hover {
  border-color: #333;
}

.single-blog .image {
  margin-bottom: 30px;
}

.single-blog .image a {
  display: block;
}

.single-blog .image a img {
  width: 100%;
}

.single-blog .content:not(:last-child) {
  margin-bottom: 60px;
}

.single-blog .category {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-left: 0;
  list-style: none;
}

.single-blog .category li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

.single-blog .category li::after {
  margin: 0 7px;
  content: "/";
}

.single-blog .category li:last-child::after {
  display: none;
}

.single-blog .category li a {
  padding-bottom: 1px;
  text-transform: uppercase;
  color: #2c2c2c;
  border-bottom: 2px solid transparent;
}

.single-blog .category li a:hover {
  color: #333;
  border-color: rgba(51, 51, 51, 0.2);
}

.single-blog .title {
  font-weight: 400;
  margin-bottom: 25px;
}

.single-blog .meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-left: 0;
  list-style: none;
}

.single-blog .meta li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 10px;
}

.single-blog .meta li:not(:last-child) {
  margin-right: 25px;
}

.single-blog .meta li i {
  margin-right: 8px;
  color: #2c2c2c;
}

.single-blog .meta li a {
  border-bottom: 1px solid transparent;
}

.single-blog .meta li a:hover {
  color: #333;
  border-color: rgba(51, 51, 51, 0.2);
}

.single-blog .tags {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 30px;
  list-style: none;
}

.single-blog .tags li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

.single-blog .tags li.icon {
  font-size: 14px;
  line-height: 1;
  position: absolute;
  top: 5px;
  left: 0;
}

.single-blog .tags li:not(:last-child):not(.icon):after {
  margin-right: 3px;
  content: ",";
}

.single-blog .tags li i {
  margin-right: 8px;
  color: #2c2c2c;
}

.single-blog .tags li a {
  border-bottom: 1px solid transparent;
}

.single-blog .tags li a:hover {
  color: #333;
  border-color: rgba(51, 51, 51, 0.2);
}

.single-blog .desc {
  margin-bottom: 20px;
}

.single-blog .desc p {
  font-size: 16px;
  margin-bottom: 25px;
}

.single-blog .desc p:last-child {
  margin-bottom: 0;
}

.single-blog .desc blockquote {
  margin: 40px 0 40px 60px;
}

.single-blog .blog-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.single-blog .post-share {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-blog .post-share .toggle {
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin-left: 20px;
  padding: 3px 0;
  cursor: pointer;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  border: 2px solid #ededed;
  border-radius: 100%;
}

.single-blog .post-share .toggle i {
  font-size: 18px;
}

.single-blog .post-share .toggle:hover {
  color: #fff;
  border-color: #333;
  background-color: #333;
}

.single-blog .post-share .social-list {
  line-height: 1;
  position: absolute;
  right: 0;
  bottom: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  margin: 0;
  padding: 5px;
  list-style: none;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.single-blog .post-share .social-list::before {
  position: absolute;
  right: 19px;
  bottom: -3px;
  width: 10px;
  height: 10px;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
}

.single-blog .post-share .social-list li {
  line-height: inherit;
}

.single-blog .post-share .social-list li a {
  line-height: 1;
  padding: 5px 8px;
}

.single-blog .post-share .social-list li a i {
  font-size: 14px;
  line-height: 1;
}

.single-blog .post-share .social-list li a:hover {
  color: #333;
}

.single-blog .post-share.active .toggle {
  color: #fff;
  border-color: #333;
  background-color: #333;
}

.single-blog .post-share.active .social-list {
  visibility: visible;
  margin-bottom: 10px;
  opacity: 1;
}

@media only screen and (max-width: 575px) {
  .single-blog .title {
    font-size: 24px;
  }
  .single-blog .desc blockquote {
    margin: 30px 0 30px 30px;
  }
}

.blog-author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 60px;
}

.blog-author .thumbnail {
  width: 90px;
}

.blog-author .thumbnail img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.blog-author .thumbnail .social {
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 15px;
}

.blog-author .thumbnail .social a {
  line-height: inherit;
  padding: 5px;
}

.blog-author .thumbnail .social a i {
  font-size: 14px;
  line-height: 1;
}

.blog-author .thumbnail .social a:hover {
  color: #333;
}

.blog-author .content {
  width: calc(100% - 90px);
  padding-right: 50px;
  padding-left: 20px;
}

.blog-author .content .name {
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  display: block;
  margin-bottom: 15px;
  color: #333;
}

.blog-author .content p {
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 575px) {
  .blog-author .content {
    width: 100%;
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0;
  }
}

.related-blog {
  margin-bottom: 60px;
}

.comment-list,
.child-comment {
  margin-bottom: 60px !important;
  padding-left: 0;
  list-style: none;
}

.child-comment {
  margin-bottom: 0 !important;
}

.child-comment .comment {
  padding-left: 110px;
}

@media only screen and (max-width: 575px) {
  .child-comment .comment {
    padding-left: 0;
  }
}

.comment {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e8e8e8;
}

.comment .thumbnail {
  width: 90px;
}

.comment .thumbnail img {
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.comment .content {
  width: calc(100% - 90px);
  padding-right: 50px;
  padding-left: 20px;
}

.comment .content .name {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 700;
  line-height: 1;
  display: block;
  margin-bottom: 15px;
}

.comment .content p {
  font-size: 16px;
  font-weight: 400;
}

.comment .content .actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.comment .content .actions .date {
  font-size: 14px;
  font-weight: 500;
}

.comment .content .actions .date::after {
  margin: 0 15px;
  content: "|";
}

.comment .content .actions .reply-link {
  font-size: 16px;
  font-weight: 700;
}

.comment .content .actions .reply-link:hover {
  color: #333;
}

@media only screen and (max-width: 575px) {
  .comment .content {
    width: 100%;
    padding-top: 20px;
    padding-right: 0;
    padding-left: 0;
  }
}

.team .image {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
}

.team .image img {
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.team .image::before {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  background-color: rgba(51, 51, 51, 0.8);
}

.team .image::after {
  position: absolute;
  z-index: 3;
  top: 20px;
  left: 20px;
  visibility: hidden;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  border: 1px solid rgba(255, 255, 255, 0.8);
  background-color: transparent;
}

.team .image .social {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  visibility: hidden;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
}

.team .image .social a {
  line-height: 1.25;
  display: block;
  margin: 0 10px;
  padding: 0 5px;
  color: #fff;
}

.team .image .social a i {
  font-size: 18px;
}

.team .image .social a:hover {
  color: #f8796c;
}

.team .content .name {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.team .content .title {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #2c2c2c;
}

.team:hover .image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.team:hover .image::before {
  visibility: visible;
  opacity: 1;
}

.team:hover .image::after {
  visibility: visible;
  opacity: 1;
}

.team:hover .image .social {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .testimonial-carousel.slick-slider {
    margin-right: -10px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.testimonial {
  padding-right: 20px;
}

.testimonial p {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 26px;
  opacity: 0.8;
  color: #454545;
}

.testimonial .author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial .author img {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.testimonial .author .content {
  text-align: left;
}

.testimonial .author .content .name {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 18px;
  color: #333;
}

.testimonial .author .content .title {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: block;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #2c2c2c;
}

@media only screen and (max-width: 767px) {
  .testimonial {
    text-align: center;
  }
  .testimonial p {
    line-height: 1.5;
  }
  .testimonial .author {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.testimonial2 {
  text-align: center;
}

.testimonial2 p {
  font-family: "Marcellus";
  font-size: 24px;
  font-weight: 400;
  line-height: 1.75;
  max-width: 800px;
  margin-right: auto;
  margin-bottom: 40px;
  margin-left: auto;
  opacity: 0.8;
  color: #fff;
}

.testimonial2 .author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial2 .author img {
  width: 100px;
  height: 100px;
  margin-right: 30px;
  border-radius: 50%;
  padding: 3px;
  background-color: #fff;
  box-shadow: 0 0 14px #ffffff4d;
  object-fit: cover;
}

.testimonial2 .author .content {
  text-align: left;
}

.testimonial2 .author .content .name {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 18px;
  color: #f8f9fa;
}

.testimonial-slider i {
  color: #fff;
}

.testimonial2 .author .content .title {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: block;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .testimonial2 p {
    font-size: 20px;
  }
  .testimonial2 .author img {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial2 p {
    font-size: 18px;
  }
}

.instafeed-carousel .slick-list {
  margin-right: -7.5px !important;
  margin-left: -7.5px !important;
}

.instafeed-carousel .slick-list .slick-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.instafeed-carousel .slick-list .slick-slide > * {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 !important;
}

@media only screen and (max-width: 767px) {
  .instafeed-carousel {
    overflow: hidden;
  }
  .instafeed-carousel .slick-slider {
    margin-right: -10px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.instafeed-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.instafeed-grid .instafeed-item {
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 25%;
  -ms-flex: 1 0 25%;
  flex: 1 0 25%;
  max-width: 25%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .instafeed-grid .instafeed-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 33.3333%;
    -ms-flex: 1 0 33.3333%;
    flex: 1 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (max-width: 767px) {
  .instafeed-grid .instafeed-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 50%;
    -ms-flex: 1 0 50%;
    flex: 1 0 50%;
    max-width: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .instafeed-grid .instafeed-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 100%;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
    max-width: 100%;
  }
}

.instafeed-item {
  position: relative;
  display: block;
}

.instafeed-item::before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  background-color: #000;
}

.instafeed-item img {
  width: 100%;
}

.instafeed-item i {
  font-size: 20px;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  opacity: 0;
  color: #fff;
}

.instafeed-item:hover::before {
  opacity: 0.5;
}

.instafeed-item:hover i {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.countdown1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.countdown1 .count {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 25%;
  -ms-flex: 1 0 25%;
  flex: 1 0 25%;
  max-width: 25%;
  padding: 0 5px;
}

.countdown1 .count span {
  display: block;
}

.countdown1 .count span.amount {
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 27px;
  color: #333;
}

.countdown1 .count span.amount::after {
  font-weight: 300;
  float: right;
  margin-top: -4px;
  content: ":";
  color: #2c2c2c;
}

.countdown1 .count span.period {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #2c2c2c;
}

.countdown1 .count:last-child .amount::after {
  display: none;
}

@media only screen and (max-width: 479px) {
  .countdown1 .count span.amount {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .countdown1 .count span.period {
    font-size: 12px;
  }
}

.countdown2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.countdown2 .count {
  margin-right: 30px;
  padding-right: 15px;
}

.countdown2 .count:last-child {
  margin-right: 0;
  padding-right: 0;
}

.countdown2 .count span {
  display: block;
  text-align: center;
}

.countdown2 .count span.amount {
  font-family: "Marcellus";
  font-size: 36px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 27px;
  color: #f8796c;
}

.countdown2 .count span.amount::after {
  font-weight: 100;
  float: right;
  margin-top: -4px;
  margin-right: -20px;
  content: ":";
  color: #2c2c2c;
}

.countdown2 .count span.period {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #2c2c2c;
}

.countdown2 .count:last-child .amount::after {
  display: none;
}

.countdown2.primary2 .count span.amount {
  color: #ff4c00;
}

@media only screen and (max-width: 479px) {
  .countdown2 .count {
    margin-right: 20px;
    padding-right: 10px;
  }
  .countdown2 .count span.amount {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .countdown2 .count span.amount::after {
    margin-right: -15px;
  }
  .countdown2 .count span.period {
    font-size: 12px;
  }
}

.portfolio {
  position: relative;
  overflow: hidden;
}

.portfolio .thumbnail {
  position: relative;
}

.portfolio .thumbnail::before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  background-color: #ffcdb8;
}

.portfolio .thumbnail img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}

.shop-byuse .col {
  padding: 0 5px !important;
  margin-bottom: 10px;
}

.portfolio .content {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 0;
  padding: 20px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  text-align: center;
  opacity: 0;
  opacity: 0;
}

.portfolio .content .title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}

.portfolio .content .title a:hover {
  color: #333;
}

.portfolio .content .desc {
  margin-bottom: 20px;
}

.portfolio .content .desc p {
  font-size: 16px;
  font-weight: 400;
}

.portfolio .content .link a {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding-bottom: 2px;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.portfolio .content .link a:hover {
  border-color: #333;
}

.portfolio:hover .thumbnail::before {
  opacity: 1;
}

.portfolio:hover .content {
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  .portfolio .content {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 0;
    padding: 20px;
    -webkit-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    text-align: center;
    opacity: 0;
    opacity: 0;
  }
  .portfolio .content .title {
    margin-bottom: 10px;
  }
  .portfolio .content .desc {
    margin-bottom: 10px;
  }
}

.portfolio-image {
  overflow: hidden;
}

.portfolio-image img {
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.portfolio-image:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.portfolio-content .title {
  font-weight: 400;
  margin-bottom: 25px;
}

.portfolio-content .desc p {
  font-size: 16px;
  margin-bottom: 20px;
}

.portfolio-content .meta {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.portfolio-content .meta li {
  padding: 10px 0;
  border-top: 1px solid #eee;
}

.portfolio-content .meta li span.name {
  font-weight: 500;
  color: #333;
}

.portfolio-content .meta li span.value.category a:not(:last-child)::after,
.portfolio-content .meta li span.value.tags a:not(:last-child)::after {
  margin-right: 1px;
  content: ",";
}

.portfolio-content .meta li span.value.social a {
  padding: 0 10px;
}

.portfolio-content .meta li span.value a:hover {
  color: #333;
}

@media only screen and (max-width: 575px) {
  .portfolio-content .title {
    font-size: 24px;
  }
}

.portfolio-overview p {
  font-size: 16px;
}

.single-widget {
  float: left;
  width: 100%;
}

.widget-title {
  font-family: "Futura";
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 20px;
  color: #333;
}

.widget-search form {
  position: relative;
}

.widget-search form input {
  font-size: 16px;
  line-height: 1.25;
  width: 100%;
  padding: 8px 36px 8px 0 !important;
  color: #2c2c2c;
  border: 2px solid transparent;
  border-bottom-color: #2c2c2c;
}

.widget-search form button {
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  border: 0;
  background-color: transparent;
}

.widget-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -20px;
  padding-left: 0;
  list-style: none;
}

.widget-menu li {
  font-size: 16px;
  font-weight: 400;
  margin: 0 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.widget-menu li a {
  color: #2c2c2c;
}

.widget-menu li a:hover {
  color: #333;
}

@media only screen and (max-width: 767px) {
  .widget-menu li {
    font-size: 14px;
  }
}

.widget-about img {
  margin-bottom: 20px;
  height: auto;
  width: 140px;
}

.widget-about img:last-child {
  margin-bottom: 0;
}

.widget-about p {
  line-height: 1.5;
  max-width: 400px;
}

.widget-list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.social-links a:hover {
  color: #69696900 !important;
  border: 0 !important;
}

.social-links a:hover i {
  color: #ff4c00 !important;
}

.widget-list li {
  font-size: 16px;
  font-weight: 400;
  line-height: 17px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.widget-list li + li {
  margin-top: 11px;
}

.widget-list li i {
  width: 35px;
  color: #696969;
}

.widget-list li a {
  color: #696969;
  border-bottom: 1px solid transparent;
}

.widget-list li a:hover {
  color: #333;
  border-color: rgba(51, 51, 51, 0.2);
}

.widget-list li .count {
  margin-left: auto;
  color: #2c2c2c;
}

.widget-colors {
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px -20px;
  padding-left: 0;
  list-style: none;
}

.widget-colors li {
  margin: 0 10px 20px;
}

.widget-colors li a span {
  display: block;
  width: 20px;
  height: 20px;
  text-indent: -9999px;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
}

.widget-products {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.widget-products .product {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.widget-products .product + .product {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.widget-products .product .thumbnail {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 120px;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  padding-right: 20px;
}

.widget-products .product .thumbnail a {
  display: block;
}

.widget-products .product .thumbnail a img {
  width: 100%;
}

.widget-products .product .content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 calc(100% - 120px);
  -ms-flex: 1 0 calc(100% - 120px);
  flex: 1 0 calc(100% - 120px);
}

.widget-products .product .content .title {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 12px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.widget-products .product .content .title a {
  color: #2c2c2c;
}

.widget-products .product .content .title a:hover {
  color: #333;
}

.widget-products .product .content .price {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 8px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  color: #333;
}

.widget-products .product .content .price:last-child {
  margin-bottom: 0;
}

.widget-products .product .content .price span.old {
  text-decoration: line-through;
  color: #aaa;
}

.widget-products .product .content .price span.new {
  margin-left: 10px;
  color: #f8796c;
}

.widget-products .product .content .ratting {
  line-height: 1;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.widget-products .product .content .ratting::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  font-weight: 300;
  line-height: 1;
  content: "    ";
  color: #aaa;
}

.widget-products .product .content .ratting .rate {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
  color: #f5cc26;
}

.widget-products .product .content .ratting .rate::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  font-weight: 700;
  line-height: 1;
  content: "    ";
}

.widget-blogs {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.widget-blogs .widget-blog {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.widget-blogs .widget-blog + .widget-blog {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.widget-blogs .widget-blog .thumbnail {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 120px;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  padding-right: 20px;
}

.widget-blogs .widget-blog .thumbnail a {
  display: block;
}

.widget-blogs .widget-blog .thumbnail a img {
  width: 100%;
}

.widget-blogs .widget-blog .content {
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 calc(100% - 120px);
  -ms-flex: 1 0 calc(100% - 120px);
  flex: 1 0 calc(100% - 120px);
}

.widget-blogs .widget-blog .content .title {
  font-family: "Futura";
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.widget-blogs .widget-blog .content .title a {
  color: #333;
}

.widget-blogs .widget-blog .content .title a:hover {
  color: #333;
}

.widget-blogs .widget-blog .content .date {
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  display: block;
}

.widget-tags a {
  font-size: 16px;
  font-weight: 400;
  position: relative;
  color: #2c2c2c;
}

.widget-tags a::before {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: calc(100% - 18px);
  height: 1px;
  content: "";
  -webkit-transition: opacity 0.5s ease;
  -o-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0;
  background-color: #333;
}

.widget-tags a::after {
  font-size: 16px;
  margin: 0 8px;
  content: "/";
  color: #2c2c2c;
}

.widget-tags a:hover {
  color: #333;
}

.widget-tags a:hover::before {
  opacity: 0.2;
}

.widget-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-left: 0;
  list-style: none;
}

.widget-social li {
  margin: 0 10px;
}

.widget-social li a {
  line-height: 1.25;
  display: block;
  padding: 0 5px;
  color: #333;
}

.widget-social li a i {
  font-size: 18px;
}

.widget-subscibe {
  position: relative;
  width: 100%;
  max-width: 570px;
  margin: auto;
}

.widget-subscibe input[type="email"] {
  font-size: 16px;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  padding-right: 170px;
  padding-bottom: 10px;
  padding-left: 20px;
  letter-spacing: 0.03em;
  border: 1px solid transparent;
  border-radius: 50px;
  background-color: #fff;
}

.widget-subscibe input[type="email"]::-webkit-input-placeholder {
  color: #696969;
}

.widget-subscibe input[type="email"]:-moz-placeholder {
  color: #696969;
}

.widget-subscibe input[type="email"]::-moz-placeholder {
  color: #696969;
}

.widget-subscibe input[type="email"]:-ms-input-placeholder {
  color: #696969;
}

.widget-subscibe input[type="email"]:focus {
  border-color: transparent !important;
}

.widget-subscibe .btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 1.5px;
}

.widget-subscibe .btn:hover {
  border-color: transparent !important;
  background-color: #ff4c00 !important;
}

@media only screen and (max-width: 767px) {
  .widget-subscibe input[type="email"] {
    width: 510px;
  }
}

@media only screen and (max-width: 575px) {
  .widget-subscibe input[type="email"] {
    width: 420px;
  }
}

@media only screen and (max-width: 479px) {
  .widget-subscibe input[type="email"] {
    width: 100%;
    padding-right: 20px;
    text-align: center;
  }
  .widget-subscibe .btn {
    position: static;
    width: 100%;
    margin-top: 10px;
  }
}

.widget-subscibe2 {
  position: relative;
  max-width: 570px;
  margin: 0;
}

.widget-subscibe2 input[type="email"] {
  font-size: 16px;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  padding-right: 150px;
  padding-bottom: 10px;
  padding-left: 0;
  letter-spacing: 0.03em;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent #2c2c2c;
  border-radius: 0;
  background-color: transparent;
}

.widget-subscibe2 input[type="email"]::-webkit-input-placeholder {
  color: #696969;
}

.widget-subscibe2 input[type="email"]:-moz-placeholder {
  color: #696969;
}

.widget-subscibe2 input[type="email"]::-moz-placeholder {
  color: #696969;
}

.widget-subscibe2 input[type="email"]:-ms-input-placeholder {
  color: #696969;
}

.widget-subscibe2 input[type="email"]:focus {
  border-color: transparent !important;
}

.widget-subscibe2 .btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  padding: 10px;
  letter-spacing: 1.5px;
  border: 0;
  border-bottom: 2px solid #333;
  border-radius: 0;
}

.widget-subscibe2 .btn:hover {
  color: #ff4c00 !important;
  border-color: #ff4c00 !important;
}

.widget-contact .email {
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 12px;
}

.widget-contact .phone {
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  color: #333;
}

.widget-contact .app-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -10px;
}

.widget-contact .app-buttons:not(:first-child) {
  margin-top: 35px;
}

.widget-contact .app-buttons a {
  margin: 0 10px;
}

.widget-contact .app-buttons a img:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.widget-contact2 p {
  font-size: 16px;
  font-weight: 400;
}

.footer1-section {
  background-color: #ffcdb8;
}

.footer1-logo {
  margin-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .footer1-logo {
    margin-bottom: 40px;
  }
}

.footer1-menu {
  margin-bottom: 40px;
}

.footer1-subscribe {
  margin-bottom: 55px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .footer1-subscribe {
    margin-bottom: 40px;
  }
}

.footer1-social {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .footer1-social {
    margin-bottom: 30px;
  }
}

.footer2-section {
  background-color: #f8f8f8;
}

.footer2-copyright {
  padding: 20px 0;
  color: #2c2c2c;
  /* border-top: 1px solid #ebebeb; */
  background-color: #020d1c;
}

.bottom-images {
    background: #040f1e;
    padding: 11px;
    margin: 0;
}

.copyright {
  font-size: 16px;
  font-weight: 400;
}

.copyright strong {
  color: #333;
}

.copyright a:hover {
  color: #ff4c00;
}

.shop-toolbar {
  padding-top: 25px;
  padding-bottom: 25px;
}

.shop-product-filter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -20px -10px;
}

.shop-product-filter button {
  font-size: 18px;
  line-height: 1;
  margin: 0 20px 10px;
  padding: 0;
  text-transform: uppercase;
  color: #2c2c2c;
  border: 0;
  background-color: transparent;
}

.shop-product-filter button:hover,
.shop-product-filter button.active {
  color: #333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .shop-product-filter {
    margin: 0 -15px -10px;
  }
  .shop-product-filter button {
    font-size: 16px;
    margin: 0 15px 10px;
  }
}

@media only screen and (max-width: 575px) {
  .shop-product-filter {
    margin: 0 -10px -10px;
  }
  .shop-product-filter button {
    font-size: 14px;
    margin: 0 10px 10px;
  }
}

.shop-toolbar-controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px;
  padding-left: 0;
  list-style: none;
}

.shop-toolbar-controls > li {
  margin: 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .shop-toolbar-controls {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.product-sorting {
  position: relative;
  z-index: 9;
}

.product-sorting .nice-select {
  height: auto;
  padding-left: 0;
  border: 0;
  border-bottom: 2px solid #2c2c2c;
  border-radius: 0;
}

.product-sorting .nice-select::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 700;
  line-height: 1;
  top: 50%;
  right: 8px;
  bottom: auto;
  width: auto;
  height: auto;
  margin: 0;
  content: "\f0d7";
  -webkit-transform: rotate(0) translateY(-50%);
  -ms-transform: rotate(0) translateY(-50%);
  transform: rotate(0) translateY(-50%);
  color: #2c2c2c;
  border: 0;
}

.product-sorting .nice-select .current {
  font-size: 16px;
  line-height: 28px;
  display: block;
  padding-bottom: 4px;
}

.product-sorting .nice-select .list {
  margin-top: 10px;
  padding: 15px;
  -webkit-transform: scale(1) translate(0, 0);
  -ms-transform: scale(1) translate(0, 0);
  transform: scale(1) translate(0, 0);
  border-radius: 0;
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
}

.product-sorting .nice-select .list li {
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 1.25;
  min-height: auto;
  padding: 8px;
}

.product-sorting .nice-select .list li.selected {
  color: #333;
  background-color: transparent;
}

.product-sorting .nice-select .list li:hover {
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
  background-color: transparent;
}

.product-sorting .nice-select.open .list {
  margin-top: 0;
}

.product-column-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -10px;
}

.product-column-toggle .toggle {
  margin: 0 10px;
  padding: 0;
  color: #2c2c2c;
  border: 0;
  background-color: transparent;
}

.product-column-toggle .toggle i {
  font-size: 14px;
}

.product-column-toggle .toggle:hover,
.product-column-toggle .toggle.active {
  color: #333;
}

.product-filter-toggle {
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #2c2c2c;
}

.product-filter-toggle::before {
  font-family: "Font Awesome 5 Pro";
  font-size: 13px;
  line-height: 1;
  margin-right: 6px;
  margin-bottom: -3px;
  content: "\f0b0";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.product-filter-toggle::after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: #333;
}

.product-filter-toggle:hover {
  color: #333;
}

.product-filter-toggle:hover::after {
  right: auto;
  left: 0;
  width: 100%;
}

.product-filter-toggle.active {
  color: #333;
}

.product-filter-toggle.active::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.product-filter-toggle.active::after {
  right: auto;
  left: 0;
  width: 100%;
}

.product-filter {
  display: none;
  padding-top: 50px;
  padding-bottom: 50px;
}

.product-filter-widget-title {
  font-family: "Marcellus";
  margin-bottom: 30px;
}

.product-filter-widget {
  max-height: 210px;
}

.cart-wishlist-table {
  margin-bottom: 25px;
  border: 1px solid #ededed;
}

.cart-wishlist-table thead tr th {
  padding: 12px 16px;
  white-space: nowrap;
  border-top: 0;
  border-bottom: 1px solid #ededed !important;
}

.cart-wishlist-table thead tr th:first-child {
  padding-left: 25px;
}

.cart-wishlist-table tbody tr {
  position: relative;
}

.cart-wishlist-table tbody tr td {
  padding: 25px 10px 25px 25px;
  vertical-align: middle;
  border-color: #ededed;
}

.cart-wishlist-table tbody tr td.thumbnail {
  width: 110px;
  min-width: 110px;
}

.cart-wishlist-table tbody tr td.thumbnail a {
  display: block;
}

.cart-wishlist-table tbody tr td.name a {
  font-size: 16px;
  font-weight: 400;
  color: #2c2c2c;
}

.cart-wishlist-table tbody tr td.name a:hover {
  color: #333;
}

.cart-wishlist-table tbody tr td.price,
.cart-wishlist-table tbody tr td.subtotal {
  font-size: 14px;
  font-weight: 600;
}

.cart-wishlist-table tbody tr td.remove .btn {
  font-size: 24px;
  padding: 5px 10px;
  color: #2c2c2c;
}

.cart-wishlist-table tbody tr td.remove .btn:hover {
  color: #f44336;
}

@media only screen and (max-width: 767px) {
  .cart-wishlist-table thead {
    display: none;
  }
  .cart-wishlist-table tbody tr td {
    display: block;
    width: 100% !important;
    margin-bottom: 15px;
    padding: 0;
    text-align: center;
  }
  .cart-wishlist-table tbody tr td:not(:first-child) {
    border-top: 0;
  }
  .cart-wishlist-table tbody tr td.thumbnail {
    padding-top: 20px;
  }
  .cart-wishlist-table tbody tr td .product-quantity {
    margin: auto;
  }
  .cart-wishlist-table tbody tr td.remove {
    position: absolute;
    z-index: 9;
    top: 0;
    right: 0;
    width: auto !important;
  }
  .cart-wishlist-table tbody tr:first-child td:first-child {
    border-top: 0;
  }
}

.cart-coupon {
  position: relative;
}

.cart-coupon input[type="text"] {
  width: 300px;
  padding-right: 30px;
}

.cart-coupon .btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 0;
}

.cart-coupon .btn i {
  font-size: 20px;
}

.cart-totals {
  float: right;
  width: 400px;
  padding: 40px 30px;
  text-align: center;
  background-color: #f7f7f7;
}

.cart-totals .title {
  margin-bottom: 25px;
}

.cart-totals table {
  width: 100%;
  margin-bottom: 30px;
}

.cart-totals table tbody tr {
  font-family: "Futura";
  font-weight: 700;
  color: #333;
}

.cart-totals table tbody tr.subtotal td {
  font-size: 14px;
}

.cart-totals table tbody tr.total td {
  font-size: 24px;
}

.cart-totals table tbody tr th {
  font-size: 18px;
  padding: 15px 10px;
  text-align: left;
}

.cart-totals table tbody tr td {
  padding: 15px 10px;
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .cart-totals {
    width: 100%;
  }
}

.checkout-coupon {
  font-weight: 400;
  margin-bottom: 50px;
}

.checkout-coupon .coupon-toggle {
  margin-bottom: 20px;
}

.checkout-coupon .coupon-toggle a {
  color: #333;
  border-bottom: 1px solid #333;
}

.coupon-form {
  max-width: 500px;
  margin-bottom: 0;
  padding: 30px;
  background-color: #f8f9fa;
}

.coupon-form p {
  font-size: 16px;
  margin-bottom: 25px;
}

.coupon-form form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.coupon-form form input {
  font-weight: 400;
}

.coupon-form form .btn {
  white-space: nowrap;
}

@media only screen and (max-width: 575px) {
  .coupon-form form {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .coupon-form form .btn {
    width: 100%;
  }
}

.checkout-form input,
.checkout-form textarea {
  font-weight: 400 !important;
}

.checkout-form .select2-selection__rendered {
  font-weight: 400;
}

.order-review .table {
  margin: 0;
  text-align: left;
  border-bottom: 1px solid #ededed;
}

.order-review .table thead tr th {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 10px;
  text-transform: none;
  color: #333;
  border: 0;
  background-color: transparent;
}

.order-review .table thead tr th.total {
  font-size: 18px;
  text-align: right;
}

.order-review .table tbody tr td {
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  border-color: #ededed;
}

.order-review .table tbody tr td.name {
  color: #2c2c2c;
}

.order-review .table tbody tr td.total {
  font-size: 14px;
  font-weight: 700;
  text-align: right;
}

.order-review .table tfoot tr th {
  font-size: 18px;
  font-weight: 700;
  border: 0;
}

.order-review .table tfoot tr td {
  font-weight: 700;
  text-align: right;
  border: 0;
}

.order-review .table tfoot tr.subtotal td {
  font-size: 14px;
}

.order-review .table tfoot tr.total td {
  font-size: 24px;
  color: #f8796c;
}

.payment-method {
  margin-bottom: 30px;
  border-width: 0 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.075);
}

.payment-method .accordion .card {
  padding: 20px;
}

.payment-method .accordion .card .card-header button {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.675;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  padding-left: 22px;
  border: 0;
  background-color: transparent;
}

.payment-method .accordion .card .card-header button::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 17px;
  height: 17px;
  margin-top: 2px;
  content: "";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid #333;
  border-radius: 50%;
}

.payment-method .accordion .card .card-header button::after {
  position: absolute;
  top: 50%;
  left: 3px;
  width: 11px;
  height: 11px;
  margin-top: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  border-radius: 50%;
  background-color: #333;
}

.payment-method .accordion .card .card-header button img {
  height: 40px;
  margin: 0 10px;
}

.payment-method .accordion .card .card-body {
  position: relative;
  margin-top: 12px;
  padding: 10px 15px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.payment-method .accordion .card .card-body::before {
  position: absolute;
  top: -3px;
  left: 25px;
  width: 10px;
  height: 10px;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
}

.payment-method .accordion .card .card-body p {
  font-size: 16px;
  font-weight: 500;
}

.payment-method .accordion .card.active .card-header button::after {
  opacity: 1;
}

.payment-note {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
}

.order-tracking {
  max-width: 630px;
  margin: auto;
  padding: 60px;
  background-color: #f8f9fa;
}

.order-tracking p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
}

.order-tracking input {
  font-weight: 400;
}

.order-tracking input::-webkit-input-placeholder {
  font-weight: 400;
}

.order-tracking input:-moz-placeholder {
  font-weight: 400;
}

.order-tracking input::-moz-placeholder {
  font-weight: 400;
}

.order-tracking input:-ms-input-placeholder {
  font-weight: 400;
}

@media only screen and (max-width: 575px) {
  .order-tracking {
    padding: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .order-tracking {
    padding: 30px;
  }
}

.user-login-register {
  font-weight: 400;
  padding: 60px;
}

@media only screen and (max-width: 575px) {
  .user-login-register {
    padding: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .user-login-register {
    padding: 30px;
  }
}

.login-register-title {
  margin-bottom: 40px;
  text-align: center;
}

.login-register-form form input {
  font-weight: 400;
}

.login-register-form form input::-webkit-input-placeholder {
  font-weight: 400;
}

.login-register-form form input:-moz-placeholder {
  font-weight: 400;
}

.login-register-form form input::-moz-placeholder {
  font-weight: 400;
}

.login-register-form form input:-ms-input-placeholder {
  font-weight: 400;
}

.login-register-form form p,
.login-register-form form a {
  font-size: 16px;
}

.login-register-form form a {
  color: #333;
}

.lost-password {
  max-width: 500px;
  margin: auto;
  padding: 40px;
  background-color: #f8f9fa;
}

.lost-password p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 30px;
}

.lost-password input {
  font-weight: 400;
}

.lost-password input::-webkit-input-placeholder {
  font-weight: 400;
}

.lost-password input:-moz-placeholder {
  font-weight: 400;
}

.lost-password input::-moz-placeholder {
  font-weight: 400;
}

.lost-password input:-ms-input-placeholder {
  font-weight: 400;
}

@media only screen and (max-width: 575px) {
  .lost-password {
    padding: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .lost-password {
    padding: 30px;
  }
}

.myaccount-tab-list {
  display: block;
  margin-right: 30px;
  border: 1px solid #eee;
}

.myaccount-tab-list a {
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 14px 20px;
}

.myaccount-tab-list a + a {
  border-top: 1px solid #eee;
}

.myaccount-tab-list a:hover {
  color: #333;
}

.myaccount-tab-list a.active {
  cursor: default;
  background-color: #fafafa;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .myaccount-tab-list {
    margin-right: 0;
  }
}

.myaccount-content {
  font-size: 16px;
  font-weight: 400;
}

.myaccount-content.dashboad p {
  padding: 20px;
  background-color: #fafafa;
}

.myaccount-content.dashboad p:not(:last-child) {
  margin-bottom: 24px;
}

.myaccount-content.dashboad p span {
  text-decoration: underline;
}

.myaccount-content.order table,
.myaccount-content.download table {
  white-space: nowrap;
}

.myaccount-content.address > p {
  padding: 20px;
  background-color: #fafafa;
}

.myaccount-content.address > p:not(:last-child) {
  margin-bottom: 24px;
}

.myaccount-content.address > p span {
  text-decoration: underline;
}

.myaccount-content.address .title {
  margin-bottom: 20px;
}

.myaccount-content.address .title .edit-link {
  font-size: 12px;
  margin-left: 5px;
  text-transform: capitalize;
  color: #2c2c2c;
}

.myaccount-content.address .title .edit-link:hover {
  color: #f8796c;
}

.myaccount-content.address address {
  margin-bottom: 0;
}

.about-us {
  text-align: center;
}

.about-us .inner .logo {
  margin-bottom: 40px;
}

.about-us .inner .title {
  font-size: 40px;
  font-weight: 400;
}

.about-us .inner .title::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
}

.about-us .inner .title::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

.about-us .inner .title.no-shape::before,
.about-us .inner .title.no-shape::after {
  display: none;
}

.about-us .inner .special-title {
  font-family: "Modesty";
  font-size: 56px;
  line-height: 1.25;
  display: block;
  margin-bottom: 5px;
  color: #ff4c00;
}

.about-us .inner p {
  margin-bottom: 35px;
}

.about-us .inner .link {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
  text-transform: uppercase;
  color: #333;
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}

.about-us .inner .link:hover {
  border-color: #333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us .inner .title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .about-us .inner .title {
    font-size: 28px;
  }
  .about-us .inner .title::before,
  .about-us .inner .title::after {
    display: none;
  }
}

.about-us2 {
  text-align: center;
}

.about-us2 .inner .title {
  font-family: "Modesty";
  font-size: 80px;
  font-weight: 400;
  margin-bottom: 15px;
}

.about-us2 .inner .title::before {
  padding-right: 10px;
  content: url("../images/title/title-decor-left.webp");
}

.about-us2 .inner .title::after {
  padding-left: 10px;
  content: url("../images/title/title-decor-right.webp");
}

.about-us2 .inner .sub-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.25;
  display: block;
  margin-bottom: 25px;
  letter-spacing: 5px;
  color: #ff4c00;
}

.about-us2 .inner .desc p {
  font-size: 16px;
  line-height: 2;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us2 .inner .title {
    font-size: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .about-us2 .inner .title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .about-us2 .inner .title::before,
  .about-us2 .inner .title::after {
    display: none;
  }
}

.about-us3 .sub-title {
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
  color: #333;
}

.about-us3-img {
  position: relative;
}

.about-us3 p {
  margin-bottom: 0;
}

.about-us3-img:before {
  position: absolute;
  height: 100%;
  width: 90%;
  bottom: -2pc;
  right: -2pc;
  background-color: #ffcdb8;
  content: "";
  z-index: 0;
}

.about-us3-img img {
  position: relative;
  z-index: 3;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.about-us3 .title {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 20px;
}

.about-us3 .desc {
  margin-bottom: 15px;
}

.about-us3 .link {
  font-weight: 500;
  line-height: 1.25;
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #333;
}

.about-us3 .link::before {
  width: 60px;
  height: 2px;
  margin-right: 10px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  background-color: #333;
}

.about-us3 .link:hover {
  color: #ff4c00;
}

.about-us3 .link:hover::before {
  width: 70px;
}

@media only screen and (max-width: 575px) {
  .about-us3 .title {
    font-size: 30px;
  }
  .about-us3 .desc {
    margin-bottom: 20px;
  }
}

.about-us4 .sub-title {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
  letter-spacing: 2px;
  color: #333;
}

.about-us4 .title {
  font-size: 35px;
  font-weight: 400;
  line-height: 1.25;
  margin-bottom: 15px;
}

.about-us4 p {
  margin: 0;
  color: #000;
}

.about-us4 .title:last-child {
  margin-bottom: 0;
}

.about-us4 .desc {
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .about-us4 .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .about-us4 .title {
    font-size: 30px;
  }
}

.contact-info .title {
  font-family: "Futura";
  font-size: 22px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 15px !important;
  padding: 0 10px;
  border-bottom: 1px solid;
  width: max-content;
}

.contact-info.address-info span.info {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  font-size: 17px;
}

.contact-info.address-info b {
  font-weight: 400;
  font-size: 18px;
}

.contact-info .info {
  position: relative;
  display: block;
  padding-left: 44px;
}

.contact-info .info:not(:last-child) {
  margin-bottom: 5px;
}

.contact-info .info .icon {
  font-size: 22px;
  line-height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  color: #d8d8d8;
}

.contact-map {
  width: 100%;
  height: 500px;
}

@media only screen and (max-width: 767px) {
  .contact-map {
    height: 350px;
  }
}

.section-404 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  only screen and (max-width: 767px) {
  .section-404 {
    min-height: calc(100vh - 70px);
  }
}

.content-404 {
  padding: 100px 0;
}

.content-404 .title {
  font-size: 100px;
  font-weight: 400;
  line-height: 1;
  margin: 0;
}

.content-404 .sub-title {
  font-size: 64px;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 30px;
}

.content-404 p {
  margin-bottom: 40px;
}

.content-404 .buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: -20px;
}

.content-404 .buttons .btn {
  margin-right: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .content-404 {
    padding: 100px 0 60px;
  }
  .content-404 .title {
    font-size: 80px;
  }
  .content-404 .sub-title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .content-404 .title {
    font-size: 60px;
  }
  .content-404 .sub-title {
    font-size: 40px;
  }
}

.coming-soon-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
}

.coming-soon-content {
  text-align: center;
}

.coming-soon-content .logo {
  margin-bottom: 80px;
}

.coming-soon-content .title {
  font-size: 56px;
  font-weight: 400;
  margin-bottom: 40px;
}

.coming-soon-content .countdown3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 80px;
}

.coming-soon-content .countdown3 .count {
  min-width: 130px;
  margin: 0 10px;
}

.coming-soon-content .countdown3 .count span {
  display: block;
  color: #333;
}

.coming-soon-content .countdown3 .count span.amount {
  font-size: 100px;
  line-height: 1;
}

.coming-soon-content .countdown3 .count span.period {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .coming-soon-content .countdown3 .count {
    min-width: 100px;
  }
  .coming-soon-content .countdown3 .count span.amount {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .coming-soon-content .logo {
    margin-bottom: 60px;
  }
  .coming-soon-content .title {
    font-size: 44px;
    margin-bottom: 30px;
  }
  .coming-soon-content .countdown3 {
    margin-bottom: 60px;
  }
  .coming-soon-content .countdown3 .count {
    min-width: 80px;
  }
  .coming-soon-content .countdown3 .count span.amount {
    font-size: 60px;
  }
  .coming-soon-content .countdown3 .count span.period {
    font-size: 14px;
  }
}

@media only screen and (max-width: 479px) {
  .coming-soon-content .logo {
    margin-bottom: 40px;
  }
  .coming-soon-content .title {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .coming-soon-content .countdown3 {
    margin-bottom: 40px;
  }
  .coming-soon-content .countdown3 .count {
    min-width: 60px;
    margin: 0 5px;
  }
  .coming-soon-content .countdown3 .count span.amount {
    font-size: 40px;
  }
  .coming-soon-content .countdown3 .count span.period {
    font-size: 12px;
    letter-spacing: 0;
  }
}

ul.test-box {
  padding: 1pc !important;
  margin: 1pc 0 3pc !important;
  height: 320px;
}

.test-performed .col-12:nth-child(odd) ul.test-box {
  background-color: #f8f5de;
}

.test-performed .col-12:nth-child(even) ul.test-box {
  background-color: #edf6eb;
}

ul.test-box > li {
  border: 1px solid #eadddd;
  list-style-position: inside;
  padding: 1pc;
  font-weight: 400;
  height: 100%;
}

ul.test-box > li li {
  font-weight: 300;
}

.test-performed .sale-banner7 .inner .content {
  bottom: 0;
}

.test-performed .sale-banner7 .inner .content h3 {
  margin: 0;
}

.certified .category-banner5 .inner .image img {
  object-fit: contain;
  height: auto;
  width: 140px;
}

.certified .category-banner5 .inner .image {
  overflow: hidden;
  padding: 2pc;
  margin: 0;
}

.certified .category-banner5 .content h3.title {
  font-size: 17px;
  margin: 0;
}

.machine-capability li {
  list-style: none;
  border-bottom: 1px solid #ddd;
  padding: 7px 2pc 7px 1pc;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.machine-capability ul {
  padding: 0;
  margin: 0;
}

.machine-capability .col-12:first-child {
  padding: 0 5pc 0 0;
}

.machine-capability img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.machine-capability img:first-child {
  margin-bottom: 2pc;
}

.machine-capability h4.title {
  margin-bottom: 2pc;
}

.machines-text img {
  width: 100%;
  object-fit: cover;
  height: 300px;
}

.machines-text h4.title {
  margin-bottom: 1pc;
}

.machines-text li span {
  font-weight: 400;
}

section.suggestion-form {
  position: fixed;
  bottom: 1pc;
  right: 5pc;
  z-index: 9999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

section.suggestion-form img {
  width: 55px;
  /* border: 2px solid #fff; */
  border-radius: 50%;
  /* box-shadow: 0 0 14px lightgrey; */
  cursor: pointer;
  /* background-color: #fff; */
}

.suggestion-formHeight {
  opacity: 1 !important;
  visibility: visible !important;
  transform: scaleY(1) !important;
  transition: all 1s;
}

.suggestion-form .form {
  background-color: #fff;
  padding: 2pc 3pc;
  box-shadow: 0 0 15px #0000001f;
  border-radius: 30px;
  position: absolute;
  bottom: 5pc;
  right: -3pc;
  width: 35vw;
  height: auto;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transition: all 1s;
}

.suggestion-form .form .title {
  font-size: 24px;
  margin-bottom: 1pc;
}

.suggestion-form .form textarea {
  height: 100px;
}

.suggestion-form .form:before {
  content: "";
  position: absolute;
  height: 30px;
  width: 30px;
  bottom: -15px;
  right: 4pc;
  background-color: #fff;
  transform: rotate(45deg);
  box-shadow: 10px 10px 15px #00000014;
  z-index: 0;
}

/*---------------------------------07 / 02 / 2023 css new responsive------------------------------------ */

.btnmobile{
  margin-top: 10px;
}

/*---------------------------------08 / 02 / 2023 css new responsive 768px start ------------------------------------ */



/*---------------------------------08 / 02 / 2023 css new responsive 768px start ------------------------------------ */

.customHeader .accordion-item {
  border: none;
  border-bottom: 1px solid #ccc;
}
.mobile_navbarcanvastoggle .accordion-button {
  padding: 13px 0;
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  letter-spacing: 1.5px;
  font-family: "Futura", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
}
.mobile_navbarcanvastoggle .accordion-button:focus{
  box-shadow: none;
}
.mobile_navbarcanvastoggle .accordion-button:not(.collapsed){
  box-shadow: none;
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  background-color: transparent;
}
.mobile_navbarcanvastoggle .list_name ul{
  padding: 0;
  margin: 0;
}
.mobile_navbarcanvastoggle .accordion-body{
  padding: 0;
}
.mobile_navbarcanvastoggle .accordion-body ul li a{
  color: #000;
    padding: 4px 0;
    font-size: 14px;
    font-weight: 500;
}
.mediasocorse{
  min-width:900px
}
.mobile_delitls{
  height: 700px;
}

