/*----------------------------------------*/
/*  Font ABeeZee
/*----------------------------------------*/
/* latin */
@font-face {
	font-family: "ABeeZee";
	font-weight: 400;
	font-style: italic;

	font-display: swap;
	src: local("ABeeZee Italic"), local("ABeeZee-Italic"), url(../../fonts/abeezee/abeezee-italic.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin */
@font-face {
	font-family: "ABeeZee";
	font-weight: 400;
	font-style: normal;

	font-display: swap;
	src: local("ABeeZee Regular"),
	local("ABeeZee-Regular"),
	url(../../fonts/abeezee/abeezee.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*----------------------------------------*/
/*  Font Futura
/*----------------------------------------*/

@font-face {
	font-family: "Futura";
	font-weight: 300;
	font-style: normal;

	font-display: swap;
	src: url(../../fonts/futura/futura-lig-webfont.eot);
	src: url(../../fonts/futura/futura-lig-webfontd41d.eot?#iefix) format("embedded-opentype"),
	url(../../fonts/futura/futura-lig-webfont.woff2) format("woff2"),
	url(../../fonts/futura/futura-lig-webfont.woff) format("woff"),
	url(../../fonts/futura/futura-lig-webfont.svg#futuraligregular) format("svg");
	;
}

@font-face {
	font-family: "Futura";
	font-weight: 400;
	font-style: normal;

	font-display: swap;
	src: url(../../fonts/futura/futura-med-webfont.eot);
	src: url(../../fonts/futura/futura-med-webfontd41d.eot?#iefix) format("embedded-opentype"),
	url(../../fonts/futura/futura-med-webfont.woff2) format("woff2"),
	url(../../fonts/futura/futura-med-webfont.woff) format("woff"),
	url(../../fonts/futura/futura-med-webfont.svg#futuramedregular) format("svg");
	;
}

@font-face {
	font-family: "Futura";
	font-weight: 600;
	font-style: normal;

	font-display: swap;
	src: url(../../fonts/futura/futura-bol-webfont.eot);
	src: url(../../fonts/futura/futura-bol-webfontd41d.eot?#iefix) format("embedded-opentype"),
	url(../../fonts/futura/futura-bol-webfont.woff2) format("woff2"),
	url(../../fonts/futura/futura-bol-webfont.woff) format("woff"),
	url(../../fonts/futura/futura-bol-webfont.svg#futurabold) format("svg");
	;
}

/*----------------------------------------*/
/*  Font Marcellus
/*----------------------------------------*/
/* latin-ext */
@font-face {
	font-family: "Marcellus";
	font-weight: 400;
	font-style: normal;

	font-display: swap;
	src: local("Marcellus"),
	local("Marcellus-Regular"),
	url(../../fonts/marcellus/marcellus-latin-ext.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
	font-family: "Marcellus";
	font-weight: 400;
	font-style: normal;

	font-display: swap;
	src: local("Marcellus"),
	local("Marcellus-Regular"),
	url(../../fonts/marcellus/marcellus-latin.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*----------------------------------------*/
/*  Font Modesty
/*----------------------------------------*/
@font-face {
	font-family: "Modesty";
	font-weight: normal;
	font-style: normal;

	font-display: swap;
	src: url("../../fonts/modesty/ModestyRegularByMCT.eot");
	src: url("../../fonts/modesty/ModestyRegularByMCTd41d.eot?#iefix") format("embedded-opentype"),
	url("../../fonts/modesty/ModestyRegularByMCT.woff2") format("woff2"),
	url("../../fonts/modesty/ModestyRegularByMCT.woff") format("woff"),
	url("../../fonts/modesty/ModestyRegularByMCT.ttf") format("truetype"),
	url("../../fonts/modesty/ModestyRegularByMCT.svg#ModestyRegularByMCT") format("svg");
}