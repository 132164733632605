.menu-text {
  font-size: 17px !important;
  font-weight: 400 !important;
}
@media screen and (max-width: 1200px) and (min-width: 900px) {
  ul.test-box {
    height: auto !important;
  }
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .topBanner {
    position: static !important;
  }
  ul.test-box {
    height: auto !important;
  }
  .slide1 {
    height: auto !important;
  }
}
@media screen and (max-width: 600px) and (min-width: 300px) {
  .slide1 {
    height: auto !important;
  }
  .topBanner {
    position: static !important;
  }
}

.slide1 {
  /* background-image: url('./assets/images/banner/main-banner-2.png');
    background-position: right center; */
  /* background-color: #d7eae7; */
  /* background-size: contain;
    background-repeat: no-repeat; */
  /* height: 850px; */
  display: flex;
  align-items: center;
}

.slide2 {
  background-image: url(./assets/images/packages/banners.jpg);
  background-position: left center;
  background-repeat: no-repeat;
}

.slide3 {
  background-image: url(./assets/images/packages/Corrugated-Boxes-Wholesale-banner.jpg);
  background-position: left center;
  background-repeat: no-repeat;
}

.page-title-section {
  background-image: url(./assets/images/packages/corrugatedbg_2.jpg);
  background-position: left center;
  background-repeat: no-repeat;
}

.page-title-section-contact {
  height: 250px;
  background-image: url(./assets/images/packages/Getintouch.jpg);
  background-position: center;
  background-repeat: no-repeat;
}

.fixed-top {
  position: fixed !important;
  top: 0;
}

.on-scroll {
  transition: all 0.5s ease 0s;
}

#scrollUp,
#chatBtn {
  display: none;
}


/* slider  */

.slidecontainer {
  width: 100%;
}

.enquiryDiv {
  /* height: 100%; */
  position: sticky;
  box-shadow: 0 0 15px #0c0c0c21;
  padding: 3pc 3pc !important;
  background-color: white;
  border-radius: 10px;
  margin-left: 3pc;
  margin-right: 3pc;
}

/* .enquiryDiv label {
    color: #000;
} */

.total-amount p {
  color: #000;
  font-weight: 500;
}

.total-amount p i {
  margin-right: 5px;
}

.homeEnquiry {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(./assets/images/packages/8.jpeg);
  background-repeat: no-repeat;
  background-position: left center;
  /* box-shadow: inset 0 0 0 200px rgba(97, 49, 49, 0.24); */
}

/* .homeEnquiryForm input::placeholder, label, p, textarea::placeholder, input{
  color: white !important;
} */

.customSelect {
  border-bottom: solid 1px black;
  border-top: transparent solid 1px;
  border-left: transparent solid 1px;
  border-right: transparent solid 1px;
  border-radius: 0px;
}

.homeEnqInput::placeholder,
.homeEnqInput {
  color: black !important;
}

.homeEnquiryForm {
  padding: 0 3em;
}

.brand-item {
  height: 100px !important;
}

.homeEnqInput:focus {
  border-bottom-color: black !important;
}



.brand-carousel img {
  object-fit: contain;
}

.carousel-item {
  height: 100% !important;
}

.top-slider .slide1.carousel.slide img {
  /* height: 100%; */
  width: 100%;
  object-fit: cover;
  object-position: right;
  margin-left: auto;
}

.topBanner {
  position: absolute;
  top: 0;
  z-index: 4;
  height: 100%;
  width: 100%;
  left: 3pc;
  display: flex;
  align-items: center;
}

/* .image-gallery-slide.center{
  height: 50vh !important;
}
.image-gallery-slide .image-gallery-image{
  height: 100% !important;
} */

.page-title {
  margin-left: 2em;
}

@media only screen and (max-width: 1599px) {
  .menu-text {
    font-size: 14px !important;
  }
  .btn-pad {
    font-size: 14px !important;
    padding: 9px 20px 0 !important;
  }
  .site-main-menu > ul > li {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1279px) {
  .menu-text {
    font-size: 12px !important;
  }
  .btn-pad {
    font-size: 12px !important;
    padding: 4px 15px !important;
  }
  .site-main-menu > ul > li {
    margin-right: 0px !important;
  }
}

.customBoxShadow {
  -webkit-box-shadow: 15px 11px 29px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 15px 11px 29px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 15px 11px 29px -6px rgba(0, 0, 0, 0.75);
}

p {
  font-weight: 500 !important;
}
.box_location img { height: 100%; width: 100%; object-fit: cover;}

.box_location {  overflow: hidden;height: 700px; width: 100%; margin: 0 auto;}