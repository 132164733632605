@media (max-width: 1199px){

    .mobile_navbarcanvastoggle .offcanvas{
      z-index: 99999;
    }
    .mobile_navbarcanvastoggle .header-logo {
      padding: 0 0;
    }
    .mobile_navbarcanvastoggle.customHeader{
      padding: 10px 0;
    }
    .mobile_navbarcanvastoggle .header-logo img{
      width: 90px;
    }
    .mobile_navbarcanvastoggle .site-main-menu{
      flex-direction: column;
    }
    .mobile_navbarcanvastoggle .site-main-menu > ul{
      display: unset;
    }
    .mobile_navbarcanvastoggle .site-main-menu > ul li a{
    padding: 10px 0;
    }
    .mobile_navbarcanvastoggle .site-main-menu > ul li{
      border-bottom: 1px solid #ccc;
    }
    .mobile_navbarcanvastoggle .site-main-menu > ul .menu-text{
      color: #000 !important;
      font-weight: 400 !important;
      font-size: 17px !important;
    
    }
    .mobile_navbarcanvastoggle  .offcanvas-body{
      padding: 0
    }
    .mobile_navbarcanvastoggle .toggle{
      font-size: 30px;
    }
    .about-us3 p {
        margin-bottom: 0;
        text-align: justify;
    }
    .mobile_navbarcanvastoggle .accordion-body .list_name li a{
        padding: 5px 0 
    }
    .mobilemb-4 .learts-mb-30{
        margin-bottom: 10px;
    }
    .test-performed .sale-banner7 .inner .content{
        bottom: 11px;
    }
    .sale-banner7 .inner .content .title
    {
        background-color: #00000099;
        color: #fff;
    }
    .porssermobilebottom.section-padding{
        padding-bottom: 30px;
    }
    .machines-text img{
        margin-top: 10px;
    }
    .contact-form .btn-dark.btn-outline-hover-dark{
        margin-top: 10px;
    }
}


/* resposive css >  786--------------------------------------------------------  */
@media (max-width: 768px){
    .mobile_delitls{
      height: 250px !important;
    }
    .enquiryDiv{
      padding: 2pc 5px !important;
    }
    .contact-info.address-info span.info{
      width: 100%;
      padding-left: 0;
      margin-bottom: 15px;
    }
    .contact-info .info{
      padding-left: 0;
    }
    .contact-sec{
      padding: 3pc 1pc;
    }
    .sale-banner9-image img{
      height: 250px;
    }
    .about-us3-img:before{
      right: 0;
    }
   .about-us3{
    margin-top: 19px;
    }
    .video-banner .content {
      padding: 0 0 0 1pc;
    }
    .video-banner::after{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgb(0 0 0 / 59%);
    }
    .video-banner .section-title2{
      position: relative;
      z-index: 1;
    }
    .video-banner .section-title2 h2{
      color: #fff;
    }
    .video-banner .content span{
      color: #fff;
    }
    .video-banner .section-title2 p{
      color: #fff !important;
      font-weight: 300 !important;
    }
    .icon-box3.col {
      width: 100%;
      margin: 10px 0 !important;
    }
    .icon-box3 .inner .content .title{
      font-size: 20px;
    }
    .machine-capability .col-12:first-child{
      padding: 0 0pc 0 0;
    }
    .rowreverse_mobile .row{
      flex-direction: column-reverse;
    }
    .suggestion-form .form{
      right: -5pc;
      width: 100vw;
      right: -5pc;
      width: 100vw;
      height: 462px;
      overflow: auto;
      padding: 0pc 1pc;
    }
    .suggestion-form .form:before{
      display: none;
    }
    .suggestion-form .form .title{
      margin-bottom: 0px;
      font-size: 18px;
    }
    .learts-mb-10{
      margin-bottom: 0px !important;
    }
    .learts-mb-30{
      margin-bottom: 0;
    }
    .homeEnquiryForm .btn{
      padding: 10px 40px;
    }
    
    .homeEnquiryForm .slidecontainer.mt-4{
      margin-top: 0;
    }
    .homeEnquiryForm input{
      padding: 3px 0px !important;
    }
    ul.test-box > li{
      height: unset;
    }
    .learts-pb-30 {
      padding-bottom: 0px;
  }
  .marginb30{
    margin-bottom: 1px;
  }
  .sale-banner7 .inner .image img{
    height: 175px !important;
  }
  .about-us3{
    margin-top: 68px;
  }
  .sale-banner7 .inner .content .title{
    margin-bottom: -26px;
  }
  .learts-pt-50 {
    padding-top: 15px;
    }
.sale-banner9-image{
    margin-top: 10px;
}
.revartsectino .mobile-revarsi{
    flex-direction: column-reverse;
}
.team .image{
    margin-bottom: 0;
}
.sectionteam .team{
    margin-bottom: 35px;
}
.team .content .name{
    /* font-family: "Futura"; */
    font-size: 21px;
    font-weight: 400;
    color: #333;
    text-align: center;
    margin-top: 9px;
}
.video-banner.home-banner{
    background-position : unset;
    background-size: cover;
}
.contact-form {
    padding-bottom: 23px;
}
.btn-dark{
    z-index: 2;
}
.widget-about {
    /* display: flex; */
    align-items: center;
}
.video-banner{
    margin-top: 15px !important;
}
.box_location{
    height: 210px;
}
}
  
@media(max-width:767px){
  .why_choose .item {
    margin-bottom: 15px;
}
.slider-testimonial .slide .slider-img img{
  width:100%;
}
.slider-testimonial .slide .testimonial{
  padding: 32px 20px;
  padding-bottom:0px;
}
.slider-testimonial .slide .testimonial{
  background:none;
}
.slider-testimonial .slide .slider-img {
  padding:30px 20px;
}
.slider-testimonial .slide .testimonial blockquote{
  text-align: justify;
}
.testi-item {
    display: flex;
    flex-direction: column-reverse;
    background: #fff;
    margin-bottom: 20px;
    padding: 20px 0;
    box-shadow: 2px 2px 9px rgba(0 0 0 / 15%);
}
.slider-testimonial .slide .testimonial .author {
    margin-left: 0;
    justify-content: start;
    text-align: left;
    padding-left: 20px;
    margin-bottom: 0px;
}
blockquote:not(.learts-blockquote):not(.learts-blockquote2){
  border-left:none;
}
.testi-item:last-child {
    margin-bottom: 0px;
}
.col-lg-3.col-sm-6:last-child .item {
    margin-bottom: 0px;
}

.row.bottom-images .col-lg-4 img {
    width: 70px !important;
}
.col-lg-3.learts-mb-40.opening_hours_header {
    padding-top: 30px;
}
.slider-text-section {
    position: initial;
    padding: 30px 0;
}
.home-masthead-intro p.white.intro-text {
    color: #696969;
}

.home-masthead-intro span.luxury.white {
    color: #333;
}
}
/*=========== end divice 767px ========*/
@media(min-width:768px) and (max-width:992px){
  .slider-text-section{
    top:10px;
  }
  .about-us3.ps-md-5.ms-md-5 {
    margin-left: 0px !important;
    margin-top: 0px;
}
.col-lg-3.col-sm-6 .item {
    margin-bottom: 20px;
}
.slider-testimonial .slide.item .testimonial {
    padding-right: 0px;
    padding-left: 0px;
}

.slider-testimonial .slide.item .slider-img {
    padding-left: 0px;
}
.slider-testimonial .slide {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.slider-img img {
    width: 100% !important;
}
}
@media(min-width:769px){
  .feat .item {
    height: 354px;
}
}